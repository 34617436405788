<script setup>
import { ref, watch } from "vue";

import "vue3-tel-input/dist/vue3-tel-input.css";
import contentApi from "@/apis/contentApi";
import { useI18n } from "vue-i18n";
import CustomerProfileMembershpModifyFormItem from "@/views/profiles/items/form/profile/CustomerProfileMembershpModifyFormItem.vue";
import { useVuelidate } from "@vuelidate/core";
import { useProfileStore } from "@/stores/profile";

const props = defineProps({
  userProfileFormModal: {},
  isAdditionalTraveller: {},
});
const { t, locale } = useI18n(); // 解构调用函数

const emits = defineEmits([]);
const profileStore = useProfileStore();

const userProfileFormModalRef = ref(null);

watch(
  () => props.userProfileFormModal,
  (newValue) => {
    userProfileFormModalRef.value = newValue;
  },
  { immediate: true, deep: true }
);

//获取所有的航空公司联盟
let frequentFlyerProgramOptionRef = ref([]);
frequentFlyerProgramOptionRef.value.push({
  programID: null,
  programName: t("all-programmes"),
});
contentApi.frequentFlyerPrograms().then((res) => {
  if (res.data && res.data.length > 0) {
    res.data.sort(function (a, b) {
      if (a.programName < b.programName) {
        return -1;
      }
      if (a.programName > b.programName) {
        return 1;
      }
      return 0;
    });
    frequentFlyerProgramOptionRef.value.push(...res.data);
  }
});
const v = useVuelidate();

async function modifyMemberships(userProfileFormModal) {
  const result = await v.value.$validate();
  if (!result) return;
  emits("modifyMemberships", userProfileFormModal, "MEMBERSHIP");
}

function addMembership() {
  userProfileFormModalRef.value.addNewCustLoyalty(null, null, null, null);
}

function removeMembership(index, custLoyaltyId) {
  userProfileFormModalRef.value.removeNewCustLoyalty(index);
  //已经添加的直接调接口删除
  if (custLoyaltyId)
    profileStore.removeMemberShip(
      userProfileFormModalRef.value.partyId,
      custLoyaltyId
    );
}
</script>

<template>
  <form
    action="!#"
    id="profile_form_area"
    @submit.prevent="modifyMemberships(userProfileFormModalRef)"
  >
    <h3>Reward programmes</h3>
    <div class="font-14">
      Save your reward programmes now so you don't have to remember them every
      time you book a trip.
    </div>
    <div
      class="row"
      v-for="(membership, memberIndex) in userProfileFormModalRef.custLoyalties"
      :key="memberIndex"
    >
      <CustomerProfileMembershpModifyFormItem
        :ffp-numer="membership"
        :frequent-flyer-program-option="frequentFlyerProgramOptionRef"
        :ffp-numer-index="memberIndex"
        :ffp-numer-num="userProfileFormModalRef.custLoyalties.length"
        @addMembership="addMembership"
        @removeMembership="removeMembership"
      />
    </div>

    <div class="col-lg-12" align="center">
      <button class="btn btn_theme">{{ $t("save") }}</button>
      <!--      <button class="btn btn-secondary mg-l-8" v-if="isAdditionalTraveller">-->
      <!--        {{ $t("cancel") }}-->
      <!--      </button>-->
    </div>
  </form>
</template>

<style scoped></style>
