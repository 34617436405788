<script setup>
import { useCmsContentStore } from "@/stores/cms";
import { ref, watch } from "vue";
import { useTemplateStore } from "@/stores/template";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useHotelAvailabilityStore } from "@/stores/v2/hotel/availability/hotelAvailabilityStore";
import hotelCommonTools from "@/tools/v2/hotel/common/hotelCommonTools";
import HotelPhotosGroup from "@/views/v2/sales/hotel/items/group/HotelPhotosGroup.vue";
import HotelTabItemGroup from "@/views/v2/sales/hotel/items/group/HotelTabItemGroup.vue";

const templateStore = useTemplateStore();
const { t, locale } = useI18n(); // 解构调用函数
const router = useRouter();
const route = useRoute();
const hotelAvailabilityStore = useHotelAvailabilityStore();
//region cms内容管理
const cmsContentStore = useCmsContentStore();
cmsContentStore.getFlightHeaderContent();
cmsContentStore.getPromotionManagements();
cmsContentStore.getFlightPromotionDeals();
cmsContentStore.getPromotionManagements();
cmsContentStore.getFlightPromotionPrimaryDeals();
cmsContentStore.getOurPartner();

//机票背景图片
const backgroundImageUrlRef = ref(null);
watch(
  () => cmsContentStore.flightHeaderContent,
  (newValue) => {
    const baseUrl = "/api/v3/content/collections/images";
    if (newValue && newValue.backgoundImage) {
      const backgoundImage = newValue.backgoundImage;
      const url = backgoundImage ? backgoundImage.url : null;
      backgroundImageUrlRef.value = url ? baseUrl + url : null;
    }
  },
  { immediate: true, deep: true }
);
//endregion cms内容管理

const hotelAvailFormModelRef = ref(null);
const hotelItemDetailsShowRef = ref(null);

watch(
  () => hotelAvailabilityStore.hotelItems,
  (newValue) => {
    if (!newValue && route.query && "UPDATE" !== route.query.type) {
      sessionStorage.removeItem("hotelItemDetails");
    }
    if (newValue && newValue.length > 0) {
      hotelItemDetailsShowRef.value = newValue[0];

      //当房型为空时做存储处理，给刷新页面时，请求无数据的情况赋值
      if (
        hotelItemDetailsShowRef.value &&
        hotelItemDetailsShowRef.value.roomTypes &&
        hotelItemDetailsShowRef.value.roomTypes.length === 0
      ) {
        sessionStorage.setItem(
          "hotelItemDetails",
          JSON.stringify(hotelItemDetailsShowRef.value)
        );
      }
    } else {
      if (sessionStorage.getItem("hotelItemDetails")) {
        hotelItemDetailsShowRef.value = JSON.parse(
          sessionStorage.getItem("hotelItemDetails")
        );
      }
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => route.query,
  (newValue) => {
    if (newValue != null && Object.keys(newValue).length > 0) {
      hotelAvailFormModelRef.value = buildHotelAvailFormModel(newValue);
    }

    if (
      hotelAvailabilityStore.hotelItems === null &&
      hotelAvailFormModelRef.value
    ) {
      searchHotelByFormModel(hotelAvailFormModelRef.value);
    } else if (hotelAvailFormModelRef.value) {
      updateRoomsByFormModel(hotelAvailFormModelRef.value);
    }
  },
  { immediate: true, deep: true }
);

//#endregion

//region 语言改变，重新搜索
watch(
  () => locale.value,
  (newValue) => {
    const queryCopy = JSON.parse(JSON.stringify(route.query));
    if (queryCopy && queryCopy.languageCode) {
      queryCopy.languageCode = newValue;
      router.replace({ query: queryCopy });
    }
  }
);
//endregion

//region 辅助函数
async function updateRoomsByFormModel(formModel) {
  templateStore.pageLoader({ mode: "on" });
  let res = await hotelAvailabilityStore.updateRoomInfos(formModel);
  templateStore.pageLoader({ mode: "off" });
}

async function searchHotelByFormModel(formModel) {
  templateStore.pageLoader({ mode: "on" });
  let res = await hotelAvailabilityStore.hotelAvail(formModel);
  templateStore.pageLoader({ mode: "off" });
}

function buildHotelAvailFormModel(query) {
  const hotelAvailFormModel = hotelCommonTools.parseHotelAvailFormModel(query);
  //详情时相对列表页面将搜索条件修改
  hotelAvailFormModel.locationCode = parseInt(query.ttiCode);
  hotelAvailFormModel.locationType = "HOTEL";
  hotelAvailFormModel.bestOnlyIndicator = false;
  return hotelAvailFormModel;
}

function goToHotelList() {
  window.close();
}

//endregion

//region 设备相关

const equipmentType = ref(templateStore.responsive.equipmentType);
watch(
  () => templateStore.responsive.equipmentType,
  (newValue) => {
    equipmentType.value = newValue;
  }
);
//endregion
</script>

<template>
  <!--   Banner Area -->
  <section id="page_banner">
    <div class="container">
      <div class="common_banner_text">
        <h2>{{ $t("hotel-details") }}</h2>
        <ul>
          <li>
            <i class="fas fa-circle"></i>
            {{ $t("hotel-details") }}
          </li>
        </ul>
      </div>
    </div>
  </section>
  <!--   Banner Area  END-->

  <section id="tour_details_main" class="section_padding">
    <div class="container hotel_container" style="margin-left: 350px">
      <div class="row" v-if="equipmentType !== 'iphone'">
        <div class="col-lg-3 col-md-3 col-sm-3 col-3">
          <div class="header_back">
            <a @click="goToHotelList">
              <i class="bi bi-arrow-left-square back_icon" size="40"></i>
              <span style="font-size: 27px">{{ $t("back") }}</span>
            </a>
          </div>
        </div>
      </div>
      <div class="row" v-if="hotelItemDetailsShowRef">
        <div
          class="col-lg-10"
          :class="{ 'col-lg-12': equipmentType === 'ipad' }"
        >
          <div class="tour_details_leftside_wrapper">
            <div
              class="tour_details_img_wrapper"
              v-if="
                hotelItemDetailsShowRef.photos &&
                hotelItemDetailsShowRef.photos.length > 0
              "
            >
              <HotelPhotosGroup
                :hotel-images="hotelItemDetailsShowRef.photos"
              />
            </div>

            <HotelTabItemGroup
              :equipment-type="equipmentType"
              :hotel-avail-form-model="hotelAvailFormModelRef"
              :hotel-item-details="hotelItemDetailsShowRef"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>
