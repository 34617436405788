import { defineStore } from "pinia";
import flightModifyTools from "@/tools/v2/flight/modify/flightModifyTools";
import flightServicingApi from "@/apis/v2/flight/flightServicingApi";

export const useFlightModifyStore = defineStore("flightModify", {
  state: () => ({
    airBookModifyRS: null,
  }),
  actions: {
    airBookModify(flightModifyFormModel) {
      const airBookModifyRQ = flightModifyTools.buildAirBookModifyRQ(
        flightModifyFormModel
      );
      return flightServicingApi
        .airBookModify(airBookModifyRQ)
        .then((res) => {
          const airBookModifyRS = res.data;
          return airBookModifyRS;
        })
        .catch((error) => {
          //console.info(error);
          return error;
        });
    },
  },
});
