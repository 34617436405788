import axios from "@/utils/axiosUtils";

function findTravelLoyalties(partyId) {
  let url = "/api/v3/parties/" + partyId + "/custloyalties";
  return axios.get(url);
}

function findTravelLoyalty(partyId, partyCustLoyaltyId) {
  let url =
    "/api/v3/parties/" + partyId + "/custloyalties/" + partyCustLoyaltyId;
  return axios.get(url);
}

function addTravelLoyalty(loyaltyRQ, partyId) {
  let url = "/api/v3/parties/" + partyId + "/custloyalties";
  return axios.post(url, loyaltyRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function modifyTravelLoyalty(loyaltyRQ, partyId, partyCustLoyaltyId) {
  let url =
    "/api/v3/parties/" + partyId + "/custloyalties/" + partyCustLoyaltyId;
  return axios.put(url, loyaltyRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function deleteTravelLoyalty(partyId, partyCustLoyaltyId) {
  let url =
    "/api/v3/parties/" + partyId + "/custloyalties/" + partyCustLoyaltyId;
  return axios.delete(url);
}

export default {
  findTravelLoyalties,
  findTravelLoyalty,
  addTravelLoyalty,
  modifyTravelLoyalty,
  deleteTravelLoyalty,
};
