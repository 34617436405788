<script setup>
import { ref, watch } from "vue";
import { useTemplateStore } from "@/stores/template";
import { useRoute, useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { useFlightShoppingStore } from "@/stores/v2/flight/shopping/flightShoppingStore";
import {
  FlightOriginDestinationFormItem,
  FlightShoppingFormModel,
} from "@/formmodel/sales/flight/shopping/flightShoppingFormModel";
import dateUtils from "@/utils/dateUtils";
import useVuelidate from "@vuelidate/core";
import FlightShoppingFormOrigDestDateItem from "@/views/v2/sales/flight/items/form/FlightShoppingFormOrigDestDateItem.vue";
import FlightShoppingPassengersAndCabinForm from "@/views/v2/sales/flight/items/form/FlightShoppingPassengersAndCabinForm.vue";

const props = defineProps({
  jumboable: {
    type: Boolean,
  },
  jumpTo: {
    type: String,
    description: "route name to jump",
  },
  equipmentType: {},
  isFromHome: {},
});

let router = useRouter();
let route = useRoute();
const emits = defineEmits(["closeModifySearchShow"]);
let templateStore = useTemplateStore();
const flightShoppingStore = useFlightShoppingStore();
const { t, locale } = useI18n(); // 解构调用函数

let currentLanguage = ref("");

let tripTypeOptions = ref([
  { value: "ONE_WAY", label: t("one-way") },
  { value: "RETURN", label: t("return") },
  { value: "MULTI_WAY", label: t("multi-way") },
]);

//region 初始化数据
let tripTypeRef = ref("RETURN");
const flightShoppingFormModelRef = ref(
  initiateFormModel(null, tripTypeRef.value)
);
//endregion

//region 监听事件
//监听RETURN中第一行程改变，第二行程数据对应赋值
watch(
  () => flightShoppingFormModelRef.value.originDestinations,
  (newValue) => {
    if (tripTypeRef.value === "RETURN") {
      newValue[1].originLocationName = newValue[0].destinationLocationName
        ? newValue[0].destinationLocationName
        : null;
      newValue[1].destinationLocationName = newValue[0].originLocationName
        ? newValue[0].originLocationName
        : null;
      newValue[1].originLocationCode = newValue[0].destinationLocationCode
        ? newValue[0].destinationLocationCode
        : null;
      newValue[1].destinationLocationCode = newValue[0].originLocationCode
        ? newValue[0].originLocationCode
        : null;
    }
  },
  { deep: true }
);
//endregion

//#region query参数请求，进行搜索
if (!props.jumboable) {
  if (route.query != null && Object.keys(route.query).length > 0) {
    flightShoppingFormModelRef.value = parseFlightSearchFormModel(route.query);
    tripTypeRef.value = route.query.tripType;
  }
  searchFlightByFormModel(flightShoppingFormModelRef.value);
}

function parseFlightSearchFormModel(query) {
  const originDestinationsFormat = query.originDestinationsFormat;
  const originDestinations = parseOriginDestinations(originDestinationsFormat);
  const passengers = parseSimpleArray(query.passengersFormat);
  const loyaltyAccounts = parseSimpleArray(query.loyaltyAccountsFormat);
  const fareTypePrefs = parseSimpleArray(query.fareTypePrefsFormat);
  const corporateCodes = parseSimpleArray(query.corporateCodesFormat);
  const fareBasisCodes = parseSimpleArray(query.fareBasisCodesFormat);
  const servicePrefs = parseSimpleArray(query.servicePrefsFormat);
  const vendorPrefs = parseSimpleArray(query.vendorPrefsFormat);

  const bestPricingOption = query.bestPricingOption;
  const contentChannel = query.contentChannel;

  const contractID = query.contractID;
  const promotionCode = query.promotionCode;

  return new FlightShoppingFormModel(
    originDestinations,
    passengers,
    loyaltyAccounts,
    bestPricingOption,
    contentChannel,
    corporateCodes,
    contractID,
    fareBasisCodes,
    fareTypePrefs,
    promotionCode,
    servicePrefs,
    vendorPrefs
  );
}

function parseOriginDestinationFormItem(format) {
  let splits = format.split("&");
  let originDestinationQueryModel = {};
  for (const keyValues of splits) {
    const keyValue = keyValues.split("!!");
    const key = keyValue[0];
    const value =
      keyValue[1] === null || keyValue[1] === "" ? null : keyValue[1];
    let isArrayVariable = false;
    if (value.indexOf("||") > 0) isArrayVariable = true;

    if (isArrayVariable) {
      let keyParse = key.replace("Format", "");
      originDestinationQueryModel[keyParse] = parseSimpleArray(value);
    } else {
      originDestinationQueryModel[key] = value;
    }
  }

  let refNumber = originDestinationQueryModel.refNumber;
  let originLocationCode = originDestinationQueryModel.originLocationCode;
  let originLocationName = originDestinationQueryModel.originLocationName;
  let destinationLocationCode =
    originDestinationQueryModel.destinationLocationCode;
  let destinationLocationName =
    originDestinationQueryModel.destinationLocationName;
  let equipPrefs =
    originDestinationQueryModel.equipPrefs === undefined
      ? null
      : originDestinationQueryModel.equipPrefs;
  let cabinTypePrefs =
    originDestinationQueryModel.cabinTypePrefs === undefined
      ? null
      : originDestinationQueryModel.cabinTypePrefs;
  let airlinePrefs =
    originDestinationQueryModel.airlinePrefs === undefined
      ? null
      : originDestinationQueryModel.airlinePrefs;
  let departureDate = originDestinationQueryModel.departureDate;
  let departureTime =
    originDestinationQueryModel.departureTime === undefined
      ? null
      : originDestinationQueryModel.departureTime;

  let connections =
    originDestinationQueryModel.connections === undefined
      ? null
      : originDestinationQueryModel.connections;
  let stops =
    originDestinationQueryModel.stops === undefined
      ? null
      : originDestinationQueryModel.stops;
  let departureTimeWindowBefore =
    originDestinationQueryModel.departureTimeWindowBefore === undefined
      ? null
      : originDestinationQueryModel.departureTimeWindowBefore;
  let departureTimeWindowAfter =
    originDestinationQueryModel.departureTimeWindowAfter === undefined
      ? null
      : originDestinationQueryModel.departureTimeWindowAfter;
  let daysBeforeDeparture =
    originDestinationQueryModel.daysBeforeDeparture === undefined
      ? null
      : originDestinationQueryModel.daysBeforeDeparture;
  let daysAfterDeparture =
    originDestinationQueryModel.daysAfterDeparture === undefined
      ? null
      : originDestinationQueryModel.daysAfterDeparture;
  let arrivalDate =
    originDestinationQueryModel.arrivalDate === undefined
      ? null
      : originDestinationQueryModel.arrivalDate;
  let arrivalTime =
    originDestinationQueryModel.arrivalTime === undefined
      ? null
      : originDestinationQueryModel.arrivalTime;
  return new FlightOriginDestinationFormItem(
    refNumber,
    originLocationCode,
    originLocationName,
    destinationLocationCode,
    destinationLocationName,
    equipPrefs,
    cabinTypePrefs,
    airlinePrefs,
    departureDate,
    departureTime,
    connections,
    stops,
    departureTimeWindowBefore,
    departureTimeWindowAfter,
    daysBeforeDeparture,
    daysAfterDeparture,
    arrivalDate,
    arrivalTime
  );
}

function parseOriginDestinations(formats) {
  let originDestinations = [];

  if (formats && formats instanceof Array && formats.length > 0) {
    formats.forEach((format) => {
      const flightOriginDestinationFormItem =
        parseOriginDestinationFormItem(format);
      originDestinations.push(flightOriginDestinationFormItem);
    });
  } else {
    const flightOriginDestinationFormItem =
      parseOriginDestinationFormItem(formats);

    originDestinations.push(flightOriginDestinationFormItem);
  }
  return originDestinations;
}

//#endregion

//#region OD参数格式化
function formatOriginDestinations(originDestinations) {
  let formatArr = [];
  if (originDestinations && originDestinations.length > 0) {
    originDestinations.forEach((originDestination) => {
      let equipPrefsFormat = formatSimpleArray(originDestination.equipPrefs);
      let cabinTypePrefsFormat = formatSimpleArray(
        originDestination.cabinTypePrefs
      );
      let airlinePrefsFormat = formatSimpleArray(
        originDestination.airlinePrefs
      );

      let format = "";
      for (const key of Object.keys(originDestination)) {
        const value = originDestination[key];
        if (typeof value !== "string" && typeof value !== "number") continue;

        format = appendFormatQueryVariable(key, format, value);
      }
      format = appendFormatQueryVariable(
        "equipPrefsFormat",
        format,
        equipPrefsFormat
      );
      format = appendFormatQueryVariable(
        "cabinTypePrefsFormat",
        format,
        cabinTypePrefsFormat
      );
      format = appendFormatQueryVariable(
        "airlinePrefsFormat",
        format,
        airlinePrefsFormat
      );
      formatArr.push(format);
    });
  }
  // console.info(formatArr);
  return formatArr;
}

/**
 *
 * @param array 简单对象数组，对象只有一个层级的成员变量
 */
function formatSimpleArray(array) {
  let format = "";
  if (array == null || array.length === 0) return format;

  for (const ele of array) {
    if (!ele || (typeof ele === "object" && Object.keys(ele) === 0)) {
      continue;
    }

    if (typeof ele === "string") {
      format += ele;
    } else {
      for (const key of Object.keys(ele)) {
        if (!key) continue;
        const value = ele[key] ? ele[key] : "";
        if (!value) continue;
        if (format !== "") format += "@"; //变量分隔符
        format += key + "=" + value;
      }
    }
    format += "||"; //元素分隔符
  }

  return format;
}

function parseSimpleArray(format) {
  if (!format || format === "") return null;
  const parseArray = [];
  for (const keyValues of format.split("||")) {
    if (!keyValues || keyValues === "") continue;

    let isStringArr = false;
    if (keyValues.indexOf("=") < 0) isStringArr = true;

    if (isStringArr) {
      parseArray.push(keyValues);
    } else {
      const keyValueSplit = keyValues.split("@"); //
      const parseObj = {};
      for (const keyValue of keyValueSplit) {
        if (!keyValue || keyValue === "") continue;
        const split = keyValue.split("=");
        const key = split[0];
        if (!key || key === "") continue;

        const value = split[1] === null || split[1] === "" ? null : split[1];
        parseObj[key] = value;
      }
      parseArray.push(parseObj);
    }
  }

  return parseArray;
}

function appendFormatQueryVariable(key, format, formatAppend) {
  if (!formatAppend || "" === formatAppend) return format;

  if (format.length > 0) key = "&" + key;

  format += key + "!!" + formatAppend;
  return format;
}

//#endregion

//region 表单相关函数
function changeTripType(typeValue) {
  tripTypeRef.value = typeValue; //不加这行发生了flightShoppingFormModelRef.value.originDestinations监听异常，oneway-roundtrip没有监听到
  flightShoppingFormModelRef.value = initiateFormModel(
    flightShoppingFormModelRef.value,
    typeValue
  );
}

function initiateFormModel(formModel, tripType) {
  //为空，则初始化
  if (formModel == null) {
    formModel = buildFlightShoppingFormModel(tripType); //表单第一次初始化
  } else if (tripType === "RETURN") {
    const originDestinations = formModel.originDestinations;
    const originDestinationFirst = originDestinations[0];
    const originLocationCode = originDestinationFirst.originLocationCode;
    const originLocationName = originDestinationFirst.originLocationName;
    const destinationLocationCode =
      originDestinationFirst.destinationLocationCode;
    const destinationLocationName =
      originDestinationFirst.destinationLocationName;
    const departureDate = originDestinationFirst.departureDate;
    if (originDestinations.length >= 2) {
      originDestinations.splice(2, originDestinations.length - 1);
      originDestinations[1] = initiateInboundWay(
        originDestinations[1],
        departureDate
      );
    } else {
      formModel.addNewOriginDestination(
        "OD1",
        destinationLocationCode,
        destinationLocationName,
        originLocationCode,
        originLocationName,
        null,
        null,
        null,
        dateUtils.getOffsetDate(departureDate, 1),
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      );
    }
  } else if (tripType === "ONE_WAY") {
    const originDestinations = formModel.originDestinations;
    formModel.originDestinations.splice(1, originDestinations.length - 1);
  } else {
    const originDestinations = formModel.originDestinations;
    const originDestinationLast = originDestinations[0]; //获取第一条
    const departureDate = originDestinationLast.departureDate;
    if (originDestinations.length === 2) {
      originDestinations[1] = initiateInboundWay(
        originDestinations[1],
        departureDate
      );
    } else {
      formModel.addNewOriginDestination(
        "OD1",
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        dateUtils.getOffsetDate(departureDate, 1),
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      );
    }
  }
  return formModel;
}

function initiateInboundWay(originDestination, departureDate) {
  // formModel.removeOriginDestination(1);
  originDestination.refNumber = "OD1";
  originDestination.originLocationCode = null;
  originDestination.originLocationName = null;
  originDestination.destinationLocationCode = null;
  originDestination.destinationLocationName = null;
  originDestination.equipPrefs = null;
  originDestination.cabinTypePrefs = null;
  originDestination.airlinePrefs = null;
  originDestination.departureDate = dateUtils.getOffsetDate(departureDate, 1);
  originDestination.departureTime = null;
  originDestination.connections = null;
  originDestination.stops = null;
  originDestination.departureTimeWindowBefore = null;
  originDestination.departureTimeWindowAfter = null;
  originDestination.daysBeforeDeparture = null;
  originDestination.daysAfterDeparture = null;
  originDestination.arrivalDate = null;
  originDestination.arrivalTime = null;

  return originDestination;
}

function buildFlightShoppingFormModel(tripType) {
  //初始化对象
  const formModel = new FlightShoppingFormModel(
    null,
    null,
    null,
    null,
    "ALL",
    null,
    null,
    null,
    null,
    null,
    null,
    null
  );
  //根据不同的tripType添加行程信息
  let originDesNum = tripType === "ONE_WAY" ? 1 : 2;
  for (let i = 0; i < originDesNum; i++) {
    const dateInit = dateUtils.getOffsetDate(new Date(), 7 + i);
    formModel.addNewOriginDestination(
      "OD" + i,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      dateInit,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
  }
  return formModel;
}

//OD添加删除操作
function addOrigDestDateItem(index) {
  const departureDate =
    flightShoppingFormModelRef.value.originDestinations[index].departureDate;
  flightShoppingFormModelRef.value.addNewOriginDestination(
    "OD" + index + 1,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    dateUtils.getOffsetDate(departureDate, 1),
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null
  );
}

function deleteOrigDestDateItem(index) {
  flightShoppingFormModelRef.value.removeOriginDestination(index);
}

//OD时间修改
function updateOriginDestinationDepartureDate(originDesIndex) {
  let originDestinationCurrent =
    flightShoppingFormModelRef.value.originDestinations[originDesIndex];
  //当前改变项
  let departureDateCurrent = originDestinationCurrent.departureDate;
  for (
    let i = 0;
    i < flightShoppingFormModelRef.value.originDestinations.length;
    i++
  ) {
    let originDestinationCompare =
      flightShoppingFormModelRef.value.originDestinations[i];
    //遍历项
    let departureDateTimeCompare = originDestinationCompare.departureDate;

    if (i < originDesIndex) {
      //之前有比当前改变项更晚出发的时间
      if (departureDateTimeCompare >= departureDateCurrent) {
        if (departureDateCurrent === dateUtils.getOffsetDate(new Date(), 0)) {
          flightShoppingFormModelRef.value.originDestinations[
            originDesIndex
          ].departureDate = dateUtils.getOffsetDate(new Date(), 1);
          flightShoppingFormModelRef.value.originDestinations[i].departureDate =
            dateUtils.getOffsetDate(
              flightShoppingFormModelRef.value.originDestinations[
                originDesIndex
              ].departureDate,
              -1
            );
        } else {
          //修改这个时间比当前改变时间早一天
          flightShoppingFormModelRef.value.originDestinations[i].departureDate =
            dateUtils.getOffsetDate(departureDateCurrent, -1);
        }
      }
    } else if (i > originDesIndex) {
      //在当前改变项之后的遍历项有比当前改变项更早出发的时间
      if (departureDateCurrent >= departureDateTimeCompare) {
        flightShoppingFormModelRef.value.originDestinations[i].departureDate =
          dateUtils.getOffsetDate(departureDateCurrent, 1);
      }
    }
  }
}

async function searchFlightByFormModel(formModel) {
  templateStore.pageLoader({ mode: "on" });
  //每次搜索之后存储表单
  sessionStorage.setItem("flightShoppingFormModel", JSON.stringify(formModel));
  let res = await flightShoppingStore.airLowFareSearch(formModel);
  if (!props.jumboable) {
    emits("closeModifySearchShow");
  }
  templateStore.pageLoader({ mode: "off" });
}

//endregion

//region校验和表单提交
const v = useVuelidate();

async function submitFlightShoppingFormModel(formModel) {
  const result = await v.value.$validate();
  if (!result) return;
  let formModelCopy = JSON.parse(JSON.stringify(formModel));
  const shoppingQueryModel = buildBasicShoppingQueryModel(formModelCopy);

  shoppingQueryModel.originDestinationsFormat = formatOriginDestinations(
    formModelCopy.originDestinations
  );
  shoppingQueryModel.passengersFormat = formatSimpleArray(
    formModelCopy.passengers
  );
  shoppingQueryModel.tripType = tripTypeRef.value;
  shoppingQueryModel.isFromHome = props.isFromHome;
  if (props.jumboable) {
    router.push({
      name: props.jumpTo,
      query: shoppingQueryModel,
    });
  } else {
    router.replace({ query: shoppingQueryModel });
    searchFlightByFormModel(formModel);
  }
}

function buildBasicShoppingQueryModel(formModel) {
  const shoppingQueryModel = {};

  for (const key of Object.keys(formModel)) {
    const value = formModel[key];
    if (typeof value !== "string") continue;
    shoppingQueryModel[key] = formModel[key];
  }

  return shoppingQueryModel;
}

//endregion
</script>

<template>
  <!--程型选择-->
  <div class="row">
    <div class="col-lg-12">
      <div class="flight_categories_search">
        <!--          决定行程类型-->
        <ul class="nav nav-tabs">
          <li v-for="(option, index) in tripTypeOptions" :key="index">
            <button
              class="nav-link"
              :class="{ active: tripTypeRef === option.value }"
              type="button"
              @click="changeTripType(option.value)"
            >
              {{ option.label }}
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <!--  v-if 的方式实时渲染 -->

  <div class="row mg-b-20">
    <div class="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-sm-12 col-12">
      <FlightShoppingPassengersAndCabinForm
        :passengers="flightShoppingFormModelRef.passengers"
        :origin-destinations="flightShoppingFormModelRef.originDestinations"
      />
    </div>
  </div>
  <!-- 遍历行程-->
  <div
    v-if="
      flightShoppingFormModelRef.originDestinations &&
      flightShoppingFormModelRef.originDestinations.length > 0
    "
  >
    <div
      v-for="(
        originDestinationItem, originDesIndex
      ) in flightShoppingFormModelRef.originDestinations"
      :key="originDesIndex"
    >
      <div class="font_weight" v-if="equipmentType === 'iphone'">
        {{ $t("flight") }} {{ originDesIndex + 1 }} :
      </div>
      <FlightShoppingFormOrigDestDateItem
        :origin-destination-form-model="originDestinationItem"
        :origin-des-index="originDesIndex"
        :origin-des-num="flightShoppingFormModelRef.originDestinations.length"
        :trip-type="tripTypeRef"
        @deleteOrigDestDateItem="deleteOrigDestDateItem"
        @addOrigDestDateItem="addOrigDestDateItem"
        @updateOriginDestinationDepartureDate="
          updateOriginDestinationDepartureDate
        "
      />
    </div>
  </div>
  <!-- 遍历行程End-->

  <div class="top_form_search_button">
    <button
      class="btn btn_theme btn_md"
      @click="submitFlightShoppingFormModel(flightShoppingFormModelRef)"
    >
      {{ $t("search") }}
    </button>
  </div>
</template>

<style scoped></style>
