import { FlightPassengerFormItem } from "@/formmodel/sales/flight/book/flightBookFormModel";

function buildAirBookRQ(flightBookFormModel) {
  const airItineraryRPH = flightBookFormModel.airItineraryRPH;
  const quoteID = flightBookFormModel.quoteID;
  const passengers = flightBookFormModel.passengers;
  const servicesSelected = flightBookFormModel.servicesSelected;
  const paymentDetails = flightBookFormModel.paymentDetails;

  const airTravelerList = [];
  const offerPriced = [];
  const specialServices = [];
  const seatRequests = [];
  if (servicesSelected && servicesSelected.length > 0) {
    for (const serviceSelected of servicesSelected) {
      const type = serviceSelected.type;
      const quantity = serviceSelected.quantity ? serviceSelected.quantity : 1;
      const seatRowNumber = serviceSelected.seatRowNumber;
      const seatColumnNumber = serviceSelected.seatColumnNumber;
      const flightSegmentRPH = serviceSelected.flightSegmentRPH;
      const travelerRPH = serviceSelected.travelerRPH;
      const ancillaryId = serviceSelected.ancillaryId;

      let pricing = null;
      if (quantity) {
        pricing = {
          offerQty: quantity,
        };
      }

      let seatInfo = null;
      if (seatColumnNumber && seatRowNumber) {
        seatInfo = {
          columnNumber: seatColumnNumber,
          rowNumber: seatRowNumber,
        };
      }

      const offerSelected = {
        id: ancillaryId,
        travelerRPH: travelerRPH,
        flightSegmentRPH: flightSegmentRPH,
        pricing: pricing,
        seatInfo: seatInfo,
      };
      offerPriced.push(offerSelected);
    }
  }

  let specialServiceRequests = null;

  if (passengers && passengers.length > 0) {
    for (const passenger of passengers) {
      const passengerTypeCode = passenger.passengerTypeCode;
      const travelerRefNumberRPH = passenger.travelerRefNumberRPH;
      const quantity = passenger.quantity;
      const ffpNumber = passenger.ffpNumber;
      const nameTitle = passenger.nameTitle;
      const gender = passenger.gender;
      const givenName = passenger.givenName;
      const surname = passenger.surname;
      const birthDate = passenger.birthDate;
      const countryAccessCode = passenger.countryAccessCode;
      const dialAreaCode = passenger.dialAreaCode;
      const phoneUseType = passenger.phoneUseType;
      const phoneNumber = passenger.phoneNumber;
      const emailAddress = passenger.emailAddress;
      const documents = passenger.documents;
      const seatPrefs = passenger.seatPrefs;
      const mealPrefs = passenger.mealPrefs;

      if (seatPrefs && seatPrefs.length > 0) {
        seatPrefs.forEach((item) => {
          seatRequests.push({
            travelerRefNumberRPHList: [travelerRefNumberRPH],
            seatPreference: item.seatPreference,
          });
        });
      }
      if (mealPrefs && mealPrefs.length > 0) {
        mealPrefs.forEach((item) => {
          specialServices.push({
            travelerRefNumberRPHList: [travelerRefNumberRPH],
            ssrcode: item.mealType,
          });
        });
      }
      let custLoyaltyList = [];
      if (ffpNumber && ffpNumber.programID && ffpNumber.membershipID) {
        custLoyaltyList.push({
          travelSector: ffpNumber.travelSector,
          vendorCode: ffpNumber.vendorCode,
          programID: ffpNumber.programID,
          membershipID: ffpNumber.membershipID,
        });
      }
      const documentList = [];
      if (documents && documents.length > 0) {
        for (const document of documents) {
          const docType = document.docType;
          const docID = document.docID;
          const expireDate = document.expireDate;
          const docIssueCountry = document.docIssueCountry;
          const docHolderNationality = document.docHolderNationality;

          if (docID !== null && docID !== "")
            documentList.push({
              docID: docID,
              docType: docType,
              expireDate: expireDate,
              docIssueCountry: docIssueCountry,
              docHolderNationality: docHolderNationality,
            });
        }
      }

      airTravelerList.push({
        travelerRefNumber: {
          rph: travelerRefNumberRPH,
        },
        custLoyaltyList: custLoyaltyList,
        passengerTypeCode: passengerTypeCode,
        personName: {
          givenName: givenName,
          surname: surname,
          nameTitle: nameTitle,
        },
        gender: gender,
        documents: documentList,
        birthDate: birthDate,
        emailList: [
          {
            emailAddress: emailAddress,
          },
        ],
        telephoneList: [
          {
            phoneUseType: phoneUseType,
            countryAccessCode: countryAccessCode,
            areaCityCode: dialAreaCode,
            phoneNumber: phoneNumber,
          },
        ],
      });
    }
  }

  let fulfillment = null;
  if (paymentDetails && paymentDetails.paymentType === "CASH") {
    fulfillment = {
      paymentDetailList: [
        {
          paymentType: "CASH",
        },
      ],
    };
  } else if (paymentDetails && paymentDetails.paymentType === "CREDITCARD") {
    const cardCode = paymentDetails.cardCode;
    const cardNumber = paymentDetails.cardNumber;
    const channelCode = paymentDetails.channelCode;
    const expireDate = paymentDetails.expireDate;
    const seriesCode = paymentDetails.seriesCode;

    fulfillment = {
      paymentDetailList: [
        {
          paymentType: "CREDITCARD",
          paymentCard: {
            cardCode: cardCode,
            cardNumber: cardNumber,
            expireDate: expireDate + "-01",
            seriesCode: {
              encryptionValue: seriesCode,
            },
            threeDomainSecurity: {
              gateway: {
                channelCode: channelCode,
              },
            },
          },
        },
      ],
    };
  }

  return {
    targetName: "PROD",
    airItinerary: {
      airItineraryRPH: airItineraryRPH,
    },
    priceInfo: {
      quoteID: quoteID,
    },
    travelerInfo: {
      airTravelerList: airTravelerList,
    },
    fulfillment: fulfillment,
    offer: {
      priced: offerPriced,
    },
  };
}

function buildFlightPassengerFormItems(pricedItinerary) {
  const airItineraryPricingInfo = pricedItinerary.airItineraryPricingInfo;
  const ptcFareBreakdowns = airItineraryPricingInfo.PTC_FareBreakdowns;
  //Passengers
  const flightPassengerFormItems = [];
  for (const ptcFareBreakdown of ptcFareBreakdowns) {
    const travelerRefNumberList = ptcFareBreakdown.travelerRefNumberList;
    const passengerTypeCode = ptcFareBreakdown.passengerTypeQuantity.code;

    for (const travelerRefNumber of travelerRefNumberList) {
      const flightPassengerFormItem = new FlightPassengerFormItem(
        passengerTypeCode,
        travelerRefNumber.rph,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null
      );
      flightPassengerFormItems.push(flightPassengerFormItem);
    }
  }

  return flightPassengerFormItems;
}

export default {
  buildAirBookRQ,
  buildFlightPassengerFormItems,
};
