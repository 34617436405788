<script setup>
import { Empty, Modal } from "ant-design-vue";
import { ref } from "vue";
import dateUtils from "@/utils/dateUtils";
import FlightFareOptionItemStructuredPanel from "@/views/v2/sales/flight/items/panel/shopping/FlightFareOptionItemStructuredPanel.vue";
import FlightFareOptionItemNormalPanel from "@/views/v2/sales/flight/items/panel/shopping/FlightFareOptionItemNormalPanel.vue";
import commonUtils from "@/utils/commonUtils";
import { useFlightShoppingStore } from "@/stores/v2/flight/shopping/flightShoppingStore";

const props = defineProps({
  itineraryFareOptionKeyMap: {},
  fareOptionsSelected: {},
  fareItem: {},
  flightInfo: {},
  fareClock: {},
  fareActiveIndex: {},
  fareIndex: {},
  activeFlightGroupNumber: {},
  priceClasses: {},
});

const AModal = Modal;
const AEmpty = Empty;
const emits = defineEmits([]);
const flightShoppingStore = useFlightShoppingStore();
const mode = ref("left");

const fareDescriptionModalOpenRef = ref(false);

function changeFare(fareOptionsSelected, fareClock, fareIndex, fareItem) {
  clearInterval(fareClock);
  if (fareItem.expirationDateTime) emits("clock", fareItem.expirationDateTime);
  emits("changeFareActiveKeyIndex", fareIndex);
  fareOptionsSelected[props.activeFlightGroupNumber] = fareItem;
}

function getPriceClassName(priceClasses, priceClassId) {
  if (!priceClasses || priceClasses.length === 0) return "";

  if (priceClassId.indexOf("@@") >= 0) {
    let brandFareNames = [];
    //todo 是否去重
    const split = priceClassId.split("@@");
    split.forEach((id) => {
      priceClasses.forEach((e) => {
        if (id === e.priceClassId) {
          brandFareNames.push(e.priceClassName);
        }
      });
    });

    return brandFareNames.join(" + ");
  } else {
    return priceClasses.find((item) => item.priceClassId === priceClassId)
      .priceClassName;
  }
}

function filterPriceClassDescriptions(
  priceClasses,
  fareItem,
  flightSegmentMap
) {
  const priceClassId = fareItem.priceClassId;
  const segmentPriceClass = fareItem.segmentPriceClass;
  const priceClassIdSplit = priceClassId.split("@@");
  const priceClassesMap = {};
  priceClassIdSplit.forEach((id) => {
    const priceClassItem = priceClasses.find(
      (item) => item.priceClassId === id
    );
    const segmentPriceClasses = segmentPriceClass.filter(
      (item) => item.bundleID === id
    ); //找到该OD表示这种priceClassId的priceClasses

    if (segmentPriceClasses && segmentPriceClasses.length > 0) {
      segmentPriceClass.forEach((segmentPriceClassItem) => {
        const flightSegmentRPH = segmentPriceClassItem.flightSegmentRPH;
        const priceClassName = priceClassItem.priceClassName;
        const priceDescriptions = priceClassItem.priceDescriptions;
        const segmentInfo =
          flightSegmentMap && flightSegmentMap[flightSegmentRPH]
            ? flightSegmentMap[flightSegmentRPH]
            : null;
        priceClassesMap[flightSegmentRPH] = {
          priceClassName,
          priceDescriptions,
          segmentInfo,
        };
      });
    }
  });

  return priceClassesMap;
}

function sortDescriptions(priceDescriptions) {
  if (
    priceDescriptions &&
    priceDescriptions.length > 0 &&
    priceDescriptions.some((item) => item.code)
  ) {
    return commonUtils.sortByParams(priceDescriptions, "code");
  } else {
    return priceDescriptions;
  }
}

function closeFareDescriptionModal() {
  fareDescriptionModalOpenRef.value = false;
  // fareDescriptionsRef.value = [];
  descriptionActiveIndexRef.value = 0;
}

const descriptionActiveIndexRef = ref(0);
</script>

<template>
  <div
    class="theme_common_box_two img_hover cursor-point"
    :class="{
      'fare-active': fareActiveIndex === fareIndex,
    }"
    @click="changeFare(fareOptionsSelected, fareClock, fareIndex, fareItem)"
  >
    <div id="flight_type_1`" class="flight_ticket_content">
      <h4 v-if="fareItem.priceClassId" class="text-muted text-wrap">
        {{ getPriceClassName(priceClasses, fareItem.priceClassId) }}
      </h4>
      <h4>
        {{ fareItem.currencyCode }}
        {{ fareItem.totalPrice.toFixed(2) }}
      </h4>
      <h6 v-if="fareItem.fareTypeName || fareItem.fareTypeCode">
        {{ fareItem.fareTypeName }} ({{ fareItem.fareTypeCode }})
      </h6>
      <div>
        <div v-if="fareItem.expirationDateTime">
          {{ $t("expire-date") }}:
          <span class="text-warning">{{
            dateUtils.formatDateTime(fareItem.expirationDateTime)
          }}</span>
        </div>
        <div v-if="fareItem.cabinTypes && fareItem.cabinTypes.length > 0">
          <span>{{ $t("cabin") }}: {{ fareItem.cabinTypes.toString() }}</span>
        </div>
        <div
          style="display: flex; justify-content: center; align-items: center"
          class="mg-t-10 mg-b-10"
        >
          <div
            class="fare-btn"
            :class="{
              'fare-active': fareActiveIndex === fareIndex,
            }"
          ></div>
        </div>

        <div
          v-if="fareItem.priceClassId"
          class="main_color cursor-point text-end"
        >
          <span @click="fareDescriptionModalOpenRef = true">{{
            $t("see-fare-details")
          }}</span>
        </div>

        <div v-else class="main_color cursor-point text-end mg-t-20">
          <FlightFareOptionItemStructuredPanel
            :fare-index="fareIndex"
            :fare-item="fareItem"
            :flight-info="flightInfo"
            :itinerary-fare-option-key-map="itineraryFareOptionKeyMap"
          />
          |
          <FlightFareOptionItemNormalPanel
            :fare-index="fareIndex"
            :fare-item="fareItem"
            :flight-info="flightInfo"
            :itinerary-fare-option-key-map="itineraryFareOptionKeyMap"
            :price-classes="priceClasses"
          />
        </div>
      </div>
    </div>
  </div>

  <!-- 无需请求 fare 描述 -->
  <a-modal
    v-model:open="fareDescriptionModalOpenRef"
    :cancel-button-props="{ style: { display: 'none' } }"
    :maskClosable="false"
    :ok-button-props="{ style: { display: 'none' } }"
    :z-index="111111"
    centered
    width="40%"
    wrapClassName="fare-modal"
    @cancel="closeFareDescriptionModal"
  >
    <div class="mg-t-20">
      <h3>{{ $t("fare-rules") }}</h3>
      <div
        class="p-2"
        v-if="
          filterPriceClassDescriptions(
            priceClasses,
            fareItem,
            flightShoppingStore.flightSegmentMap
          )
        "
      >
        <div
          v-for="(priceClassItem, segmentRPH) in filterPriceClassDescriptions(
            priceClasses,
            fareItem,
            flightShoppingStore.flightSegmentMap
          )"
          :key="segmentRPH"
        >
          <div class="font_weight font-18">
            {{ priceClassItem.priceClassName }}
            <span
              v-if="priceClassItem.segmentInfo"
              class="text-muted mg-l-8 font-14"
            >
              ( {{ priceClassItem.segmentInfo.departureAirportCode }} -
              {{ priceClassItem.segmentInfo.arrivalAirportCode }} -
              {{ priceClassItem.segmentInfo.marketingAirlineCode }}
              {{ priceClassItem.segmentInfo.flightNumber }}
              )</span
            >
          </div>

          <div class="mg-up-10 p-xl-3">
            <div
              v-for="(descriptionItem, descriptionIndex) in sortDescriptions(
                priceClassItem.priceDescriptions
              )"
              :key="descriptionIndex"
            >
              <div>
                <span v-html="descriptionItem.description"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
  <!--无需请求 fare描述 END -->
</template>

<style scoped>
.fare-btn {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 1px solid #d2d2d275;
}

.fare-active {
  background-color: #7ae3c3;
  border: 1px solid #7ae3c3;
}
</style>
