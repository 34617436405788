import dateUtils from "@/utils/dateUtils";

function buildFilterOptions(flightItemVOs) {
  const airlineOptions = buildAirlineOptions(flightItemVOs);
  const stopsOptions = buildStopsOptions(flightItemVOs);
  const sourceOptions = buildSourceOptions(flightItemVOs);
  const supplierOptions = buildSupplierOptions(flightItemVOs);
  const stopoverAirportOptions = buildStopoverAirportOptions(flightItemVOs);
  return {
    airlineOptions: airlineOptions,
    stopsOptions: stopsOptions,
    sourceOptions: sourceOptions,
    supplierOptions: supplierOptions,
    stopoverAirportOptions: stopoverAirportOptions,
  };
}

function buildStopsOptions(flightItemVOs) {
  const stopOptions = [];
  if (flightItemVOs == null || flightItemVOs.length === 0) {
    return stopOptions;
  }
  const stopMap = {};
  for (const flightItemVO of flightItemVOs) {
    const numStops = flightItemVO.numStops;
    if (stopMap[numStops] >= 0) {
      stopMap[numStops]++;
      continue;
    }
    stopMap[numStops] = 1;
  }

  const stops = Object.keys(stopMap);
  stops.sort();
  for (const key of Object.keys(stops)) {
    stopOptions.push({
      label: key,
      value: key,
      quantity: stopMap[key],
    });
  }

  return stopOptions;
}

function buildSupplierOptions(flightItemVOs) {
  const supplierOptions = [];
  if (flightItemVOs == null || flightItemVOs.length === 0) {
    return supplierOptions;
  }

  const supplierCodeMap = {};
  for (const flightItemVO of flightItemVOs) {
    const supplierCodes = flightItemVO.supplierCodes;
    for (const supplierCode of supplierCodes) {
      if (supplierCodeMap[supplierCode]) {
        supplierCodeMap[supplierCode]++;
        continue;
      }

      supplierCodeMap[supplierCode] = 1;
    }
  }

  const supplierCodeMapKeys = Object.keys(supplierCodeMap);
  supplierCodeMapKeys.sort();

  for (const supplierCode of supplierCodeMapKeys) {
    supplierOptions.push({
      label: supplierCode,
      value: supplierCode,
      quantity: supplierCodeMap[supplierCode],
    });
  }

  return supplierOptions;
}

function buildSourceOptions(flightItemVOs) {
  const sourceOptions = [];
  if (flightItemVOs == null || flightItemVOs.length === 0) {
    return sourceOptions;
  }

  const sourceCodeMap = {};
  for (const flightItemVO of flightItemVOs) {
    const bookingSourceTypes = flightItemVO.bookingSourceTypes;
    for (const bookingSourceTYpe of bookingSourceTypes) {
      if (sourceCodeMap[bookingSourceTYpe]) {
        sourceCodeMap[bookingSourceTYpe]++;
        continue;
      }

      sourceCodeMap[bookingSourceTYpe] = 1;
    }
  }

  const sourceCodeMapKeys = Object.keys(sourceCodeMap);
  sourceCodeMapKeys.sort();

  for (const sourceCode of sourceCodeMapKeys) {
    sourceOptions.push({
      label: sourceCode,
      value: sourceCode,
      quantity: sourceCodeMap[sourceCode],
    });
  }

  return sourceOptions;
}

// function buildCabinTypeOptions(flightItemVOs) {
//   const cabinTypeOptions = [];
//   if (flightItemVOs == null || flightItemVOs.length === 0) {
//     return cabinTypeOptions;
//   }
//
//   const cabinTypeMap = {};
//   for (const flightItemVO of flightItemVOs) {
//     const bookingSourceTypes = flightItemVO.bookingSourceTypes;
//     for (const bookingSourceTYpe of bookingSourceTypes) {
//       if (cabinTypeMap[bookingSourceTYpe]) {
//         cabinTypeMap[bookingSourceTYpe]++;
//         continue;
//       }
//
//       cabinTypeMap[bookingSourceTYpe] = 1;
//     }
//   }
//
//   const cabinTypeMapKeys = Object.keys(cabinTypeMap);
//   cabinTypeMapKeys.sort();
//
//   for (const cabinType of cabinTypeMapKeys) {
//     cabinTypeOptions.push({
//       label: cabinType,
//       value: cabinType,
//       quantity: cabinTypeMap[cabinType],
//     });
//   }
//
//   return cabinTypeOptions;
// }

function buildAirlineOptions(flightItemVOs) {
  const airlineOptions = [];
  if (flightItemVOs == null || flightItemVOs.length === 0) {
    return airlineOptions;
  }

  const airlineCodeMap = {};
  for (const flightItemVO of flightItemVOs) {
    const flightSegments = flightItemVO.flightSegments;
    const marketingAirlineCodes = [];
    for (const flightSegment of flightSegments) {
      const marketingAirlineCode = flightSegment.marketingAirlineCode;

      if (marketingAirlineCodes.indexOf(marketingAirlineCode) >= 0) continue;
      marketingAirlineCodes.push(marketingAirlineCode);
    }

    for (const marketingAirlineCode of marketingAirlineCodes) {
      if (airlineCodeMap[marketingAirlineCode]) {
        airlineCodeMap[marketingAirlineCode]++;
        continue;
      }
      airlineCodeMap[marketingAirlineCode] = 1;
    }
  }

  const airlineCodeArr = Object.keys(airlineCodeMap);
  airlineCodeArr.sort();
  for (const airlineCode of airlineCodeArr) {
    airlineOptions.push({
      label: airlineCode,
      value: airlineCode,
      quantity: airlineCodeMap[airlineCode],
    });
  }

  return airlineOptions;
}

function buildStopoverAirportOptions(flightItemVOs) {
  const stopoverAirportCodeOptions = [];
  if (flightItemVOs == null || flightItemVOs.length === 0) {
    return stopoverAirportCodeOptions;
  }

  const stopoverAirportCodeMap = {};
  for (const flightItemVO of flightItemVOs) {
    const flightSegments = flightItemVO.flightSegments;
    if (flightSegments.length === 1) continue;

    for (let i = 0; i < flightSegments.length; i++) {
      const arrivalAirportCode = flightSegments[i].arrivalAirportCode;
      if (i === flightSegments.length - 1) {
        continue;
      }
      if (stopoverAirportCodeMap[arrivalAirportCode]) {
        stopoverAirportCodeMap[arrivalAirportCode]++;
        continue;
      }

      stopoverAirportCodeMap[arrivalAirportCode] = 1;
    }
  }

  const stopoverAirportCodes = Object.keys(stopoverAirportCodeMap);
  stopoverAirportCodes.sort();
  for (const airportCode of stopoverAirportCodes) {
    stopoverAirportCodeOptions.push({
      label: airportCode,
      value: airportCode,
      quantity: stopoverAirportCodeMap[airportCode],
    });
  }

  return stopoverAirportCodeOptions;
}

function filterFlightItems(flightItemVOs, flightShoppingFilterFormModel) {
  if (!flightItemVOs || flightItemVOs.length === 0) return flightItemVOs;

  //Filter options
  const stops = flightShoppingFilterFormModel.stops;
  const airlineCodes = flightShoppingFilterFormModel.airlineCodes;
  const sourceCodes = flightShoppingFilterFormModel.sourceCodes;
  const travelAndBaggageCodes =
    flightShoppingFilterFormModel.travelAndBaggageCodes;
  const departureTimePeriods =
    flightShoppingFilterFormModel.departureTimePeriods;
  const arrivalTimePeriods = flightShoppingFilterFormModel.arrivalTimePeriods;
  const stopoverAirportCodes =
    flightShoppingFilterFormModel.stopoverAirportCodes;
  const supplierCodes = flightShoppingFilterFormModel.supplierCodes;

  return flightItemVOs.filter((flightItemVO) => {
    let match = true;

    const numStops = flightItemVO.numStops;
    const supplierCodesCompare = flightItemVO.supplierCodes;
    const bookingSourceTypesCompare = flightItemVO.bookingSourceTypes;
    const flightSegments = flightItemVO.flightSegments;
    const departureDateTime = flightItemVO.departureDateTime;
    const arrivalDateTime = flightItemVO.arrivalDateTime;

    const airlineCodesCompare = [];
    const stopoverAirportCodesCompare = [];
    for (let i = 0; i < flightSegments.length; i++) {
      const flightSegment = flightSegments[i];
      const marketingAirlineCode = flightSegment.marketingAirlineCode;
      if (airlineCodes && airlineCodes.length > 0)
        airlineCodesCompare.push(marketingAirlineCode);

      if (flightSegments.length === 1) {
      } else if (i !== flightSegments.length - 1) {
        const arrivalAirportCode = flightSegment.arrivalAirportCode;
        stopoverAirportCodesCompare.push(arrivalAirportCode);
      }
    }

    //Stops filter
    if (stops && stops.length > 0 && stops.indexOf(numStops + "") < 0)
      return false;
    //Airline filter
    if (
      airlineCodes &&
      airlineCodes.length > 0 &&
      airlineCodesCompare.length > 0
    ) {
      let oneMatch = false;
      for (const airlineCodeCompare of airlineCodesCompare) {
        if (airlineCodes.indexOf(airlineCodeCompare) >= 0) oneMatch = true;
      }
      if (!oneMatch) return false;
    }
    //Stopover filter
    if (stopoverAirportCodes && stopoverAirportCodes.length > 0) {
      let oneMatch = false;
      for (const stopoverAirportCode of stopoverAirportCodesCompare) {
        if (stopoverAirportCodes.indexOf(stopoverAirportCode) >= 0)
          oneMatch = true;
      }

      if (!oneMatch) return false;
    }
    //Supplier filter
    if (supplierCodes && supplierCodes.length > 0) {
      let oneMatch = false;
      for (const supplierCodeCompare of supplierCodesCompare) {
        if (supplierCodes.indexOf(supplierCodeCompare) >= 0) oneMatch = true;
      }
      if (!oneMatch) return false;
    }
    //Source filter
    if (sourceCodes && sourceCodes.length > 0) {
      let oneMatch = false;
      for (const bookingSourceTypeCompare of bookingSourceTypesCompare) {
        if (sourceCodes.indexOf(bookingSourceTypeCompare) >= 0) oneMatch = true;
      }
      if (!oneMatch) return false;
    }
    //Departure Time Filter
    if (departureTimePeriods && departureTimePeriods.length > 0) {
      let oneMatch = false;
      for (const departurePeriod of departureTimePeriods) {
        const split = departurePeriod.split("-");
        const startTime = split[0];
        const endTime = split[1];
        const defaultDate = departureDateTime.split("T")[0];
        const compareDepartureDateTimeFormat =
          departureDateTime.split(".000")[0];
        if (
          dateUtils.compareIfBefore(
            defaultDate + "T" + startTime,
            compareDepartureDateTimeFormat
          ) &&
          dateUtils.compareIfBefore(
            compareDepartureDateTimeFormat,
            defaultDate + "T" + endTime
          )
        ) {
          oneMatch = true;
        }
      }
      if (!oneMatch) return false;
    }
    //Arrival Time Filter
    if (arrivalTimePeriods && arrivalTimePeriods.length > 0) {
      let oneMatch = false;
      for (const arrivalTimePeriod of arrivalTimePeriods) {
        const split = arrivalTimePeriod.split("-");
        const startTime = split[0];
        const endTime = split[1];
        const defaultDate = arrivalDateTime.split("T")[0];
        const compareDepartureDateTimeFormat = arrivalDateTime.split(".000")[0];
        if (
          dateUtils.compareIfBefore(
            defaultDate + "T" + startTime,
            compareDepartureDateTimeFormat
          ) &&
          dateUtils.compareIfBefore(
            compareDepartureDateTimeFormat,
            defaultDate + "T" + endTime
          )
        ) {
          oneMatch = true;
        }
      }
      if (!oneMatch) return false;
    }
    return match;
  });
}

export default {
  buildFilterOptions,
  filterFlightItems,
};
