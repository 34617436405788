<script setup>
import { ref } from "vue";
import CustomerProfileCommunicationAccountSupportForm from "@/views/profiles/items/form/communication/CustomerProfileCommunicationAccountSupportForm.vue";
import { useI18n } from "vue-i18n";
import { Modal } from "ant-design-vue";

const { t } = useI18n();
const AModal = Modal;
const communicationEditModalOpenRef = ref(false);

function editCommunications() {
  communicationEditModalOpenRef.value = true;
}
</script>

<template>
  <div class="row">
    <div class="col-10">
      <div class="font_weight font-24">Communications</div>
    </div>

    <div class="col-12">
      <div class="row">
        <div class="col-12 mg-b-10">
          Choose how we notify you about account and reward updates.
        </div>
        <div>
          <button
            class="btn btn-secondary"
            style="width: 40%"
            @click="editCommunications"
          >
            <div class="text-start">
              <div>Account support</div>
              <div class="font-14">Email</div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>

  <!--  add/edit additional traveller-->
  <a-modal
    v-model:open="communicationEditModalOpenRef"
    :cancel-button-props="{ style: { display: 'none' } }"
    :ok-button-props="{ style: { display: 'none' } }"
    centered
    :maskClosable="false"
    width="40%"
    z-index="22222"
  >
    <CustomerProfileCommunicationAccountSupportForm />
  </a-modal>
  <!-- add/edit  additional traveller END-->
</template>

<style scoped></style>
