<script setup>
import { createVNode, ref, watch, watchEffect } from "vue";
import { Empty, Modal } from "ant-design-vue";
import { useTemplateStore } from "@/stores/template";

import { useI18n } from "vue-i18n";
import { FlightModifyFormModel } from "@/formmodel/sales/flight/modify/flightModifyFormModel";
import { TripAirItemPaymentDetailVO } from "@/viewobject/trip/flight/payment/tripAirItemPaymentDetailVO";
import { useFlightModifyStore } from "@/stores/v2/flight/modify/flightModifyStore";
// import BaseFlightPaymentForm from "@/components/payment/BaseFlightPaymentForm.vue";
import { useRoute, useRouter } from "vue-router";
import { useFlightRepriceStore } from "@/stores/v2/flight/price/flightRepriceStore";
import flightCommonTools from "@/tools/v2/flight/common/flightCommonTools";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";

const props = defineProps({
  servicesSelected: {},
  segments: {},
  passengers: {},
  flightRePriceFormModel: {},
  airlineCapability: {},
  tripAirItem: {},
  servicesSelectedOld: {},
  idContext: {},
});

const route = useRoute();
const router = useRouter();
const AModal = Modal;
const emits = defineEmits();
const { t, locale } = useI18n(); // 解构调用函数

const flightModifyStore = useFlightModifyStore();
let templateStore = useTemplateStore();
const flightRePriceStore = useFlightRepriceStore();

const recordLocator = route.query.recordLocator;
const idContext = route.query.idContext;
const tripItemType = route.query.tripItemType
  ? route.query.tripItemType
  : t("air-reservation");
const tripId = route.query.tripId;
const tripAirItemId = route.query.tripAirItemId;

const paymentMethodOpenRef = ref(false);
const paymentFormOpenRef = ref(false);

const AEmpty = Empty;

const serviceTotalAmountRef = ref({
  amount: 0,
  currencyCode: null,
});

const servicesRepriceTotalAmountRef = ref({
  amount: 0,
  currencyCode: null,
});

const errorServerMessageRef = ref(null);

const flightRePriceFormModelRef = ref(null);

const flightModifyFormModelRef = ref(
  new FlightModifyFormModel(
    "AcceptOffer",
    tripId,
    tripAirItemId,
    null,
    recordLocator,
    idContext,
    props.servicesSelected,
    new TripAirItemPaymentDetailVO(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    )
  )
);

watch(
  () => props.flightRePriceFormModel,
  (newValue) => {
    flightRePriceFormModelRef.value = newValue;
  },
  { immediate: true, deep: true }
);

watchEffect(() => {
  if (props.servicesSelected) {
    serviceTotalAmountRef.value =
      flightRePriceFormModelRef.value.calculateServicesTotalPrice();
  }
});

watchEffect(() => {
  if (flightRePriceStore.flightRepriceItem) {
    servicesRepriceTotalAmountRef.value = {
      amount: 0,
      currencyCode: null,
    };
    const flightRepriceItem = flightRePriceStore.flightRepriceItem;
    let amount = 0;
    let currencyCode = null;
    if (flightRepriceItem && flightRepriceItem.addItems) {
      const addItems = flightRepriceItem.addItems;

      const newItem = addItems ? addItems.newItem : null;
      if (newItem) currencyCode = newItem.currencyCode;
      amount = newItem.totalAmount;

      servicesRepriceTotalAmountRef.value = {
        amount: amount,
        currencyCode: currencyCode,
      };
    }
  }
});

function removeService(serviceSelected, servicesSelectedOld, idContext) {
  let containItem = null;
  //如果要删除的这个服务是modify过的服务，那么将这个服务从表单对象中删除
  if (servicesSelectedOld && servicesSelectedOld.length > 0) {
    containItem = servicesSelectedOld.find(
      (item) =>
        item.code === "189C" &&
        item.flightSegmentRPH === serviceSelected.flightSegmentRPH &&
        item.travelerRPH === serviceSelected.travelerRPH
    );
  }

  if (containItem && idContext === "FARELOGIX-UA") {
    //删除要add项
    const flightSegmentRPH = serviceSelected.flightSegmentRPH;
    const travelerRPH = serviceSelected.travelerRPH;
    const ancillaryId = serviceSelected.ancillaryId;

    flightRePriceFormModelRef.value.removeService(
      flightSegmentRPH,
      travelerRPH,
      ancillaryId,
      null
    );

    //删除要remove项
    const removeFlightSegmentRPH = containItem.flightSegmentRPH;
    const removeTravelerRPH = containItem.travelerRPH;
    const removeAncillaryId = containItem.ancillaryId;

    flightRePriceFormModelRef.value.removeServiceRemoval(
      removeFlightSegmentRPH,
      removeTravelerRPH,
      removeAncillaryId,
      null
    );
  } else {
    const flightSegmentRPH = serviceSelected.flightSegmentRPH;
    const travelerRPH = serviceSelected.travelerRPH;
    const ancillaryId = serviceSelected.ancillaryId;
    flightRePriceFormModelRef.value.removeService(
      flightSegmentRPH,
      travelerRPH,
      ancillaryId,
      null
    );
  }
}

function getSegmentPassengerServices(
  servicesSelected,
  segmentRPH,
  travelerRefNumberRPH
) {
  let services = [];

  if (servicesSelected && servicesSelected.length > 0) {
    services = servicesSelected.filter(
      (item) =>
        item.travelerRPH === travelerRefNumberRPH &&
        item.flightSegmentRPH === segmentRPH &&
        item.code === "189C"
    );
  }
  return services;
}

async function confirmSeatSelections(flightRePriceFormModel, idContext) {
  const servicesSelected = flightRePriceFormModel.servicesSelected;
  if (servicesSelected && servicesSelected.length > 0) {
    switch (idContext) {
      case "FARELOGIX-EMIRATES":
        confirmSeatSelectionsEK(flightRePriceFormModel);
      case "FARELOGIX-UA":
        confirmSeatSelectionsUA(flightRePriceFormModel);
        break;
      case "SQ":
        confirmSeatSelectionsSQ(flightRePriceFormModel);
        break;
    }
  } else {
    toast.fire(t("warning"), t("you-have-not-selected-seat"), "warning");
  }
}

async function confirmSeatSelectionsSQ(flightRePriceFormModel) {
  templateStore.pageLoader({ mode: "on" });
  let res = await flightRePriceStore.airReprice(flightRePriceFormModel);
  if (!res.success) {
    let error = "";
    if (res.errors && res.errors.errors && res.errors.errors.length > 0) {
      error = res.errors.errors[0].stringValue;
    }
    errorServerMessageRef.value = error ? error : "Sever error!";
    openMassageModal(errorServerMessageRef.value, 2, true);
  } else {
    //reprice成功
    AModal.confirm({
      content: t("continue-pay-services", {
        currencyCode: servicesRepriceTotalAmountRef.value.currencyCode,
        amount: servicesRepriceTotalAmountRef.value.amount,
      }),
      icon: createVNode(ExclamationCircleOutlined),
      centered: true,
      width: 600,
      wrapClassName: "confirmModal",
      onOk() {
        return new Promise((resolve, reject) => {
          //确认modify当前附加服务，弹出支付方式
          flightModifyFormModelRef.value.servicesSelected =
            flightRePriceFormModel.servicesSelected;
          paymentMethodOpenRef.value = true;
        }).catch((err) => {
          AModal.destroyAll();
          templateStore.pageLoader({ mode: "off" });
        });
      },
      cancelText: "Cancel",
      onCancel() {
        AModal.destroyAll();
        templateStore.pageLoader({ mode: "off" });
      },
    });
  }
}

function confirmSeatSelectionsEK(flightRePriceFormModel) {
  AModal.confirm({
    content: t("continue-pay-services-now"),
    icon: createVNode(ExclamationCircleOutlined),
    centered: true,
    width: 600,
    wrapClassName: "confirmModal",
    onOk() {
      return new Promise((resolve, reject) => {
        //确认modify当前附加服务，弹出支付方式
        flightModifyFormModelRef.value.servicesSelected =
          flightRePriceFormModel.servicesSelected;
        paymentMethodOpenRef.value = true;
      }).catch((err) => {
        AModal.destroyAll();
      });
    },
    cancelText: "Cancel",
    onCancel() {
      AModal.destroyAll();
    },
  });
}

function confirmSeatSelectionsUA(flightRePriceFormModel) {
  AModal.confirm({
    content: t("continue-pay-services-now"),
    icon: createVNode(ExclamationCircleOutlined),
    centered: true,
    width: 600,
    wrapClassName: "confirmModal",
    onOk() {
      return new Promise((resolve, reject) => {
        //确认modify当前附加服务，弹出支付方式
        flightModifyFormModelRef.value.servicesSelected =
          flightRePriceFormModel.servicesSelected;
        flightModifyFormModelRef.value.servicesRemoval =
          flightRePriceFormModel.servicesRemoval;
        paymentMethodOpenRef.value = true;
      }).catch((err) => {
        AModal.destroyAll();
      });
    },
    cancelText: "Cancel",
    onCancel() {
      AModal.destroyAll();
    },
  });
}

function selectPaymentType(
  paymentType,
  flightModifyFormModel,
  servicesRepriceTotalAmount
) {
  if (flightModifyFormModel) {
    switch (paymentType) {
      case "CREDITCARD":
        flightModifyFormModel.paymentDetails.paymentType = "CREDITCARD";
        flightModifyFormModel.paymentDetails.channelCode = "EC";
        flightModifyFormModel.paymentDetails.amount =
          servicesRepriceTotalAmount.amount;
        flightModifyFormModel.paymentDetails.currencyCode =
          servicesRepriceTotalAmount.currencyCode;
        paymentMethodOpenRef.value = false;
        paymentFormOpenRef.value = true;
        break;
      case "CASH":
        paymentMethodOpenRef.value = false;
        flightModifyFormModel.paymentDetails.paymentType = "CASH";
        flightModifyFormModel.paymentDetails.amount =
          servicesRepriceTotalAmount.amount;
        flightModifyFormModel.paymentDetails.currencyCode =
          servicesRepriceTotalAmount.currencyCode;
        confirmThisTrip(
          t("you-will-pay-for-services"),
          "PAY_LATER",
          flightModifyFormModel
        );
        break;
      case "DIRECT":
        flightModifyFormModel.paymentDetails.paymentType = "CREDITCARD";
        flightModifyFormModel.paymentDetails.channelCode = "MO";
        flightModifyFormModel.paymentDetails.amount =
          servicesRepriceTotalAmount.amount;
        flightModifyFormModel.paymentDetails.currencyCode =
          servicesRepriceTotalAmount.currencyCode;
        paymentMethodOpenRef.value = false;
        paymentFormOpenRef.value = true;
        break;
      case "DEFERRED":
        paymentMethodOpenRef.value = false;
        flightModifyFormModel.paymentDetails.paymentType = null;
        flightModifyFormModel.paymentDetails.amount =
          servicesRepriceTotalAmount.amount;
        flightModifyFormModel.paymentDetails.currencyCode =
          servicesRepriceTotalAmount.currencyCode;
        confirmThisTrip(
          t("you-will-pay-for-services"),
          "PAY_LATER",
          flightModifyFormModel
        );
        break;
    }
  }
}

function confirmThisTrip(message, type, flightModifyFormModel) {
  AModal.confirm({
    content: message,
    icon: createVNode(ExclamationCircleOutlined),
    centered: true,
    width: 600,
    wrapClassName: "confirmModal",
    onOk() {
      return new Promise((resolve, reject) => {
        //选择CASH或者Deferred Payment
        //进行air modify
        templateStore.pageLoader({ mode: "on" });
        flightModifyStore.airBookModify(flightModifyFormModel).then((res) => {
          if (res.success) {
            router.push({
              name: "modify-success",
              query: route.query,
            });
          } else {
            errorServerMessageRef.value = res.errors.errors[0].stringValue;
            openMassageModal(errorServerMessageRef.value, 2, true);
          }
          paymentFormOpenRef.value = false;
          templateStore.pageLoader({ mode: "off" });
        });
      }).catch((err) => {
        AModal.destroyAll();
      });
    },
    cancelText: "Cancel",
    onCancel() {
      AModal.destroyAll();
      paymentFormOpenRef.value = false;
      templateStore.pageLoader({ mode: "off" });
    },
  });
}

function closePaymentForm() {
  paymentFormOpenRef.value = false;
  paymentMethodOpenRef.value = false;
}

function openMassageModal(message, type, functionType) {
  if (type === 1) {
    AModal.success({
      title: "This is a success message",
      content: message,
      centered: true,
      width: 600,
    });
  } else {
    if (functionType === "AIR_PRICE") {
      AModal.error({
        title: "This is an error message",
        content: message,
        centered: true,
        width: 600,
        onOk() {
          return new Promise((resolve, reject) => {
            window.close(); //air price的时候出问题直接关闭页面回到list页面重选
          }).catch(() => console.log("Oops errors!"));
        },
      });
    } else {
      AModal.error({
        title: "This is an error message",
        content: message,
        centered: true,
        width: 600,
      });
    }
  }
}

function sortSegments(segments) {
  if (segments && segments.length > 0) {
    return segments.sort((a, b) => a.departureDateTime - b.departureDateTime);
  }
}

function buildPaymentMethodOptions(airlineCapability, tripAirItem) {
  //1.确定当前预定步骤
  const ticketStatus = flightCommonTools.getTicketStatus(
    tripAirItem.ticketings
  );
  const workflowStep =
    ticketStatus === "TICKETED" ? "AFTERTICKETING" : "POSTBOOKING"; //判断是否已经出票

  //2、拿到所有支付方式
  let paymentOptions = flightCommonTools.filterPaymentOptions(
    airlineCapability,
    workflowStep,
    "ANCILLARY",
    "SEAT"
  );

  // //3.特殊情况处理
  // const supplierCode = tripAirItem ? tripAirItem.supplierCode : null;
  // if (supplierCode === "FARELOGIX-EMIRATES" && paymentOptions.length > 0) {
  //   console.log(tripAirItem.bookingSourceType);
  //   paymentOptions = paymentOptions.filter((item) => item.code === "DEFERRED");
  // }
  return paymentOptions;
}
</script>

<template>
  <div
    v-for="(segment, segIndex) in sortSegments(segments)"
    :key="segIndex"
    class="mg-t-20"
  >
    <div class="font_weight">
      <span>
        {{ segment.departureDateTime.split("T")[0] }}
      </span>

      <span class="mg-l-10"
        >{{ segment.departureAirportCode }}
        <i class="bi bi-arrow-right main_color"></i>
        {{ segment.arrivalAirportCode }}</span
      >

      <span class="mg-l-10">
        {{ segment.marketingAirlineCode }}{{ segment.flightNumber }}
      </span>
    </div>
    <div class="p-2">
      <div
        v-for="(passenger, passengerIndex) in passengers"
        :key="passengerIndex"
      >
        <div class="font_weight">
          {{ $t("passenger") }} - {{ passenger.travelerRefNumber }}
        </div>
        <div
          v-if="
            getSegmentPassengerServices(
              servicesSelected,
              segment.rph,
              passenger.travelerRefNumber
            ) &&
            getSegmentPassengerServices(
              servicesSelected,
              segment.rph,
              passenger.travelerRefNumber
            ).length > 0
          "
        >
          <div
            v-for="(service, serviceIndex) in getSegmentPassengerServices(
              servicesSelected,
              segment.rph,
              passenger.travelerRefNumber
            )"
            :key="serviceIndex"
            class="row mg-t-10"
          >
            <div class="col-7">
              {{ service.name }}
              <span
                v-if="
                  service.type === 'SEAT' &&
                  service.seatRowNumber &&
                  service.seatColumnNumber
                "
                class="text-bg-info mg-l-10 p-1"
                >{{ service.seatRowNumber }}
                {{ service.seatColumnNumber }}</span
              >
              <span class="mg-l-10 text-muted"> x {{ service.quantity }}</span>
            </div>
            <div class="col-3 text-end">
              {{ service.currencyCode }}
              {{ (service.amount * service.quantity).toFixed(2) }}
            </div>
            <div class="col-2 font-18 text-warning">
              <i
                class="bi bi-x-square cursor-point"
                @click="removeService(service, servicesSelectedOld, idContext)"
              ></i>
            </div>
          </div>
        </div>
        <div v-else class="p-1">
          <span class="text-muted">{{ $t("no-selection") }}</span>
        </div>
      </div>
    </div>
    <hr />
  </div>

  <div v-if="serviceTotalAmountRef" class="row mg-t-10">
    <div class="col-7 font_weight">
      {{ $t("total-amount") }}
    </div>
    <div class="col-5 text-end font_weight">
      {{ serviceTotalAmountRef.currencyCode }}
      {{
        serviceTotalAmountRef.amount
          ? serviceTotalAmountRef.amount.toFixed(2)
          : "0.00"
      }}
    </div>
  </div>

  <div
    class="row mg-t-20"
    v-if="servicesSelected && servicesSelected.length > 0"
  >
    <div class="col-6">
      <h4 class="text-muted">
        {{ servicesSelected.filter((item) => item.code === "189C").length }} /
        {{ passengers.length * segments.length }} {{ $t("seat-selected") }}
      </h4>
    </div>
    <div class="col-6 text-end">
      <button
        class="btn btn-outline-primary mb-2"
        @click="confirmSeatSelections(flightRePriceFormModelRef, idContext)"
      >
        {{ $t("confirm") }}
      </button>
    </div>
  </div>

  <!-- 选择支付方式-->
  <a-modal
    v-model:open="paymentMethodOpenRef"
    :cancel-button-props="{ style: { display: 'none' } }"
    :ok-button-props="{ style: { display: 'none' } }"
    centered
    @cancel="paymentMethodOpenRef = false"
  >
    <div class="mg-t-20">
      <h3>{{ $t("please-select-your-payment-method") }}</h3>
    </div>
    <div
      class="mg-t-20 text-center"
      v-if="buildPaymentMethodOptions(airlineCapability, tripAirItem)"
    >
      <button
        class="btn me-1 mb-3 font-18 mg-l-20"
        :class="[paymentMethod.color]"
        v-for="(paymentMethod, paymentMethodIndex) in buildPaymentMethodOptions(
          airlineCapability,
          tripAirItem
        )"
        :key="paymentMethodIndex"
        @click="
          selectPaymentType(
            paymentMethod.code,
            flightModifyFormModelRef,
            servicesRepriceTotalAmountRef
          )
        "
      >
        {{ $t(paymentMethod.name) }}
      </button>
    </div>
  </a-modal>
  <!-- 选择出票的支付方式END-->

  <!--  payment form modal content-->
  <a-modal
    v-model:open="paymentFormOpenRef"
    :cancel-button-props="{ style: { display: 'none' } }"
    :maskClosable="false"
    :ok-button-props="{ style: { display: 'none' } }"
    :zIndex="11111"
    centered
    width="100%"
    wrapClassName="payment-form-full-modal"
    @cancel="closePaymentForm"
  >
    <div v-if="paymentFormOpenRef" class="form-center">
      <!--      <BaseFlightPaymentForm-->
      <!--        :form-model="flightModifyFormModelRef"-->
      <!--        :is-service="true"-->
      <!--        @payNowByCreditOrDirect="confirmThisTrip"-->
      <!--      />-->
    </div>
  </a-modal>
  <!--  payment form modal content END-->
</template>

<style lang="scss"></style>
