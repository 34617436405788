<script setup>
import { useRoute, useRouter } from "vue-router";
import { useTemplateStore } from "@/stores/template";
import { reactive, ref, watch } from "vue";
import { useCmsContentStore } from "@/stores/cms";
import { Modal } from "ant-design-vue";
import { TripsReadFormModel } from "@/formmodel/sales/trip/tripsReadFormModel";
import { useTripStore } from "@/stores/v2/trip/tripStore";
import BackButton from "@/components/custom/BackButton.vue";
import Banner from "@/components/custom/Banner.vue";
import ReservationDetailsFlightPanel from "@/views/v2/trip/booking/items/panel/flight/ReservationDetailsFlightPanel.vue";
import ReservationDetailsHotelPanel from "@/views/v2/trip/booking/items/panel/hotel/ReservationDetailsHotelPanel.vue";

const bannerContentStore = useCmsContentStore();
bannerContentStore.getProductBannerBackground();
let bannerContent = reactive(null);
const AModal = Modal;
const navigation = reactive({
  title: "Booking Details",
  routes: [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Customer Dashboard",
      path: "/profiles/customer-dashboard",
    },
    {
      name: "Booking Details",
    },
  ],
});
const router = useRouter();
const route = useRoute();
const templateStore = useTemplateStore();
const tripStore = useTripStore();

const tripId = route.query.tripId;
const recordLocator = route.query.recordLocator;
const idContext = route.query.idContext;
const tripItemType = route.query.tripItemType;
const isFromTripList =
  route.query && route.query.isFromTripList
    ? JSON.parse(route.query.isFromTripList)
    : false;
//region cms内容管理
const cmsContentStore = useCmsContentStore();
cmsContentStore.getFlightHeaderContent();

//机票背景图片
const backgroundImageUrlRef = ref(null);
watch(
  () => cmsContentStore.flightHeaderContent,
  (newValue) => {
    const baseUrl = "/api/v3/content/collections/images";
    if (newValue && newValue.backgoundImage) {
      const backgoundImage = newValue.backgoundImage;
      const url = backgoundImage ? backgoundImage.url : null;
      backgroundImageUrlRef.value = url ? baseUrl + url : null;
    }
  },
  { immediate: true, deep: true }
);
//endregion cms内容管理

const tripsReadFormModelRef = ref(
  new TripsReadFormModel("BOOKING_ID", tripId, 1, 0, null, null, null, null)
);
let messageContent = reactive({
  show: false,
  message: null,
  type: null,
});

syncSessionAndSearch(tripsReadFormModelRef.value);

async function syncSessionAndSearch(tripsReadFormModel) {
  templateStore.pageLoader({ mode: "on" });
  let res = await tripStore.tripsRead(tripsReadFormModel);
  templateStore.pageLoader({ mode: "off" });
}

let equipmentType = ref(templateStore.responsive.equipmentType);
watch(
  () => templateStore.responsive.equipmentType,
  (newValue) => {
    equipmentType.value = newValue;
  }
);
</script>

<template>
  <!-- Common Banner Area -->
  <Banner
    :navigate="navigation"
    :background-image-url="backgroundImageUrlRef"
  />
  <section id="tour_booking_submission" class="section_padding">
    <div class="container">
      <div class="row" v-if="equipmentType !== 'iphone'">
        <div
          class="col-lg-12 col-md-12 col-sm-12 col-12"
          style="margin-left: 8%"
        >
          <BackButton
            :button-name="'Go to booking list'"
            :route-name="'profiles-customer-dashboard'"
          ></BackButton>
        </div>
      </div>
      <div class="row margin-top-20">
        <div class="col-lg-10 mx-auto">
          <div class="tou_booking_form_Wrapper">
            <div
              class="alert d-flex align-items-center justify-content-between"
              :class="{
                'alert-danger': messageContent.showType == 'alert-danger',
                'alert-success': messageContent.showType == 'alert-success',
              }"
              role="alert"
              v-if="messageContent.show"
            >
              <div class="flex-grow-1 me-3">
                <p class="mb-0">
                  {{ messageContent.message }}
                </p>
              </div>
              <div class="flex-shrink-0">
                <i
                  class="fa fa-fw fa-times-circle"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                ></i>
              </div>
            </div>

            <!-- Page Content -->
            <div class="content">
              <ReservationDetailsFlightPanel
                v-if="tripItemType === 'AIR'"
                :is-from-trip-list="isFromTripList"
                :trip-id="tripId"
                :id-context="idContext"
                :record-locator="recordLocator"
                :trip-item-type="tripItemType"
                :equipment-type="equipmentType"
                @syncSessionAndSearch="
                  syncSessionAndSearch(tripsReadFormModelRef)
                "
              />

              <ReservationDetailsHotelPanel
                v-else-if="tripItemType === 'HOTEL'"
                :is-from-trip-list="isFromTripList"
                :trip-id="tripId"
                :trip-item-type="tripItemType"
                @syncSessionAndSearch="
                  syncSessionAndSearch(tripsReadFormModelRef)
                "
              />
            </div>
            <!-- END Page Content -->
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>
