<script setup>
import { onUnmounted, ref, watch } from "vue";
import { useFlightShoppingStore } from "@/stores/v2/flight/shopping/flightShoppingStore";
import dateUtils from "@/utils/dateUtils";

import { useI18n } from "vue-i18n";
import { Modal } from "ant-design-vue";
import FlightFareOptionItem from "@/views/v2/sales/flight/items/group/FlightFareOptionItem.vue";
import commonUtils from "@/utils/commonUtils";
import flightCommonTools from "../../../../../../tools/v2/flight/common/flightCommonTools";

const AModal = Modal;
const { t, locale } = useI18n(); // 解构调用函数
const props = defineProps({
  fareOptions: {},
  flightInfo: {},
  fareOptionSelected: {},
  activeFlightGroupNumber: {},
  airportCityMap: {},
  priceClasses: {},
});

const emits = defineEmits();

const flightShoppingStore = useFlightShoppingStore();
const fareOptionsRef = ref(null);
const fareOptionsSelectedRef = ref([]);
const flightInfoRef = ref(null);
const fareClockRef = ref(null);
const airportCityMapRef = ref(null);

const priceClassesRef = ref(null);
const fareActiveIndexRef = ref(0);

watch(
  () => props.flightInfo,
  (newValue) => {
    flightInfoRef.value = newValue;
  },
  { immediate: true, deep: true }
);

watch(
  () => props.priceClasses,
  (newValue) => {
    priceClassesRef.value = newValue;
  },
  { immediate: true, deep: true }
);

watch(
  () => props.fareOptionSelected,
  (newValue) => {
    fareOptionsSelectedRef.value = newValue;
  },
  { immediate: true, deep: true }
);

watch(
  () => props.fareOptions,
  (newValue) => {
    if (newValue && newValue.length > 0) {
      newValue.sort((a, b) => a.totalPrice - b.totalPrice); //排序
      fareOptionsRef.value = newValue;
      fareOptionsSelectedRef.value[props.activeFlightGroupNumber] =
        fareOptionsRef.value && fareOptionsRef.value.length > 0
          ? fareOptionsRef.value[0]
          : {};

      if (
        fareOptionsSelectedRef.value[props.activeFlightGroupNumber]
          .expirationDateTime
      )
        clock(
          fareOptionsSelectedRef.value[props.activeFlightGroupNumber]
            .expirationDateTime
        );
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => props.airportCityMap,
  (newValue) => {
    airportCityMapRef.value = newValue;
  },
  { immediate: true, deep: true }
);

function goToSpecificFlightGroup() {
  const fareItemSelected =
    fareOptionsSelectedRef.value[props.activeFlightGroupNumber];
  if (fareItemSelected.outOfPolicy) return;
  let nextFlightGroupNumber = props.activeFlightGroupNumber + 1;
  emits("goToSpecificFlightGroup", nextFlightGroupNumber);
}

function clock(expirationDate) {
  let isBefore = false;
  clearInterval(fareClockRef.value);
  fareClockRef.value = setInterval(function () {
    isBefore = dateUtils.compareIfBefore(expirationDate, new Date());
    if (isBefore) {
      //如果比现在的时间早，提示fare 过期
      commonUtils.openToastBox(
        "WARNING",
        t("this-is-a-warning-message"),
        t("fare-is-expired"),
        function () {
          location.reload(); //刷新页面
        }
      );
      isBefore = false;
      clearInterval(fareClockRef.value);
    }
  }, 1000);
}

function changeFareActiveKeyIndex(fareActiveIndex) {
  fareActiveIndexRef.value = fareActiveIndex;
}

onUnmounted(() => {
  //组件销毁的时候清除定时器
  clearInterval(fareClockRef.value);
});

function closeFareOptions() {
  emits("closeFareOptions");
}
</script>

<template>
  <div class="tour_booking_form_box" style="min-height: 100vh">
    <div style="height: 100%">
      <a class="text-pointer cursor-point" @click="closeFareOptions">
        <h5>
          <i class="fa fa-fw fa-times mb-3"></i>
          {{
            $t("review--fare-to", {
              locationCode: flightInfoRef.destinationLocationCode,
            })
          }}
          <span class="mg-l-10 font-14">
            {{
              dateUtils.formatEnglishDate(flightInfoRef.departureDateTime).ddate
            }}
            {{
              dateUtils.formatEnglishDate(flightInfoRef.departureDateTime).month
            }}
          </span>
        </h5>
      </a>
      <div v-if="flightInfoRef" class="booking_tour_form">
        <div>
          <div class="orgin-des">
            <div class="location">
              {{
                flightCommonTools.getAirportName(
                  locale,
                  flightInfoRef.departureAirportCode,
                  flightShoppingStore.airportCityMap
                )
              }}({{ flightInfoRef.departureAirportCode }}) -
              {{
                flightCommonTools.getAirportName(
                  locale,
                  flightInfoRef.arrivalAirportCode,
                  flightShoppingStore.airportCityMap
                )
              }}({{ flightInfoRef.arrivalAirportCode }})
            </div>
            <div
              v-if="
                dateUtils.formatLocalTime(flightInfoRef.departureDateTime) &&
                dateUtils.formatLocalTime(flightInfoRef.arrivalDateTime)
              "
              class="text-muted"
            >
              {{
                dateUtils.formatLocalTime(flightInfoRef.departureDateTime).time
              }}
              ({{
                dateUtils.formatLocalTime(flightInfoRef.departureDateTime)
                  .timeZone
              }}) -
              {{
                dateUtils.formatLocalTime(flightInfoRef.arrivalDateTime).time
              }}
              ({{
                dateUtils.formatLocalTime(flightInfoRef.arrivalDateTime)
                  .timeZone
              }})
            </div>
            <div class="mg-t-5">
              {{ dateUtils.formatHourMinute(flightInfoRef.durationInMins) }} (
              {{ flightInfoRef.numStops }}-{{ $t("stop") }})
            </div>
          </div>
        </div>
      </div>

      <div v-if="fareOptionsRef && fareOptionsRef.length > 0">
        <h5 class="text-info">
          {{ $t("some-fare-options-for-you") }}
        </h5>
        <div v-if="fareOptionsRef && fareOptionsRef.length > 0">
          <div class="row mg-t-10">
            <div
              v-for="(fareItem, fareIndex) in fareOptionsRef"
              :key="fareIndex"
              class="col-3"
            >
              <FlightFareOptionItem
                :itinerary-fare-option-key-map="
                  flightShoppingStore.itineraryFareOptionKeyMap
                "
                :flight-info="flightInfo"
                :fare-item="fareItem"
                :fare-index="fareIndex"
                :fare-active-index="fareActiveIndexRef"
                :active-flight-group-number="activeFlightGroupNumber"
                :fare-clock="fareClockRef"
                :fare-options-selected="fareOptionSelected"
                :price-classes="priceClassesRef"
                @clock="clock"
                @changeFareActiveKeyIndex="changeFareActiveKeyIndex"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="text-center">
        <button class="btn btn_theme mb-2" @click="goToSpecificFlightGroup">
          {{ $t("continue") }}
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
