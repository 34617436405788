<script setup>
import { computed, reactive, ref, watchEffect } from "vue";
import BaseRoomPassengerFormItem from "@/views/sales/hotel/items/form/BaseRoomPassengerFormItem.vue";
import useVuelidate from "@vuelidate/core";
import hotelTools from "@/tools/hotelTools";

const props = defineProps({
  roomPassengers: {
    type: Array,
    description: "初始化房间住客映射数组",
    default: null,
  },
  cabinClass: {},
  isMenuShow: {},
});
// (props.roomPassengers);
let roomPassengerFormModel = reactive(
  new hotelTools.RoomPassengerFormModel(props.roomPassengers)
);

let cabinClassModel = ref(props.cabinClass);

// const cabinList = ["Y", "W", "F", "C"];

const cabinOptions = [
  {
    code: "Y",
    name: "Economy",
  },
  {
    code: "W",
    name: "Premium Economy",
  },
  {
    code: "C",
    name: "Business",
  },
  {
    code: "F",
    name: "First",
  },
];

const packagePassengersShow = computed(() => {
  let roomCount = roomPassengerFormModel.roomPassengers.length;
  let travelersNumer = 0;
  // (roomPassengerFormModel.roomPassengers);
  roomPassengerFormModel.roomPassengers.forEach((roomPassenger) => {
    travelersNumer +=
      parseInt(roomPassenger.adultNum) + parseInt(roomPassenger.childNum);
  });
  let cabinName = "";
  // (cabinClassModel.value);
  cabinOptions.forEach((cabinOption) => {
    if (cabinOption.code == cabinClassModel.value.code) {
      cabinName = cabinOption.name;
    }
  });

  return (
    travelersNumer +
    " traveler(s) , " +
    roomCount +
    " room(s) " +
    ", " +
    cabinName
  );
});
//定义传值函数
const emits = defineEmits(["submitRoomPassengers"]);

let isFormCorrect = ref(true);

//当用户未选择儿童岁数时，要主动校验，让输入框显示错误
watchEffect(() => {
  let formElement = document.getElementById("roomPassengerForm");
  if (props.isMenuShow && formElement) {
    //展開
    formElement.classList.add("show");
  } else if (!props.isMenuShow && formElement) {
    formElement.classList.remove("show");
  }
});

// watch(roomPassengerFormModel.roomPassengers, () => {
//   submitRoomPassengersForm();
// });
//passengerType string 住客年龄类型，8为儿童，10为成人
function addPassengerCount(roomNum, passengerType) {
  let childSubBtn = document.getElementById("sub-child-button-" + roomNum);
  let adultSubBtn = document.getElementById("sub-adult-button-" + roomNum);
  if (
    parseInt(roomPassengerFormModel.roomPassengers[roomNum].adultNum) +
      parseInt(roomPassengerFormModel.roomPassengers[roomNum].childNum) <
    4
  ) {
    if (passengerType === "8") {
      roomPassengerFormModel.roomPassengers[roomNum].childAges.push({
        age: null,
        roomId: roomNum,
      });
      roomPassengerFormModel.roomPassengers[roomNum].childNum++;
      refreshAllRoomPassengers(roomNum);
      childSubBtn.disabled = false;
    } else if (passengerType === "10") {
      roomPassengerFormModel.roomPassengers[roomNum].adultNum++;
      refreshAllRoomPassengers(roomNum);
      adultSubBtn.disabled = false;
    }
  }
}

//目前只支持相同房型的搜索与预定，故所有房间的人数都是一样的
function refreshAllRoomPassengers(roomIndex) {
  const roomNum = roomPassengerFormModel.roomPassengers.length;
  const roomPassenger = roomPassengerFormModel.roomPassengers[roomIndex];
  for (let i = 0; i < roomNum; i++) {
    if (roomIndex == i) continue;
    roomPassengerFormModel.roomPassengers[i] = {
      adultNum: roomPassenger.adultNum,
      childNum: roomPassenger.childNum,
      childAges: [],
    };

    if (roomPassenger.childAges) {
      roomPassenger.childAges.forEach((childAge) => {
        let childAgeCopy = JSON.parse(JSON.stringify(childAge));
        childAgeCopy.roomId = i;

        roomPassengerFormModel.roomPassengers[i].childAges.push(childAgeCopy);
      });
    }
  }
}

function subtractPassengerCount(roomNum, passengerType) {
  let childSubBtn = document.getElementById("sub-child-button-" + roomNum);
  let adultSubBtn = document.getElementById("sub-adult-button-" + roomNum);
  if (passengerType === "8") {
    if (roomPassengerFormModel.roomPassengers[roomNum].childNum < 1) {
      childSubBtn.disabled = true;
    } else {
      let childAges = [];
      if (
        roomPassengerFormModel.roomPassengers[roomNum] &&
        roomPassengerFormModel.roomPassengers[roomNum].childAges
      ) {
        childAges = roomPassengerFormModel.roomPassengers[roomNum].childAges;
      }
      if (childAges && childAges.length > 0) {
        childAges.splice(childAges.length - 1, 1);
      }
      roomPassengerFormModel.roomPassengers[roomNum].childNum--;
      refreshAllRoomPassengers(roomNum);
    }
  } else if (passengerType === "10") {
    if (roomPassengerFormModel.roomPassengers[roomNum].adultNum < 2) {
      adultSubBtn.disabled = true;
    } else {
      roomPassengerFormModel.roomPassengers[roomNum].adultNum--;
      refreshAllRoomPassengers(roomNum);
    }
  }
}

function addRoomPassenger() {
  roomPassengerFormModel.addNewRoomPassenger();
  refreshAllRoomPassengers(0);
}

function removeRoomPassenger(roomNum) {
  roomPassengerFormModel.removeRoomPassenger(roomNum);
}

// Validation rules

const v = useVuelidate();

let activeIndex = ref(0);
if (cabinClassModel.value.code) {
  for (let i = 0; i < cabinOptions.length; i++) {
    let cabinOption = cabinOptions[i];
    if (cabinOption.code == cabinClassModel.value.code) {
      activeIndex.value = i;
    }
  }
}

function chooseCabinClass(cabinOption, index) {
  // cabinClassModel.code = cabinOption.code;
  // cabinClassModel.name = cabinOption.name;
  cabinClassModel.value.code = cabinOption.code;
  activeIndex.value = index;
}

async function submitRoomPassengersForm() {
  isFormCorrect.value = await v.value.$validate();
  if (!isFormCorrect.value) return;
  let formElement = document.getElementById("pkgPassengerForm");
  formElement.classList.remove("show");
}

//endregion
</script>

<template>
  <form action="!#">
    <div class="search_boxed dropdown_passenger_area">
      <p>{{ $t("passenger-room-class") }}</p>
      <div class="dropdown">
        <button
          class="dropdown-toggle final-count"
          data-toggle="dropdown"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          data-bs-auto-close="outside"
        >
          {{ packagePassengersShow.split(",")[0] }}
        </button>

        <div
          class="dropdown-menu dropdown_passenger_info fs-sm dropdown-menu-400"
          aria-labelledby="dropdownMenuButton1"
          id="pkgPassengerForm"
          :class="{
            show: isMenuShow,
          }"
        >
          <div class="traveller-calulate-persons">
            <div class="passengers">
              <h6>{{ $t("passengers") }}</h6>
              <form class="p-2" @submit.prevent>
                <div
                  v-for="(
                    roomPassenger, roomPassengerIndex
                  ) in roomPassengerFormModel.roomPassengers"
                  :key="roomPassengerIndex"
                >
                  <BaseRoomPassengerFormItem
                    :room-passenger-index="roomPassengerIndex"
                    :room-passenger="roomPassenger"
                    @removeRoomPassenger="removeRoomPassenger"
                    @addCount="addPassengerCount"
                    @subtractCount="subtractPassengerCount"
                    @refreshAllRoomPassengers="
                      refreshAllRoomPassengers(roomPassengerIndex)
                    "
                  />
                </div>
                <a
                  class="d-flex justify-content-sm-end mt-sm-4"
                  @click="addRoomPassenger"
                  >{{ $t("add-another-room") }}</a
                >
                <div class="cabin-selection">
                  <h6>{{ $t("cabin-class") }}</h6>
                  <div
                    v-for="(cabinOption, index) in cabinOptions"
                    class="cabin-list"
                  >
                    <button
                      :class="{ active: index === activeIndex }"
                      class="label-select-btn"
                      type="radio"
                      name="cabinClass"
                      @click="chooseCabinClass(cabinOption, index)"
                    >
                      <span class="muiButton-label">{{
                        cabinOption.name
                      }}</span>
                    </button>
                  </div>
                </div>
                <div class="text-center">
                  <button
                    type="submit"
                    class="btn col-11"
                    @click="submitRoomPassengersForm"
                  >
                    {{ $t("done") }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <span>
        {{ packagePassengersShow.split(",")[1]
        }}{{ packagePassengersShow.split(",")[2] }}</span
      >
    </div>
  </form>
</template>

<style scoped></style>
