import axios from "@/utils/axiosUtils.js";

function getDocument(partyId, partyDocumentId) {
  let url = "/api/v3/parties/" + partyId + "/documents/" + partyDocumentId;
  return axios.get(url);
}

function addDocument(documentRQ, partyId) {
  let url = "/api/v3/parties/" + partyId + "/documents";
  return axios.post(url, documentRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function modifyDocument(documentRQ, partyId, partyDocumentId) {
  let url = "/api/v3/parties/" + partyId + "/documents/" + partyDocumentId;
  return axios.put(url, documentRQ, {
    headers: {
      "Content-Type": "application/json",
    },
  });
}

function deleteDocument(partyId, partyDocumentId) {
  let url = "/api/v3/parties/" + partyId + "/documents/" + partyDocumentId;
  return axios.delete(url);
}

export default {
  getDocument,
  addDocument,
  modifyDocument,
  deleteDocument,
};
