export class TripPassengerCustLoyaltyVO {
  /**
   *
   * @param travelSector
   * @param vendorCode
   * @param programID
   * @param membershipID
   */
  constructor(travelSector, vendorCode, programID, membershipID) {
    this.travelSector = travelSector;
    this.vendorCode = vendorCode;
    this.programID = programID;
    this.membershipID = membershipID;
  }
}
