<script setup>
import { useI18n } from "vue-i18n";
import { email, required } from "@vuelidate/validators";
import { reactive, ref, watch } from "vue";
import { VueTelInput } from "vue3-tel-input";
import "vue3-tel-input/dist/vue3-tel-input.css";
import useVuelidate from "@vuelidate/core";
import * as regexUtils from "@/utils/regexUtils";

const { t, locale } = useI18n(); // 解构调用函数

const props = defineProps({
  tripContactInfo: {},
});

const tripContactInfoRef = ref(null);
watch(
  () => props.tripContactInfo,
  (newValue) => {
    tripContactInfoRef.value = newValue;
  },
  { deep: true, immediate: true }
);

const phoneRule = (value) =>
  regexUtils.numberRegx.test(value) && regexUtils.phoneNumberRegx.test(value);
const rules = reactive({
  phoneNumber: { required, phoneRule },
  emailAddress: { required, email },
});

const v = useVuelidate(rules, tripContactInfoRef);

function countryChange(value) {
  tripContactInfoRef.value.countryAccessCode = value.dialCode;
}

//region 电话号码
function phoneNumberInput(value, event) {
  let isPhoneNumber =
    regexUtils.phoneNumberRegx.test(value) && regexUtils.numberRegx.test(value);
  if (
    isPhoneNumber &&
    event != null &&
    event.nationalNumber != null &&
    event.nationalNumber != ""
  ) {
    tripContactInfoRef.value.phoneNumber = event.nationalNumber;
  } else if (
    isPhoneNumber &&
    event != null &&
    event.nationalNumber == null &&
    event.formatted != null &&
    event.formatted !== ""
  ) {
    tripContactInfoRef.value.phoneNumber = event.formatted;
  } else if (typeof value === "string") {
    tripContactInfoRef.value.phoneNumber = value;
  }
}
</script>

<template>
  <div class="tour_booking_form_box">
    <h4>{{ $t("contact-info") }}</h4>
    <div class="mg-b-10">
      <form @sumbit.prevent>
        <div class="row">
          <div class="col-6">
            <div>
              <label class="form-label" for="example-text-input"
                >{{ $t("phone-number") }}
                <small class="text-lowercase"><code>*</code></small></label
              >
              <VueTelInput
                id="phoneEle"
                :autoFormat="false"
                :inputOptions="{
                  placeholder: $t('please-enter'),
                  autoFormat: false,
                }"
                :value="v.phoneNumber.$model"
                :class="{
                  'is-invalid': v.phoneNumber.$errors.length > 0,
                }"
                default-country="HK"
                class="form-control flex"
                @blur="v.phoneNumber.$touch"
                @input="phoneNumberInput"
                @country-changed="countryChange"
                autocomplete="off"
                mode="international"
              />
            </div>
          </div>
          <div class="col-6">
            <label class="form-label" for="example-text-input"
              >{{ $t("email-address") }}
              <small class="text-lowercase"><code>*</code></small></label
            >
            <input
              id="passenger-form-email"
              :placeholder="$t('please-enter')"
              v-model="v.emailAddress.$model"
              :class="{
                'is-invalid': v.emailAddress.$errors.length > 0,
              }"
              @blur="v.emailAddress.$touch"
              autocomplete="off"
              class="form-control"
              name="passenger-form-email"
              type="email"
            />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped></style>
