import { FlightReshopAddItemVO } from "@/viewobject/sales/flight/reshop/flightReshopAddItemVO";
import { FlightReshopFareItemVO } from "@/viewobject/sales/flight/reshop/flightReshopFareItemVO";
import { FlightReshopAmountVO } from "@/viewobject/sales/flight/reshop/flightReshopAmountVO";
import { FlightOriginDestinationFormItem } from "@/formmodel/sales/flight/shopping/flightShoppingFormModel";
import { FlightSegmentVO } from "@/viewobject/sales/flight/shopping/flightSegmentVO";
import dateUtils from "@/utils/dateUtils";
import { FlightItemVO } from "@/viewobject/sales/flight/shopping/flightItemVO";
import { FlightFareOption } from "@/viewobject/sales/flight/shopping/flightFareOption";
import { FlightPriceClassVO } from "@/viewobject/sales/flight/shopping/flightPriceClassVO";

function buildReshopRQ(flightReshopFormModel) {
  const reshopType = flightReshopFormModel.reshopType;
  const recordLocator = flightReshopFormModel.recordLocator;
  const idType = flightReshopFormModel.idType;
  const idContext = flightReshopFormModel.idContext;
  const adds = flightReshopFormModel.adds;
  const deletes = flightReshopFormModel.deletes;

  let deleteOptions = null;
  if (deletes && deletes.length > 0) {
    let itemList = [];
    for (const deleteEle of deletes) {
      const flightSegmentRPHs = deleteEle.flightSegmentRPHs;
      const flightReferenceList = [];
      for (const flightSegmentRPH of flightSegmentRPHs) {
        flightReferenceList.push({
          flightRefNumber: flightSegmentRPH,
        });
      }

      itemList.push({
        rph: deleteEle.rph,
        flightReferenceList: flightReferenceList,
      });
    }

    deleteOptions = {
      itemList: itemList,
    };
  }

  let originDestinationInformationList = null;
  let travelPreferencesList = null;
  if (adds && adds.length > 0) {
    let refNumIndex = 1;
    originDestinationInformationList = [];
    const origDestPrefs = [];
    for (const originDestination of adds) {
      const cabinTypePrefs = originDestination.cabinTypePrefs;
      const originDestinationInformation = buildOriginDestinationInformation(
        originDestination,
        refNumIndex
      );
      originDestinationInformationList.push(originDestinationInformation);

      //暂时只支持参考第一行程搜索条件
      if (origDestPrefs.length === 0) {
        origDestPrefs.push({
          refNumber: originDestinationInformation.refNumber,
          cabinTypePrefs,
        });
      }

      refNumIndex++;
    }

    //偏好
    travelPreferencesList = [];
    for (const origDestPref of origDestPrefs) {
      let refNumber = origDestPref.refNumber;
      let cabinTypePrefs = origDestPref.cabinTypePrefs;

      const originDestinationRPHs = [];
      const cabinPrefList = [];

      //舱型
      if (cabinTypePrefs && cabinTypePrefs.length > 0) {
        for (const cabinTypePref of cabinTypePrefs) {
          cabinPrefList.push({
            preferLevel: "Preferred", //仅支持Preferred
            cabin: cabinTypePref.code,
          });
        }
      } else {
        cabinPrefList.push({
          preferLevel: "Preferred", //仅支持Preferred
          cabin: "YY", //for all
        });
      }

      const travelPreferences = {
        originDestinationRPHs: originDestinationRPHs, //Optional, origionDestination refNum
        cabinPrefList: cabinPrefList,
      };

      travelPreferencesList.push(travelPreferences);
    }
  }

  return {
    modificationType: reshopType,
    bookingReference: {
      id: recordLocator,
      type: idType,
      id_context: idContext,
    },
    reshop: {
      servicing: {
        add: {
          flightQuery: {
            originDestinationInformationList: originDestinationInformationList,
          },
          travelPreferencesList: travelPreferencesList,
        },
        delete: deleteOptions,
      },
    },
  };
}

function buildOriginDestinationInformation(originDestination, refNumIndex) {
  const refNumber =
    originDestination.refNumber != null
      ? originDestination.refNumber
      : "OD" + refNumIndex;
  const originLocationCode = originDestination.originLocationCode;
  const originLocationName = originDestination.originLocationName;
  const destinationLocationCode = originDestination.destinationLocationCode;
  const destinationLocationName = originDestination.destinationLocationName;
  const departureDate = originDestination.departureDate;
  const departureTime =
    originDestination.departureTime != null
      ? originDestination.departureTime
      : "00:00";
  const departureDateTime = departureDate + "T" + departureTime;
  const connections = originDestination.connections;
  const stops = originDestination.stops;
  const departureTimeWindowBefore = originDestination.departureTimeWindowBefore;
  const departureTimeWindowAfter = originDestination.departureTimeWindowAfter;
  const daysBeforeDeparture = originDestination.daysBeforeDeparture;
  const daysAfterDeparture = originDestination.daysAfterDeparture;
  const arrivalDate = originDestination.arrivalDate;
  const arrivalTime =
    originDestination.arrivalTime != null
      ? originDestination.arrivalTime
      : "00:00";
  const arrivalDateTime =
    arrivalDate != null && arrivalTime != null
      ? arrivalDate + "T" + arrivalTime
      : null;

  return {
    departureDateTime: departureDateTime,
    departureDateTimeWindowBefore: departureTimeWindowBefore,
    departureDateTimeWindowAfter: departureTimeWindowAfter,
    daysBeforeNumber: daysBeforeDeparture,
    daysAfterNumber: daysAfterDeparture,
    arrivalDateTime: arrivalDateTime,
    originLocation: {
      locationCode: originLocationCode,
    },
    destinationLocation: {
      locationCode: destinationLocationCode,
    },
    refNumber: refNumber,
  };
}

function buildFlightReshopItem(addItem) {
  const reshopDifferential = addItem.reshopDifferential;
  const originalItem = reshopDifferential.originalItem;
  const newItem = reshopDifferential.newItem;
  const penaltyAmount = reshopDifferential.penaltyAmount;
  const feesAmount = reshopDifferential.feesAmount;

  let originalItemVO = null;
  let newItemVO = null;
  let penaltyAmountVO = null;
  let feesAmountVO = null;

  if (originalItem) {
    const totalFare = originalItem.totalFare;
    const currencyCode = totalFare.currencyCode;
    const totalAmount = totalFare.amount;

    const taxes = originalItem.taxes;
    const taxesTotalAmount = taxes.amount;

    originalItemVO = new FlightReshopFareItemVO(
      currencyCode,
      taxesTotalAmount,
      totalAmount,
      null,
      null
    );
  }

  if (newItem) {
    const totalFare = newItem.totalFare;
    const currencyCode = totalFare.currencyCode;
    const totalAmount = totalFare.amount;

    const taxes = newItem.taxes;
    let taxesTotalAmount = null;
    if (taxes) {
      taxes.amount;
    }

    newItemVO = new FlightReshopFareItemVO(
      currencyCode,
      taxesTotalAmount,
      totalAmount,
      null,
      null
    );

    const flightRefNumberRPHlist = newItem.flightRefNumberRPHList;
    const travelerRefNumberList = newItem.travelerRefNumberList;

    if (flightRefNumberRPHlist && flightRefNumberRPHlist.length > 0) {
      for (const flightRefNumberRPH of flightRefNumberRPHlist) {
        newItemVO.addFlightRefNumber(flightRefNumberRPH);
      }
    }

    if (travelerRefNumberList && travelerRefNumberList.length > 0) {
      for (const travelerRefNumber of travelerRefNumberList) {
        newItemVO.addTravelerRefNumber(travelerRefNumber.rph);
      }
    }
  }

  if (penaltyAmount) {
    const totalFare = penaltyAmount.totalFare;
    const currencyCode = totalFare.currencyCode;
    const totalAmount = totalFare.amount;
    const operation = totalFare.operation;

    penaltyAmountVO = new FlightReshopAmountVO(
      currencyCode,
      totalAmount,
      operation
    );
  }

  if (feesAmount) {
    const totalFare = feesAmount.totalFare;
    const currencyCode = totalFare.currencyCode;
    const totalAmount = totalFare.amount;
    const operation = totalFare.operation;

    feesAmountVO = new FlightReshopAmountVO(
      currencyCode,
      totalAmount,
      operation
    );
  }
  const flightReshopAddItemVO = new FlightReshopAddItemVO(
    originalItemVO,
    newItemVO,
    penaltyAmountVO,
    feesAmountVO
  );

  return flightReshopAddItemVO;
}

function buildItineraryArrangement(reshopPricedItinerary) {
  const originDestinations =
    reshopPricedItinerary.airItinerary.originDestinationOptions;

  const itineraryArrangement = {
    flights: [],
  };

  for (const index in originDestinations) {
    const refNumber = "OD" + index;
    const originDestination = originDestinations[index];
    const originLocationCode = originDestination.originLocation;
    const destinationLocationCode = originDestination.destinationLocation;
    const departureDate =
      originDestination.flightSegments[0].departureDateTime.split("T")[0];

    const flightOriginDestinationFormItem = new FlightOriginDestinationFormItem(
      refNumber,
      originLocationCode,
      null,
      destinationLocationCode,
      null,
      null,
      null,
      null,
      departureDate
    );
    itineraryArrangement.flights.push(flightOriginDestinationFormItem);
  }

  return itineraryArrangement;
}

function buildFlightItems(pricedItineraryList) {
  const flightItemMap = {};
  for (const pricedItinerary of pricedItineraryList) {
    const airItineraryKey = pricedItinerary.rph;
    const airItinerary = pricedItinerary.airItinerary;

    const originDestinationOptions = airItinerary.originDestinationOptions;
    const airItineraryPricingInfo = pricedItinerary.airItineraryPricingInfo;
    const itinTotalFare = airItineraryPricingInfo.itinTotalFares[0];
    const totalFare = itinTotalFare.totalFare;
    const totalFareCurrencyCode = totalFare.currencyCode;
    const totalFareAmount = totalFare.amount;

    const bookingSourceType = pricedItinerary.bookingSourceType;
    const supplierCode =
      airItineraryPricingInfo.priceRequestInformation &&
      airItineraryPricingInfo.priceRequestInformation.negotiatedFareCodeList
        ? airItineraryPricingInfo.priceRequestInformation
            .negotiatedFareCodeList[0].supplierCode
        : airItineraryPricingInfo.pricingSource;

    for (let i = 0; i < originDestinationOptions.length; i++) {
      const originDestinationOption = originDestinationOptions[i];
      const flightSegments = originDestinationOption.flightSegments;
      const originDestinationKey = originDestinationOption.originDestinationKey;

      const tripSequenceNumber = i;
      const originLocationCode = originDestinationOption.originLocation;
      const destinationLocationCode =
        originDestinationOption.destinationLocation;
      const connections = originDestinationOption.connections;
      let numStops = originDestinationOption.numStops;

      let durationInMins = originDestinationOption.durationInMins;
      let originLocationName = null;
      let departureAirportCodeFlight = null;
      let destinationLocationName = null;
      let arrivalAirportCodeFlight = null;
      let departureDateTimeFlight = null;
      let arrivalDateTimeFlight = null;

      const flightSegmentVOs = [];
      for (const flightSegment of flightSegments) {
        const departureDateTime = flightSegment.departureDateTime;
        const arrivalDateTime = flightSegment.arrivalDateTime;
        const segmentKey = flightSegment.segmentKey;
        const flightNumber = flightSegment.flightNumber;
        const fareBasisCode = flightSegment.fareBasisCode;
        const fareTypeName = flightSegment.fareTypeName;
        const resBookDesigCode = flightSegment.resBookDesigCode;
        const numberInParty = flightSegment.numberInParty;
        const stopoverInd = flightSegment.stopoverInd;
        const departureAirportCode = flightSegment.departureAirportCode;
        const arrivalAirportCode = flightSegment.arrivalAirportCode;
        const operatingAirlineCode = flightSegment.operatingAirlineCode;
        const operatingAirlineName = flightSegment.operatingAirlineName;
        const equipmentCode = flightSegment.equipmentCode;
        const marketingAirlineCode = flightSegment.marketingAirlineCode;
        const cabinClass = flightSegment.cabinClass;
        // const baggageAllowance = flightSegment.baggageAllowance;
        const baggageAllowance = null;
        const rph = flightSegment.rph;

        const flightSegmentVO = new FlightSegmentVO(
          departureDateTime,
          arrivalDateTime,
          segmentKey,
          flightNumber,
          fareBasisCode,
          fareTypeName,
          resBookDesigCode,
          numberInParty,
          stopoverInd,
          departureAirportCode,
          arrivalAirportCode,
          operatingAirlineCode,
          operatingAirlineName,
          equipmentCode,
          marketingAirlineCode,
          cabinClass,
          baggageAllowance,
          rph
        );

        flightSegmentVOs.push(flightSegmentVO);

        if (!departureDateTimeFlight) {
          departureDateTimeFlight = departureDateTime;
          departureAirportCodeFlight = departureAirportCode;
        }
        arrivalDateTimeFlight = arrivalDateTime;
        arrivalAirportCodeFlight = arrivalAirportCode;
      }

      if (!durationInMins) {
        durationInMins = dateUtils.calcDurationInMinsWithTimeZone(
          departureDateTimeFlight,
          arrivalDateTimeFlight
        );
      }

      if (flightItemMap[originDestinationKey]) {
        const flightItem = flightItemMap[originDestinationKey];
        if (flightItem.bestTotalPrice > totalFareAmount)
          flightItem.bestTotalPrice = totalFareAmount;

        if (flightItem.bookingSourceTypes.indexOf(bookingSourceType) < 0)
          flightItem.bookingSourceTypes.push(bookingSourceType);

        if (flightItem.supplierCodes.indexOf(supplierCode) < 0)
          flightItem.supplierCodes.push(supplierCode);

        continue;
      }

      if (!numStops) {
        numStops = flightSegmentVOs.length - 1;
      }

      const flightItemVO = new FlightItemVO(
        null,
        tripSequenceNumber,
        originLocationCode,
        originLocationName,
        departureAirportCodeFlight,
        destinationLocationCode,
        destinationLocationName,
        arrivalAirportCodeFlight,
        departureDateTimeFlight,
        arrivalDateTimeFlight,
        null,
        originDestinationKey,
        durationInMins,
        connections,
        numStops,
        totalFareCurrencyCode,
        totalFareAmount,
        null,
        null,
        null,
        flightSegmentVOs
      );
      flightItemVO.addBookingSourceType(bookingSourceType);
      flightItemVO.addSupplierCode(supplierCode);

      flightItemMap[originDestinationKey] = flightItemVO;
    }
  }
  return Object.values(flightItemMap);
}

function buildFlightKeyGroups(pricedItineraryList) {
  const flightKeyGroups = [];
  for (const pricedItinerary of pricedItineraryList) {
    const airItineraryKey = pricedItinerary.airItinerary.airItineraryKey;
    flightKeyGroups.push(airItineraryKey);
  }
  return flightKeyGroups;
}

function buildItineraryFareOptionKeyMap(pricedItineraryList) {
  const itineraryFareOptionKeyMap = {};
  for (const pricedItinerary of pricedItineraryList) {
    const airItineraryRPH = pricedItinerary.airItinerary.airItineraryRPH;
    const airItineraryKey = pricedItinerary.airItinerary.airItineraryKey;
    const bookingSource = pricedItinerary.bookingSource;
    const offer = pricedItinerary.offer;
    const airItineraryPricingInfo = pricedItinerary.airItineraryPricingInfo;
    const itinTotalFare = airItineraryPricingInfo.itinTotalFares[0];
    const totalFare = itinTotalFare.totalFare;
    const totalFareCurrencyCode = totalFare.currencyCode;
    const totalFareAmount = totalFare.amount;

    const supplierCode =
      airItineraryPricingInfo.priceRequestInformation &&
      airItineraryPricingInfo.priceRequestInformation.negotiatedFareCodeList
        ? airItineraryPricingInfo.priceRequestInformation
            .negotiatedFareCodeList[0].supplierCode
        : airItineraryPricingInfo.pricingSource;

    let key =
      supplierCode +
      ":" +
      airItineraryKey +
      ":!" +
      totalFareCurrencyCode +
      totalFareAmount;

    if (offer && offer.summary && offer.summary.length > 0) {
      const summary = offer.summary;
      key += "!!";
      let first = true;
      for (const summaryEle of summary) {
        const bundleID = summaryEle.bundleID;
        if (first) {
          key += bundleID;
          first = false;
        } else {
          key += ":" + bundleID;
        }
      }
    }
    itineraryFareOptionKeyMap[key] = airItineraryRPH;
  }

  return itineraryFareOptionKeyMap;
}

function buildFlightFareOptionsMap(pricedItineraryList) {
  const flightFareOptionsMap = {};
  const flightFareOptionKeyMap = {};
  for (const pricedItinerary of pricedItineraryList) {
    const airItinerary = pricedItinerary.airItinerary;
    const airItineraryKey = airItinerary.airItineraryKey;
    const offer = pricedItinerary.offer;

    const airItineraryPricingInfo = pricedItinerary.airItineraryPricingInfo;
    const ptcFareBreakdowns = airItineraryPricingInfo.PTC_FareBreakdowns;
    const itinTotalFare = airItineraryPricingInfo.itinTotalFares[0];
    const totalFare = itinTotalFare.totalFare;
    const totalFareCurrencyCode = totalFare.currencyCode;
    const totalFareAmount = totalFare.amount;

    const bookingSourceType = pricedItinerary.bookingSourceType;
    const supplierCode =
      airItineraryPricingInfo.priceRequestInformation &&
      airItineraryPricingInfo.priceRequestInformation.negotiatedFareCodeList
        ? airItineraryPricingInfo.priceRequestInformation
            .negotiatedFareCodeList[0].supplierCode
        : airItineraryPricingInfo.pricingSource;
    let priceExpirationDatetime = null;
    if (
      airItineraryPricingInfo.tpaExtensions &&
      airItineraryPricingInfo.tpaExtensions.length > 0 &&
      airItineraryPricingInfo.tpaExtensions[0] &&
      airItineraryPricingInfo.tpaExtensions[0].valueMap
    ) {
      const valueMap = airItineraryPricingInfo.tpaExtensions[0].valueMap;
      priceExpirationDatetime = valueMap.priceExpirationDatetime;
    }

    const origDestKeyMap = {};
    const segmentRPHKeyMap = {};
    const originDestinationOptions = airItinerary.originDestinationOptions;
    for (const originDestinationOption of originDestinationOptions) {
      const rph = originDestinationOption.rph;
      const originDestinationKey = originDestinationOption.originDestinationKey;
      origDestKeyMap[rph] = originDestinationKey;

      for (const flightSegment of originDestinationOption.flightSegments) {
        const segmentKey = flightSegment.segmentKey;
        const flightSegmentRPH = flightSegment.rph;
        segmentRPHKeyMap[flightSegmentRPH] = segmentKey;
      }
    }

    if (!offer || !offer.summary) {
      const originDestinationOptions = airItinerary.originDestinationOptions;
      for (const originDestinationOption of originDestinationOptions) {
        const originDestinationKey =
          origDestKeyMap[originDestinationOption.rph];
        const airItineraryKeySplit =
          airItineraryKey.split(originDestinationKey)[0];
        const value =
          airItineraryKeySplit[0] == null || airItineraryKeySplit[0] === ""
            ? null
            : airItineraryKey.split("#" + originDestinationKey)[0];
        const preItineraryKey = value && value !== "" ? value : null;
        const flightFareOption = new FlightFareOption(
          supplierCode,
          bookingSourceType,
          null,
          null,
          preItineraryKey,
          originDestinationKey,
          totalFareCurrencyCode,
          totalFareAmount,
          null,
          null,
          null,
          null,
          null,
          null,
          priceExpirationDatetime
        );

        for (const ptcFareBreakdown of ptcFareBreakdowns) {
          const fareBasisCodes = ptcFareBreakdown.fareBasisCodes;
          const fareInfoList = ptcFareBreakdown.fareInfoList;
          if (fareBasisCodes) {
            for (const fareBasisCode of fareBasisCodes) {
              const flightSegmentRPH = fareBasisCode.flightSegmentRPH;
              const segmentKey = segmentRPHKeyMap[flightSegmentRPH];
              if (originDestinationKey.indexOf(segmentKey) < 0) continue;

              const fareBasisCodeStringValue =
                fareBasisCode.fareBasisCodeStringValue;
              flightFareOption.addFareBasisCode(fareBasisCodeStringValue);
            }
          }

          if (fareInfoList) {
            for (const fareInfoDetails of fareInfoList) {
              const negotiatedFareCode = fareInfoDetails.negotiatedFareCode;
              flightFareOption.addNegotiatedFareCode(negotiatedFareCode);

              const fareReferences = fareInfoDetails.fareReferences;
              if (!fareReferences) continue;
              for (const fareReference of fareReferences) {
                const flightSegmentRPH = fareReference.flightSegmentRPH;
                const segmentKey = segmentRPHKeyMap[flightSegmentRPH];
                if (originDestinationKey.indexOf(segmentKey) < 0) continue;

                const cabinType = fareReference.cabinType;
                const resBookDesigCode = fareReference.resBookDesigCode;

                if (resBookDesigCode)
                  flightFareOption.addBookingClassCode(resBookDesigCode);
                if (cabinType) flightFareOption.addCabinType(cabinType);
              }
            }
          }
        }

        const itineraryKeyCombine =
          flightFareOption.supplierCode +
          ":" +
          flightFareOption.preItineraryKey +
          "#" +
          flightFareOption.originDestinationKey +
          ":" +
          null +
          ":" +
          totalFareAmount;

        if (flightFareOptionKeyMap[itineraryKeyCombine]) {
          continue;
        } else {
          flightFareOptionKeyMap[itineraryKeyCombine] = 1;
        }

        if (flightFareOptionsMap[originDestinationKey]) {
          flightFareOptionsMap[originDestinationKey].push(flightFareOption);
        } else {
          flightFareOptionsMap[originDestinationKey] = [];
          flightFareOptionsMap[originDestinationKey].push(flightFareOption);
        }

        // flightFareOptionMap[key] = flightFareOption;
      }
    } else {
      const summary = offer.summary;
      const preClassIdArr = [];
      for (const summaryEle of summary) {
        const bundleID = summaryEle.bundleID;
        const originDestinationKey = origDestKeyMap[summaryEle.origDestRPH];
        const airItineraryKeySplit =
          airItineraryKey.split(originDestinationKey)[0];
        const value =
          airItineraryKeySplit[0] == null || airItineraryKeySplit[0] === ""
            ? null
            : airItineraryKey.split("#" + originDestinationKey)[0];
        const preItineraryKey = value && value !== "" ? value : null;
        const prePriceClassIdsKey =
          preClassIdArr.length === 0 ? null : preClassIdArr.join(":");
        const flightFareOption = new FlightFareOption(
          supplierCode,
          bookingSourceType,
          bundleID,
          prePriceClassIdsKey,
          preItineraryKey,
          originDestinationKey,
          totalFareCurrencyCode,
          totalFareAmount,
          null,
          null,
          null,
          null,
          null,
          null,
          priceExpirationDatetime
        );

        preClassIdArr.push(bundleID);

        for (const ptcFareBreakdown of ptcFareBreakdowns) {
          const fareBasisCodes = ptcFareBreakdown.fareBasisCodes;
          const fareInfoList = ptcFareBreakdown.fareInfoList;
          if (fareBasisCodes) {
            for (const fareBasisCode of fareBasisCodes) {
              const flightSegmentRPH = fareBasisCode.flightSegmentRPH;
              const segmentKey = segmentRPHKeyMap[flightSegmentRPH];
              if (originDestinationKey.indexOf(segmentKey) < 0) continue;

              const fareBasisCodeStringValue =
                fareBasisCode.fareBasisCodeStringValue;
              flightFareOption.addFareBasisCode(fareBasisCodeStringValue);
            }
          }

          if (fareInfoList) {
            for (const fareInfoDetails of fareInfoList) {
              const negotiatedFareCode = fareInfoDetails.negotiatedFareCode;
              flightFareOption.addNegotiatedFareCode(negotiatedFareCode);

              const fareReferences = fareInfoDetails.fareReferences;
              if (!fareReferences) continue;
              for (const fareReference of fareReferences) {
                const flightSegmentRPH = fareReference.flightSegmentRPH;
                const segmentKey = segmentRPHKeyMap[flightSegmentRPH];
                if (originDestinationKey.indexOf(segmentKey) < 0) continue;

                const cabinType = fareReference.cabinType;
                const resBookDesigCode = fareReference.resBookDesigCode;

                if (resBookDesigCode)
                  flightFareOption.addBookingClassCode(resBookDesigCode);
                if (cabinType) flightFareOption.addCabinType(cabinType);
              }
            }
          }
        }

        const itineraryKeyCombine =
          flightFareOption.supplierCode +
          ":" +
          flightFareOption.preItineraryKey +
          "#" +
          flightFareOption.originDestinationKey +
          ":" +
          bundleID +
          ":" +
          totalFareAmount;

        if (flightFareOptionKeyMap[itineraryKeyCombine]) {
          continue;
        } else {
          flightFareOptionKeyMap[itineraryKeyCombine] = 1;
        }

        if (flightFareOptionsMap[originDestinationKey]) {
          flightFareOptionsMap[originDestinationKey].push(flightFareOption);
        } else {
          flightFareOptionsMap[originDestinationKey] = [];
          flightFareOptionsMap[originDestinationKey].push(flightFareOption);
        }
      }
    }
  }
  return flightFareOptionsMap;
}

function buildPriceClasses(pricedItineraryList) {
  if (!pricedItineraryList || !pricedItineraryList.length === 0) return null;

  const priceClasses = [];
  for (const pricedItinerary of pricedItineraryList) {
    const offer = pricedItinerary.offer;

    if (!offer || !offer.summary || offer.summary.length === 0) continue;

    const summary = offer.summary;

    for (const summaryItem of summary) {
      const name = summaryItem.name;
      const bundleID = summaryItem.bundleID;

      const subSections =
        summaryItem.shortDescription && summaryItem.shortDescription.subSections
          ? summaryItem.shortDescription.subSections
          : null;

      const flightPriceClassVO = new FlightPriceClassVO(bundleID, name, null);

      if (subSections) {
        for (const subSection of subSections) {
          const subCode = subSection.subCode;
          const text = subSection.paragraphs[0].text;
          flightPriceClassVO.addNewPriceDescription(subCode, text);
        }
      }

      priceClasses.push(flightPriceClassVO);
    }
  }

  return priceClasses;
}

export default {
  buildReshopRQ,
  buildFlightReshopItem,
  buildItineraryArrangement,
  buildFlightItems,
  buildFlightKeyGroups,
  buildItineraryFareOptionKeyMap,
  buildFlightFareOptionsMap,
  buildPriceClasses,
};
