const preferLevelOptions = [
  {
    label: "only",
    code: "only",
  },
  {
    label: "preferred",
    code: "preferred",
  },
  {
    label: "unacceptable",
    code: "unacceptable",
  },
];

const seatPreferenceOptions = [
  {
    label: "Aisle",
    code: "3",
  },
  {
    label: "Window",
    code: "5",
  },
  // {
  //   label: "Nonsmoking aisle",
  //   code: "8",
  // },
  //
  // {
  //   label: "Nonsmoking aisle",
  //   code: "9",
  // },
  // {
  //   label: "Nonsmoking bulkhead",
  //   code: "10",
  // },
  // {
  //   label: "Nonsmoking any",
  //   code: "11",
  // },
  // {
  //   label: "Smoking aisle",
  //   code: "12",
  // },
  // {
  //   label: "Smoking window",
  //   code: "13",
  // },
  // {
  //   label: "Smoking bulkhead",
  //   code: "14",
  // },
  // {
  //   label: "Smoking any",
  //   code: "15",
  // },
];

const mealPreferenceOptions = [
  {
    label: "Designates a regular meal.",
    code: "RGML",
  },
  {
    label: "VLML - Vegetarian/Milk/Eggs",
    code: "VLML",
  },
  {
    label: "VGML - Vegetarian/Non Dairy",
    code: "VGML",
  },

  {
    label: "SPML - Special/Specify",
    code: "SPML",
  },
  {
    label: "SFML - Seafood",
    code: "SFML",
  },
  {
    label: "RVML - Raw Vegetarian",
    code: "RVML",
  },
  {
    label: "PRML - Low Purin",
    code: "PRML",
  },
  {
    label: "ORML - Oriental",
    code: "ORML",
  },
  {
    label: "NLML - Non-Lactose",
    code: "NLML",
  },
  {
    label: "MOML - Moslem",
    code: "MOML",
  },
  {
    label: "LSML - Low Sodium/No Salt",
    code: "LSML",
  },
  {
    label: "LPML - Low Protein",
    code: "LPML",
  },
  {
    label: "LFML - Low Cholesterol",
    code: "LFML",
  },
  {
    label: "LCML - Low Calorie",
    code: "LCML",
  },
  {
    label: "KSML - Kosher",
    code: "KSML",
  },
  {
    label: "HNML - Hindu Meal",
    code: "HNML",
  },
  {
    label: "HFML - High Fiber",
    code: "HFML",
  },
  {
    label: "GFML - Gluten Free",
    code: "GFML",
  },
  {
    label: "FPML - Fruit Meal",
    code: "FPML",
  },
  {
    label: "DBML - Diabetic",
    code: "DBML",
  },

  {
    label: "CHML - Child Meal",
    code: "CHML",
  },
  {
    label: "BLML - Bland Meal",
    code: "BLML",
  },
  {
    label: "BBML - Baby/Infant Food",
    code: "BBML",
  },
  {
    label: "AVML - Asian Veg",
    code: "AVML",
  },
];

const documentTypeOptions = [
  { code: "Passport", value: "passport" },
  {
    code: "VISA",
    value: "visa",
  },
  { code: "Passport", value: "passport" },
  { code: "Passport", value: "passport" },
];

const cabinOptions = [
  { code: "Y,Economy,ECONOMY,economy", name: "Economy(Y)" },
  {
    code: "W,Premium Economy,PremiumEconomy,premium economy,PREMIUM ECONOMY",
    name: "Premium Economy(W)",
  },
  { code: "C,Business,business,BUSINESS", name: "Business(C)" },
  { code: "F,First,FIRST,first", name: "First(F)" },
];

export default {
  preferLevelOptions,
  seatPreferenceOptions,
  mealPreferenceOptions,
  cabinOptions,
};
