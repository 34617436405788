<script setup>
import { ref, watch } from "vue";
import { useTemplateStore } from "@/stores/template";
import { useRoute, useRouter } from "vue-router";
import { useCmsContentStore } from "@/stores/cms";
import FlightShoppingForm from "@/views/v2/sales/flight/items/form/FlightShoppingForm.vue";
import FlightItemAndFareOptionSelectPanel from "@/views/v2/sales/flight/items/panel/FlightItemAndFareOptionSelectPanel.vue";

const templateStore = useTemplateStore();
const route = useRoute();
const router = useRouter();

//region cms内容管理
const cmsContentStore = useCmsContentStore();
cmsContentStore.getFlightHeaderContent();

//机票背景图片
const backgroundImageUrlRef = ref(null);
watch(
  () => cmsContentStore.flightHeaderContent,
  (newValue) => {
    const baseUrl = "/api/v3/content/collections/images";
    if (newValue && newValue.backgoundImage) {
      const backgoundImage = newValue.backgoundImage;
      const url = backgoundImage ? backgoundImage.url : null;
      backgroundImageUrlRef.value = url ? baseUrl + url : null;
    }
  },
  { immediate: true, deep: true }
);
//endregion cms内容管理

//region 设备区分
const equipmentType = ref(templateStore.responsive.equipmentType);
watch(
  () => templateStore.responsive.equipmentType,
  (newValue) => {
    equipmentType.value = newValue;
  }
);
//endregion
</script>

<template>
  <!--   Banner Area -->
  <section id="page_banner">
    <div class="container">
      <div class="common_banner_text">
        <h2>{{ $t("flight-list") }}</h2>
        <ul>
          <li
            class="pre-page"
            v-if="route.query && route.query.isFromHome"
            @click="router.push({ name: 'home' })"
          >
            {{ $t("home") }}
          </li>
          <li
            v-else
            class="pre-page"
            @click="router.push({ name: 'sales-flight-home-v2' })"
          >
            {{ $t("flight") }}
          </li>
          <li>
            <i class="fas fa-circle"></i>
            {{ $t("flight-list") }}
          </li>
        </ul>
      </div>
    </div>
  </section>
  <!--   Banner Area  END-->

  <!-- Form Area -->
  <section class="fligth_top_search_main_form_wrapper">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="theme_search_form_area">
            <FlightShoppingForm
              :jumboable="false"
              :equipment-type="equipmentType"
            />
          </div>
        </div>
      </div>
    </div>
  </section>

  <!-- Flight Search Areas -->

  <!--机票列表 + Fare options中心区域显示-->
  <div class="flight_list_fares">
    <FlightItemAndFareOptionSelectPanel :equipment-type="equipmentType" />
  </div>
  <!--机票列表 + Fare options中心区域显示END-->
</template>

<style scoped></style>
