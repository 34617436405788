<script setup>
import { Empty, Modal, Spin } from "ant-design-vue";

import { ref } from "vue";
import { useI18n } from "vue-i18n";
import flightApi from "@/apis/flightApi";

const props = defineProps({
  itineraryFareOptionKeyMap: {},
  fareItem: {},
  flightInfo: {},
  fareIndex: {},
});

const AModal = Modal;
const AEmpty = Empty;

const { t } = useI18n();
//单独请求fare的时候使用
const fareDescriptionsRef = ref([]); //單獨請求
const hasFareDescriptionRef = ref(false);
const fareRuleRPHRef = ref(null);

const fareDescriptionModalOpenRef = ref(false);
const isLoadingRef = ref(false);
const fareActiveDesIndexRef = ref(null);

function getStructuredRules(
  itineraryFareOptionKeyMap,
  supplierCode,
  fareIndex
) {
  fareDescriptionModalOpenRef.value = true;
  isLoadingRef.value = true;
  if (fareIndex !== fareActiveDesIndexRef.value) {
    fareDescriptionsRef.value = [];
    hasFareDescriptionRef.value = false;
    if (itineraryFareOptionKeyMap) {
      for (const itineraryFareOptionKeyMapKey in itineraryFareOptionKeyMap) {
        if (itineraryFareOptionKeyMapKey.indexOf(supplierCode) !== -1) {
          fareRuleRPHRef.value =
            itineraryFareOptionKeyMap[itineraryFareOptionKeyMapKey];
          break;
        }
      }
    }
    const fareRuleRQ = {
      abbreviatedRuleTextInd: true,
      ruleReqInfo: {
        rph: fareRuleRPHRef.value,
      },
    };

    flightApi.fareRules(fareRuleRQ).then((res) => {
      isLoadingRef.value = false;
      if (res.data && res.data.fareRuleResponseInfos) {
        const fareRuleResponseInfos = res.data.fareRuleResponseInfos;
        let ruleInfos = null;
        if (fareRuleResponseInfos && fareRuleResponseInfos.length > 0) {
          ruleInfos = fareRuleResponseInfos[0].fareRuleInfo.ruleInfos;
          if (ruleInfos) {
            for (const ruleInfo of ruleInfos) {
              const fareRuleKey = ruleInfo.fareRuleKey;
              const chargesRules = ruleInfo.chargesRules;
              let voluntaryChange = null;
              let voluntaryRefund = null;
              if (chargesRules) {
                const voluntaryChanges = chargesRules.voluntaryChanges;
                const voluntaryRefunds = chargesRules.voluntaryRefunds;
                if (voluntaryChanges && voluntaryChanges.penalty) {
                  voluntaryChange = {};
                  voluntaryChange.penaltyType =
                    voluntaryChanges.penalty.penaltyType;
                  voluntaryChange.departureStatus =
                    voluntaryChanges.penalty.departureStatus;
                  voluntaryChange.currencyCode =
                    voluntaryChanges.penalty.currencyCode;
                  voluntaryChange.amount = voluntaryChanges.penalty.amount;
                  voluntaryChange.percent = voluntaryChanges.penalty.percent;
                  voluntaryChange.volChangeInd = voluntaryChanges.volChangeInd;
                }
                if (voluntaryRefunds && voluntaryRefunds.penalty) {
                  voluntaryRefund = {};
                  voluntaryRefund.penaltyType =
                    voluntaryRefunds.penalty.penaltyType;
                  voluntaryRefund.departureStatus =
                    voluntaryRefunds.penalty.departureStatus;
                  voluntaryRefund.currencyCode =
                    voluntaryChanges.penalty.currencyCode;
                  voluntaryRefund.amount = voluntaryRefunds.penalty.amount;
                  voluntaryRefund.percent = voluntaryRefunds.penalty.percent;
                  voluntaryRefund.volChangeInd = voluntaryRefunds.volChangeInd;
                }
              }
              fareDescriptionsRef.value.push({
                fareRuleKey,
                voluntaryChange,
                voluntaryRefund,
              });
            }
          }
        }
        hasFareDescriptionRef.value = true;
        fareRuleRPHRef.value = null;
      } else {
        fareDescriptionsRef.value = null;
        hasFareDescriptionRef.value = false;
      }
    });

    fareActiveDesIndexRef.value = fareIndex;
  } else {
    isLoadingRef.value = false;
  }
}

function buildStructuredFareMap(fareDescriptions) {
  let fareMap = null;
  if (fareDescriptions && fareDescriptions.length > 0) {
    fareMap = fareDescriptions.reduce((acc, currentObj) => {
      const key = currentObj.fareRuleKey;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(currentObj);
      return acc;
    }, {});
  }

  return fareMap;
}

function closeFareDescriptionModal() {
  fareDescriptionModalOpenRef.value = false;
}

function getDepartureStatusLabel(departureStatus) {
  let label = departureStatus;
  if (departureStatus === "BEFORE_DEPARTURE") {
    label = t("before-departure");
  } else if (departureStatus === "AFTER_DEPARTURE") {
    label = t("after-departure");
  }
  return label;
}
</script>

<template>
  <span
    class="mg-r-8"
    @click="
      getStructuredRules(
        itineraryFareOptionKeyMap,
        fareItem.supplierCode,
        fareIndex
      )
    "
  >
    {{ $t("structured-rules") }}
  </span>

  <!-- fare 描述 -->
  <a-modal
    v-model:open="fareDescriptionModalOpenRef"
    :cancel-button-props="{ style: { display: 'none' } }"
    :maskClosable="false"
    :ok-button-props="{ style: { display: 'none' } }"
    :z-index="11111"
    centered
    width="45%"
    wrapClassName="fare-modal"
    @cancel="closeFareDescriptionModal"
  >
    <div class="mg-t-20" style="margin-left: -20px">
      <div v-if="!hasFareDescriptionRef" class="text-center">
        {{ $t("loading") }}
        <Spin></Spin>
      </div>
      <div v-if="buildStructuredFareMap(fareDescriptionsRef)" class="p-3">
        <div
          v-for="(passengerFares, passengerFareKey) in buildStructuredFareMap(
            fareDescriptionsRef
          )"
          :key="passengerFareKey"
        >
          <div class="p-3">
            <div class="font-18 font_weight">{{ passengerFareKey }}</div>
            <div
              v-for="(passengerFare, passengerFareIndex) in passengerFares"
              :key="passengerFareIndex"
              class="p-1"
            >
              <!-- 是否可退 -->
              <div
                v-if="
                  passengerFare.voluntaryRefund &&
                  passengerFare.voluntaryRefund.volChangeInd
                "
                class="row"
              >
                <div class="col-4">
                  <i class="fa-solid fa-dollar-sign mg-r-8"></i>
                  {{ $t("cancellation-fee") }} ({{
                    getDepartureStatusLabel(
                      passengerFare.voluntaryRefund.departureStatus
                    )
                  }}):
                </div>
                <div class="col-4">
                  <span v-if="passengerFare.voluntaryRefund.percent"
                    >{{ $t("flight-price") }} x
                    {{ passengerFare.voluntaryRefund.percent }}</span
                  >
                  <span v-else>
                    {{ passengerFare.voluntaryRefund.currencyCode }}
                    {{ passengerFare.voluntaryRefund.amount }}</span
                  >
                </div>
              </div>
              <div v-else class="row">
                <div class="col-6">
                  <i class="fa-solid fa-xmark mg-r-8"></i>
                  {{ $t("non-refundable") }} (
                  {{
                    getDepartureStatusLabel(
                      passengerFare.voluntaryRefund.departureStatus
                    )
                  }}
                  )
                </div>
              </div>

              <!-- 是否可改航班  -->
              <div
                v-if="
                  passengerFare.voluntaryChange &&
                  passengerFare.voluntaryChange.volChangeInd
                "
                class="row"
              >
                <div class="col-4">
                  <i class="fa-solid fa-dollar-sign mg-r-8"></i>
                  {{ $t("change-fee") }}({{
                    getDepartureStatusLabel(
                      passengerFare.voluntaryChange.departureStatus
                    )
                  }}):
                </div>
                <div class="col-4">
                  <span v-if="passengerFare.voluntaryChange.percent"
                    >{{ $t("flight-price") }} x
                    {{ passengerFare.voluntaryChange.percent }}</span
                  >
                  <span v-else>
                    {{ passengerFare.voluntaryChange.currencyCode }}
                    {{ passengerFare.voluntaryChange.amount }}</span
                  >
                </div>
              </div>
              <div v-else class="row">
                <div class="col-6">
                  <i class="fa-solid fa-xmark mg-r-8"></i>
                  {{ $t("unchangeable") }} (
                  {{
                    getDepartureStatusLabel(
                      passengerFare.voluntaryChange.departureStatus
                    )
                  }}
                  )
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else-if="
          !isLoadingRef && !buildStructuredFareMap(fareDescriptionsRef)
        "
      >
        <AEmpty :description="$t('no-data')"></AEmpty>
      </div>
    </div>
  </a-modal>
  <!-- fare描述 END -->
</template>

<style lang="scss">
.fare-modal {
  .ant-modal-content {
    max-height: 700px;
    overflow-y: scroll;
  }
}
</style>
