<script setup>
import { ref, watch } from "vue";

import "vue3-tel-input/dist/vue3-tel-input.css";
import CustomerProfileTravelDocumentModifyFormItem from "@/views/profiles/items/form/profile/CustomerProfileTravelDocumentModifyFormItem.vue";
import { useVuelidate } from "@vuelidate/core";
import { useProfileStore } from "@/stores/profile";

const props = defineProps({
  userProfileFormModal: {},
});

const emits = defineEmits([]);
const profileStore = useProfileStore();
const userProfileFormModalRef = ref(null);

watch(
  () => props.userProfileFormModal,
  (newValue) => {
    userProfileFormModalRef.value = newValue;
  },
  { immediate: true, deep: true }
);

function addDocument() {
  userProfileFormModalRef.value.addNewDocument(
    "PASSPORT",
    null,
    null,
    null,
    null
  );
}

function removeDocument(index, partyDocumentId) {
  userProfileFormModalRef.value.removeNewDocument(index);
  //如果是已经添加进去的，直接调接口删除
  if (partyDocumentId)
    profileStore.removeTravelDocument(
      userProfileFormModalRef.value.partyId,
      partyDocumentId
    );
}

const v = useVuelidate();

async function modifyTravelDocuments(userProfileFormModal) {
  const result = await v.value.$validate();
  if (!result) return;
  emits("modifyTravelDocuments", userProfileFormModal, "TRAVELDOCUMENT");
}
</script>

<template>
  <form
    action="!#"
    id="profile_form_area"
    @submit.prevent="modifyTravelDocuments(userProfileFormModalRef)"
  >
    <h3>{{ $t("travel-documents") }}</h3>
    <div class="font-14">
      Travel documents Your passport is essential for international travel.
      We'll share reminders about travel restrictions and passport validity that
      may impact your trip.
    </div>
    <div
      class="row mg-b-10"
      v-for="(document, docIndex) in userProfileFormModalRef.documents"
      :key="docIndex"
    >
      <CustomerProfileTravelDocumentModifyFormItem
        :document-item="document"
        :passport-index="docIndex"
        :passport-num="userProfileFormModalRef.documents.length"
        @addDocument="addDocument"
        @removeDocument="removeDocument"
      />
    </div>

    <div class="col-lg-12" align="center">
      <button class="btn btn_theme">{{ $t("save") }}</button>
    </div>
  </form>
</template>

<style scoped></style>
