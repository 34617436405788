<script setup>
import HotelCancelPolicyContentPanel from "@/views/v2/sales/hotel/items/panel/HotelCancelPolicyContentPanel.vue";
import HotelDailyRatesPanel from "@/views/v2/sales/hotel/items/panel/HotelDailyRatesPanel.vue";

const props = defineProps({
  roomItem: {},
  roomKey: {},
  roomTypeItem: {},
});
const emits = defineEmits(["selectRoom"]);

function buildModelKey(type, roomKey) {
  return type + roomKey;
}

function selectRoom(roomItem, roomTypeName) {
  emits("selectRoom", roomItem, roomTypeName);
}

function rateCommentReplace(rateComment) {
  if (rateComment) {
    return rateComment.replaceAll("//", "<br>").replaceAll("\n", "<br>");
  }
}
</script>

<template>
  <td width="30%">
    <div v-if="roomItem.bedTypeName">
      <i class="fa fa-bed"></i> {{ roomItem.bedTypeName }}
    </div>
    <div v-if="roomItem.mealName">
      <i class="fa fa-bell-concierge"></i>
      {{
        roomItem.mealName === "Room Only"
          ? $t("room-only")
          : roomItem.mealName === "Breakfast"
          ? $t("breakfast")
          : roomItem.mealName
      }}
    </div>
    <p class="text-danger mb-2 cursor-point">
      <span
        v-if="roomItem.refundable"
        :data-bs-target="'#' + buildModelKey('C', roomKey)"
        class="mb-2"
        data-bs-toggle="modal"
      >
        {{ $t("refundable") }} <i class="fa fa-circle-info mg-l-5"></i>
      </span>
      <span v-else> {{ $t("non-refundable") }} </span>
    </p>

    <!-- 退费政策 -->
    <div
      :id="buildModelKey('C', roomKey)"
      :aria-labelledby="buildModelKey('C', roomKey)"
      aria-hidden="true"
      class="modal fade"
      role="dialog"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div
          v-if="roomItem.cancelPolicies && roomItem.cancelPolicies.length > 0"
          class="modal-content"
        >
          <HotelCancelPolicyContentPanel
            :cancel-policies="roomItem.cancelPolicies"
            :room-type-name="roomTypeItem ? roomTypeItem.roomTypeName : null"
          />
        </div>
      </div>
    </div>
    <!-- 退费政策 END -->
  </td>

  <td width="20%">
    <span v-if="roomTypeItem" class="fs-sm text-muted">
      <i class="fa fa-user-group"></i>

      {{
        roomTypeItem.maxOccupancy === roomTypeItem.minOccupancy
          ? $t("sleep-num", {
              num: roomTypeItem.maxOccupancy,
            })
          : $t("sleep-num-range", {
              max: roomTypeItem.maxOccupancy,
              min: roomTypeItem.minOccupancy,
            })
      }}
    </span>
  </td>

  <td width="30%">
    <h4 class="mb-1 text-warning">
      <span class="font-14">{{ $t("from") }}</span> {{ roomItem.currencyCode }}
      {{
        roomItem.avgBaseUnitPrice ? roomItem.avgBaseUnitPrice.toFixed(2) : null
      }}
    </h4>
    <p class="fs-sm fw-medium mb-2 text-muted">
      {{
        $t("price-total", {
          currency: roomItem.currencyCode,
          price: roomItem.totalBasePrice
            ? roomItem.totalBasePrice.toFixed(2)
            : null,
        })
      }}
    </p>
    <p class="fs-sm fw-medium mb-2 text-muted">
      {{ $t("for-room", { roomNum: roomItem.nightsStay }) }}(s)
    </p>
  </td>

  <td width="20%">
    <div
      :data-bs-target="'#' + buildModelKey('D', roomKey)"
      class="text-pointer cursor-point text-muted"
      data-bs-toggle="modal"
    >
      {{ $t("price-details") }}<i class="fa fa-angle-right mg-l-5"></i>
    </div>
    <div>
      <button
        class="btn btn-outline-primary me-1 mb-3"
        type="button"
        @click="
          selectRoom(roomItem, roomTypeItem ? roomTypeItem.roomTypeName : null)
        "
      >
        {{ $t("reserve") }}
      </button>
    </div>

    <!-- 日期价格 -->
    <div
      :id="buildModelKey('D', roomKey)"
      :aria-labelledby="buildModelKey('D', roomKey)"
      aria-hidden="true"
      class="modal fade"
      role="dialog"
      tabindex="-1"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <HotelDailyRatesPanel
            :room-item="roomItem"
            :room-type-name="roomTypeItem ? roomTypeItem.roomTypeName : null"
          />
        </div>
      </div>
    </div>
    <!-- 日期价格 END-->
  </td>
</template>

<style lang="scss" scoped></style>
