<script setup>
import { ref, watch } from "vue";
import FlatPickr from "vue-flatpickr-component";
import "vue3-tel-input/dist/vue3-tel-input.css";
import { VueTelInput } from "vue3-tel-input";
import useVuelidate from "@vuelidate/core";
import { adultNameTitleOptions } from "@/formmodel/sales/flight/common/options/flightCommonOption";
import * as regexUtils from "@/utils/regexUtils";
import { email, required } from "@vuelidate/validators";
import contentApi from "@/apis/contentApi";

const props = defineProps({
  userProfileFormModal: {},
  isAdditionalTraveller: {},
});

const emits = defineEmits([]);
const userProfileFormModalRef = ref(null);

const genderOptionsRef = ref([
  { name: "Male", code: "MALE" },
  { name: "Female", code: "FEMALE" },
]);

const adultNameTitleOptionsRef = ref(adultNameTitleOptions);
const countriesRef = ref(null);
watch(
  () => props.userProfileFormModal,
  (newValue) => {
    userProfileFormModalRef.value = newValue;
  },
  { immediate: true, deep: true }
);

const surnameRule = (value) => regexUtils.surnameRegx.test(value);
const givenNameRule = (value) => regexUtils.givenNameRegx.test(value);
const phoneRule = (value) =>
  (regexUtils.numberRegx.test(value) &&
    regexUtils.phoneNumberRegx.test(value)) ||
  !value;

let rules = {
  gender: { required },
  nameTitle: { required },
  birthDate: { required },
  surname: { required, surnameRule },
  givenName: { required, givenNameRule },
  mobileNumber: { required, phoneRule },
  emailAddress: { required, email },
  emergencyContactName: { required },
  emergencyNumber: { required, phoneRule },
  country: { required },
  province: { required },
  city: { required },
  region: { required },
  postCode: { required },
};

const v = useVuelidate(rules, userProfileFormModalRef);

function mobileNumberInput(value, event) {
  let isPhoneNumber =
    regexUtils.phoneNumberRegx.test(value) && regexUtils.numberRegx.test(value);
  if (
    isPhoneNumber &&
    event != null &&
    event.nationalNumber != null &&
    event.nationalNumber != ""
  ) {
    userProfileFormModalRef.value.mobileNumber = event.nationalNumber;
  } else if (
    isPhoneNumber &&
    event != null &&
    event.nationalNumber == null &&
    event.formatted != null &&
    event.formatted !== ""
  ) {
    userProfileFormModalRef.value.mobileNumber = event.formatted;
  } else if (typeof value === "string") {
    userProfileFormModalRef.value.mobileNumber = value;
  }
}

function mobileCountryChange(value) {
  userProfileFormModalRef.value.mobileCountryAccessCode = value.dialCode;
}

function emergencyNumberInput(value, event) {
  let isPhoneNumber =
    regexUtils.phoneNumberRegx.test(value) && regexUtils.numberRegx.test(value);
  if (
    isPhoneNumber &&
    event != null &&
    event.nationalNumber != null &&
    event.nationalNumber != ""
  ) {
    userProfileFormModalRef.value.emergencyNumber = event.nationalNumber;
  } else if (
    isPhoneNumber &&
    event != null &&
    event.nationalNumber == null &&
    event.formatted != null &&
    event.formatted !== ""
  ) {
    userProfileFormModalRef.value.emergencyNumber = event.formatted;
  } else if (typeof value === "string") {
    userProfileFormModalRef.value.emergencyNumber = value;
  }
}

function emergencyCountryChange(value) {
  userProfileFormModalRef.value.emergencyCountryAccessCode = value.dialCode;
}

function getCountries(keyword) {
  document.getElementById("country-dropdown-menu").classList.add("show");
  contentApi.countries(keyword).then((res) => {
    countriesRef.value = res.data;
  });
}

function selectCountry(country, menuId) {
  userProfileFormModalRef.value.country = country.name;
  document.getElementById(menuId).classList.remove("show");
}

async function modifyPersonInfo(userProfileFormModal) {
  const result = await v.value.$validate();
  if (!result) return;
  emits("modifyPersonInfo", userProfileFormModal, "CONTACT");
}
</script>

<template>
  <form
    action="!#"
    id="profile_form_area"
    @submit.prevent="modifyPersonInfo(userProfileFormModalRef)"
  >
    <h3>{{ $t("basic-information") }}</h3>
    <div class="row">
      <div class="col-3">
        <div class="form-group">
          <label for="f-name">{{ $t("gender") }}</label>
          <select
            id="passenger-form-nameTitle"
            v-model="userProfileFormModalRef.gender"
            :class="{
              'is-invalid': v.gender.$errors.length,
            }"
            class="form-control"
            type="text"
          >
            <option
              v-for="(genderOption, genderOptionIndex) in genderOptionsRef"
              :key="genderOptionIndex"
              :value="genderOption.code"
            >
              {{ genderOption.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label for="f-name">{{ $t("name-title") }}</label>
          <select
            id="passenger-form-nameTitle"
            v-model="userProfileFormModalRef.nameTitle"
            :class="{
              'is-invalid': v.nameTitle.$errors.length,
            }"
            class="form-control"
            type="text"
          >
            <option
              v-for="(
                nameTitleOption, nameTitleOptionIndex
              ) in adultNameTitleOptionsRef"
              :key="nameTitleOptionIndex"
              :value="nameTitleOption.code"
            >
              {{ nameTitleOption.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label for="f-name">{{ $t("date-of-birth") }}</label>
          <FlatPickr
            v-model="userProfileFormModalRef.birthDate"
            :class="{
              'is-invalid': v.birthDate.$errors.length,
            }"
            name="birth-flatpickr"
            :config="{
              maxDate: 'today',
            }"
            :placeholder="$t('birth-date')"
            class="form-control"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <div class="form-group">
          <label for="l-name">{{ $t("last-name") }}</label>
          <input
            type="text"
            class="form-control"
            id="profile-givenName"
            name="profile-givenName"
            :class="{
              'is-invalid': v.surname.$errors.length,
            }"
            :placeholder="$t('last-name')"
            @blur="v.surname.$touch"
            v-model="userProfileFormModalRef.surname"
          />
        </div>
      </div>
      <div class="col-4">
        <div class="form-group">
          <label for="f-name">{{ $t("first-name") }}</label>
          <input
            type="text"
            class="form-control"
            id="profile-surname"
            name="profile-surname"
            :class="{
              'is-invalid': v.givenName.$errors.length,
            }"
            @blur="v.givenName.$touch"
            :placeholder="$t('first-name')"
            v-model="userProfileFormModalRef.givenName"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <label for="mobil-number">{{ $t("mobile-number") }}</label>
          <VueTelInput
            :value="userProfileFormModalRef.mobileNumber"
            :autoFormat="false"
            :class="{
              'is-invalid': v.mobileNumber.$errors.length > 0,
            }"
            :inputOptions="{
              placeholder: $t('enter-your-phone-number'),
              autoFormat: false,
            }"
            autocomplete="off"
            class="form-control flex"
            mode="international"
            @blur="v.mobileNumber.$touch"
            @input="mobileNumberInput"
            @country-changed="mobileCountryChange"
          />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label for="mail-address">{{ $t("email-address") }}</label>
          <input
            type="text"
            v-model="userProfileFormModalRef.emailAddress"
            :class="{
              'is-invalid': v.emailAddress.$errors.length > 0,
            }"
            class="form-control"
            id="profile-emailAddress"
            name="profile-emailAddress"
            placeholder="(e.g. xxxxxxx@gmail.com)"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <label for="mobil-number">Emergency contact</label>
      <div class="col-lg-6">
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Contact Name"
            :class="{
              'is-invalid': v.emergencyContactName.$errors.length > 0,
            }"
            v-model="userProfileFormModalRef.emergencyContactName"
          />
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <VueTelInput
            :value="userProfileFormModalRef.emergencyNumber"
            :autoFormat="false"
            :class="{
              'is-invalid': v.emergencyNumber.$errors.length > 0,
            }"
            :inputOptions="{
              placeholder: $t('enter-your-phone-number'),
              autoFormat: false,
            }"
            autocomplete="off"
            class="form-control flex"
            mode="international"
            @blur="v.emergencyNumber.$touch"
            @input="emergencyNumberInput"
            @country-changed="emergencyCountryChange"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <label for="mobil-number">Address</label>
      <div class="col-lg-4">
        <div class="form-group">
          <input
            type="text"
            v-model="userProfileFormModalRef.country"
            :class="{
              'is-invalid': v.country.$errors.length > 0,
            }"
            aria-expanded="false"
            aria-haspopup="true"
            autocomplete="off"
            class="form-control"
            data-bs-toggle="dropdown"
            placeholder="Country/Region"
            @input="(event) => getCountries(event.target.value)"
          />
          <div
            id="country-dropdown-menu"
            class="dropdown-menu fs-sm full-width"
          >
            <div v-if="countriesRef && countriesRef.length > 0">
              <a
                v-for="(country, suggestIndex) in countriesRef"
                :key="suggestIndex"
                class="dropdown-item"
                href="javascript:void(0)"
                @click="selectCountry(country, 'country-dropdown-menu')"
              >
                <span class="text-muted me-0 mg-l-8">
                  {{ country.name }}
                </span>
              </a>
            </div>
            <div v-else>
              <div class="dropdown-item">
                <i class="fa fa-magnifying-glass me-3"></i>
                {{ $t("search") }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-group">
          <input
            :class="{
              'is-invalid': v.province.$errors.length > 0,
            }"
            type="text"
            class="form-control"
            placeholder="Province"
            v-model="userProfileFormModalRef.province"
          />
        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-group">
          <input
            v-model="userProfileFormModalRef.city"
            :class="{
              'is-invalid': v.city.$errors.length > 0,
            }"
            type="text"
            class="form-control"
            placeholder="City"
          />
        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Region"
            v-model="userProfileFormModalRef.region"
            :class="{
              'is-invalid': v.region.$errors.length > 0,
            }"
          />
        </div>
      </div>

      <div class="col-lg-4">
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            placeholder="Postcode"
            v-model="userProfileFormModalRef.postCode"
            :class="{
              'is-invalid': v.postCode.$errors.length > 0,
            }"
          />
        </div>
      </div>

      <div class="col-lg-12" align="center">
        <button class="btn btn_theme">{{ $t("save") }}</button>
        <!--        <button class="btn btn-secondary mg-l-10" v-if="isAdditionalTraveller">-->
        <!--          {{ $t("cancel") }}-->
        <!--        </button>-->
      </div>
    </div>
  </form>
</template>

<style scoped></style>
