import { Modal } from "ant-design-vue";

const AModal = Modal;

/***
 * 价格字符串转换成千分位
 * @param num
 * @returns {string}
 */
function formatPriceThousands(str) {
  return Number(str)
    .toFixed(2)
    .replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
}

// 获取assets静态资源
const getAssetsFile = (url) => {
  return new URL(`../../public/assets/flagIcon/${url}`, import.meta.url).href;
};

//计算数组中元素出现的次数 {"1":8,"2":25}
function countTimes(data) {
  let obj = {};
  return data.reduce(function (time, name) {
    if (name in time) {
      time[name]++;
    } else {
      time[name] = 1;
    }
    return time;
  }, {});
}

/**
 * 根据数组对象的某个字段去重
 * item.name  是[{name:1}] 根据每条数据的name值来去重
 * */
function unique(arr, val) {
  const res = new Map();
  return arr.filter((item) => !res.has(item[val]) && res.set(item[val], 1));
}

/**
 * 将字符串的第一个字母大写
 * @param str
 * @returns {string}
 */

function stringFirstUpper(str) {
  if (str) {
    return str[0].toUpperCase() + str.substring(1);
  } else {
    return null;
  }
}

//打开toast提示框
function openToastBox(toastType, title, message, callback) {
  switch (toastType) {
    case "SUCCESS":
      toastSuccess(title, message, callback);
      break;
    case "ERROR":
      toastError(title, message, callback);
      break;
    case "WARNING":
      toastWarning(title, message, callback);
      break;
  }
}

function toastSuccess(title, message, callback) {
  Modal.success({
    title: title,
    content: message,
    centered: true,
    zIndex: 222222,
    width: 600,
    onOk() {
      return new Promise((resolve, reject) => {
        if (callback) callback();
        AModal.destroyAll();
      });
    },
  });
}

function toastError(title, message, callback) {
  AModal.error({
    title: title,
    content: message,
    centered: true,
    zIndex: 222222,
    width: 600,
    onOk() {
      return new Promise((resolve, reject) => {
        if (callback) callback();
        AModal.destroyAll();
      });
    },
  });
}

function toastWarning(title, message, callback) {
  AModal.warning({
    title: title,
    content: message,
    centered: true,
    width: 600,
    zIndex: 222222,
    onOk() {
      return new Promise((resolve, reject) => {
        if (callback) callback();
        AModal.destroyAll();
      });
    },
  });
}

function getAirlinePicture(airlineCode) {
  return "http://images.skywidetech.com/airlines/" + airlineCode + ".png";
} //打开模态框

function openModelByKey(name, modelIndex) {
  return name + modelIndex;
}

function sortByParams(arrayList, type) {
  if (!arrayList || arrayList.length === 0) return null;

  return arrayList.sort(function (a, b) {
    if (a[type] < b[type]) {
      return -1;
    }
    if (a[type] > b[type]) {
      return 1;
    }
    return 0;
  });
}

export default {
  formatPriceThousands,
  getAssetsFile,
  countTimes,
  unique,
  stringFirstUpper,
  openToastBox,
  getAirlinePicture,
  openModelByKey,
  sortByParams,
};
