import { TripPassengerDocumentVO } from "@/viewobject/trip/common/tripPassengerDocumentVO";
import { TripPassengerCustLoyaltyVO } from "@/viewobject/trip/common/tripPassengerCustLoyaltyVO";

export default class UserProfileVO {
  constructor(
    partyId,
    username,
    nameTitle,
    gender,
    givenName,
    surname,
    birthDate,
    mobileNumber,
    mobileCountryAccessCode,
    mobileContactMechId,
    emergencyNumber,
    emergencyCountryAccessCode,
    emergencyContactName,
    emergencyContactMechId,
    emailAddress,
    emailContactMechId,
    country,
    province,
    city,
    region,
    postCode,
    addressContactMechId,
    knownTravellerNumber,
    knownTravellerNumberId,
    redressNumber,
    redressNumberId,
    documents,
    seatPref,
    seatPrefId,
    mealPref,
    mealPrefId,
    custLoyalties,
    additionalTravellers
  ) {
    this.partyId = partyId;
    this.username = username;
    this.nameTitle = nameTitle;
    this.gender = gender;
    this.givenName = givenName;
    this.surname = surname;
    this.birthDate = birthDate;
    this.mobileNumber = mobileNumber;
    this.mobileCountryAccessCode = mobileCountryAccessCode;
    this.mobileContactMechId = mobileContactMechId;
    this.emergencyNumber = emergencyNumber;
    this.emergencyCountryAccessCode = emergencyCountryAccessCode;
    this.emergencyContactName = emergencyContactName;
    this.emergencyContactMechId = emergencyContactMechId;
    this.emailAddress = emailAddress;
    this.emailContactMechId = emailContactMechId;
    this.country = country;
    this.province = province;
    this.city = city;
    this.region = region;
    this.postCode = postCode;
    this.addressContactMechId = addressContactMechId;
    this.knownTravellerNumber = knownTravellerNumber;
    this.knownTravellerNumberId = knownTravellerNumberId;
    this.redressNumber = redressNumber;
    this.redressNumberId = redressNumberId;
    this.documents = documents;
    this.seatPref = seatPref;
    this.seatPrefId = seatPrefId;
    this.mealPref = mealPref;
    this.mealPrefId = mealPrefId;
    this.custLoyalties = custLoyalties;
    this.additionalTravellers = additionalTravellers;
  }

  addNewDocument(
    docType,
    docID,
    expireDate,
    docIssueCountry,
    docHolderNationality,
    partyDocumentId
  ) {
    const document = new TripPassengerDocumentVO(
      docType,
      docID,
      expireDate,
      docIssueCountry,
      docHolderNationality
    );

    document.partyDocumentId = partyDocumentId;
    if (!this.documents) {
      this.documents = [];
      this.documents.push(document);
    } else {
      const itemContain = this.documents.find(
        (item) => item.docID === docID && item.docType === docType
      );
      if (!itemContain) {
        this.documents.push(document);
      }
    }

    return document;
  }

  removeNewDocument(passportIndex) {
    if (this.documents && this.documents.length > 0) {
      this.documents.splice(passportIndex, 1);
    }
  }

  addNewCustLoyalty(programID, membershipID, custLoyaltyId) {
    const ffpNumber = new TripPassengerCustLoyaltyVO(
      null,
      null,
      programID,
      membershipID
    );
    ffpNumber.custLoyaltyId = custLoyaltyId;
    if (!this.custLoyalties) {
      this.custLoyalties = [];
      this.custLoyalties.push(ffpNumber);
    } else {
      const itemContain = this.custLoyalties.find(
        (item) =>
          item.programID === programID && item.membershipID === membershipID
      );
      if (!itemContain) {
        this.custLoyalties.push(ffpNumber);
      }
    }

    return ffpNumber;
  }

  removeNewCustLoyalty(custLoyaltyIndex) {
    if (this.custLoyalties && this.custLoyalties.length > 0) {
      this.custLoyalties.splice(custLoyaltyIndex, 1);
    }
  }
}
