import dateUtils from "@/utils/dateUtils";
import commonUtils from "@/utils/commonUtils";

//#region 获取请求对象- Searching Hotels Request Object

/*
 * 获取酒店地区范围搜索或者特定酒店房型搜索的请求
 *
 * @param placeId number 可选，地区代码，从地区自动补全的api接口中获取，例如，123334455
 * @param ttiCode string 可选，酒店代码，从地区范围搜索的结果中获取，例如，7788996889
 * @param checkInDate string 必填，入住日期，格式位yyyy-MM-dd,例如，2023-06-14
 * @param checkOutDate string 必填，离开日期，格式位yyyy-MM-dd,例如，2023-06-15
 * @param roomPassengers Array 必填，RoomPassenger对象数组，每个房间与住客信息映射表
 * @param language string 可选，响应内容语言，例如，en，zh_HK，zh_CN
 * @param filterOption object 可选，结构可参考下面的FilterOption对象
 * @param sortingOption object 可选，结构可参考下面的SortingOption对象
 * @return object 酒店搜索请求对象
 */

function getHotelAvailRQ(
  placeId,
  type,
  ttiCode,
  checkInDate,
  checkOutDate,
  roomPassengers,
  language,
  filterOption,
  sortingOption,
  bestOnlyIndicator
) {
  let searchLevel = "VeryRecent";
  if (bestOnlyIndicator == null) bestOnlyIndicator = false;
  language = language == null ? "en" : language;

  let hotelRef = {
    hotelCityCode: placeId,
    hotelCodeContext: "PLACE",
  };
  if ("airport" == type) {
    hotelRef.hotelCodeContext = "AIRPORT";
  }
  if (ttiCode != null && ttiCode != "") {
    searchLevel = "Live";
    hotelRef.tticode = ttiCode;
  }

  let criterion = {
    hotelRefList: [hotelRef],
    stayDateRange: {
      start: checkInDate,
      end: checkOutDate,
    },
  };
  //console.info(filterOption);
  //过滤
  if (
    filterOption &&
    filterOption.starRatings &&
    filterOption.starRatings.length > 0
  ) {
    hotelRef.rating = filterOption.starRatings.toString();
  }
  if (
    filterOption &&
    filterOption.propertyClass &&
    filterOption.propertyClass != ""
  ) {
    hotelRef.propertyClassCode = filterOption.propertyClass.toString();
  }
  if (
    filterOption &&
    filterOption.userRating &&
    filterOption.userRating != ""
  ) {
    let award = {
      rating: filterOption.userRating,
      provider: "TRIPADVISOR", // 评分系统
    };
    criterion.awards = [];
    criterion.awards.push(award);
  }

  if (
    filterOption &&
    filterOption.priceRange != null &&
    filterOption.priceRange[0] > 0
  ) {
    criterion.rateRanges = [
      {
        minRate: filterOption.priceRange[0],
      },
    ];
  }

  if (
    filterOption &&
    filterOption.mealPlans &&
    filterOption.mealPlans.length > 0
  ) {
    criterion.mealPlan = {
      code: filterOption.mealPlans.toString(),
    };
  }
  // 排序
  if (
    sortingOption &&
    sortingOption.type &&
    sortingOption.type != "" &&
    sortingOption.order
  ) {
    criterion.tpaExtensions = {
      valueMap: {
        sortBy: sortingOption.type,
        sortType: sortingOption.order,
      },
    };
  }

  let roomStayCandidates = [];

  if (roomPassengers && roomPassengers.length > 0) {
    roomPassengers.forEach((roomPassenger) => {
      let adultNum = roomPassenger.adultNum;

      let roomStayCandidate = {
        quantity: 1,
        guestCounts: {
          guestCountList: [
            {
              ageQualifyingCode: "10",
              count: adultNum,
            },
          ],
        },
      };

      roomStayCandidates.push(roomStayCandidate);
      let childAges = roomPassenger.childAges;
      if (childAges != null && childAges.length > 0) {
        childAges.forEach((childAge) => {
          let age = childAge.age;
          let childGuest = {
            ageQualifyingCode: "8",
            age: age,
            count: 1,
          };
          roomStayCandidate.guestCounts.guestCountList.push(childGuest);
        });
      }
    });
  }

  return {
    primaryLangID: language,
    availRequestSegments: {
      maximumWaitTime: 120000,
      availRequestSegments: [
        {
          hotelSearchCriteria: {
            availableOnlyIndicator: true,
            bestOnlyIndicator: bestOnlyIndicator,
            criterionList: [criterion],
          },
          roomStayCandidates: roomStayCandidates,
        },
      ],
    },
    searchCacheLevel: searchLevel,
  };
}

/*
 * 酒店地区搜索过滤
 * */

// eslint-disable-next-line no-unused-vars
class FilterOption {
  constructor(
    starRatings,
    userRating,
    propertyClass,
    mealPlans,
    priceRange,
    placeId
  ) {
    // number， 可选，酒店星级
    this.starRatings = starRatings;
    // number，可选，用户评分
    this.userRating = userRating;
    // array，可选，住宿类型
    this.propertyClass = propertyClass;
    // array，可选，餐饮类型
    this.mealPlans = mealPlans;
    // object，可选，价格过滤，结构可参考下面PriceRange对象
    this.priceRange = priceRange;
    // number， 可选
    this.placeId = placeId;
  }
}

// // eslint-disable-next-line no-unused-vars
// class PriceRange {
//   constructor(minPrice, maxPrice) {
//     // number，必填，最低价格
//     this.minPrice = minPrice;
//     // number，必填，最高价格
//     this.maxPrice = maxPrice;
//   }
// }

/*
 * 酒店地区搜索排序
 * */

// eslint-disable-next-line no-unused-vars
class SortingOption {
  constructor(type, order) {
    //排序类型，可为PRICE，NAME，STAR_RATING，USER_RATING
    this.type = type;
    //升序降序，可为ASC-升序，DSC-降序
    this.order = order;
  }
}

/*
 * 获取搜索酒店描述的请求
 * @param ttiCode string 可选，酒店代码，从地区范围搜索的结果中获取，例如，7788996889
 */
function getDescriptiveRQ(ttiCode, language) {
  if (language == null) {
    language = "en";
  }
  return {
    primaryLangID: language,
    hotelDescriptiveInfos: {
      hotelDescriptiveInfoRequestInfo: [
        {
          tticode: ttiCode,
        },
      ],
    },
  };
}

/*
 *
 * @param roomResGuestInfoFormModel RoomResGuestInfoFormModel类对象
 * */
function getHoldRQ(roomResGuestInfoFormModel) {
  let checkIn = roomResGuestInfoFormModel.checkIn;
  let checkOut = roomResGuestInfoFormModel.checkOut;
  let roomResGuests = roomResGuestInfoFormModel.roomResGuests;
  let extraServices = roomResGuestInfoFormModel.extraServices;
  let roomStays = [];

  let resGuestsOta = [];
  let resGuestRPH = 0;
  let roomIndex = 0;
  roomResGuests.forEach((roomResGuest) => {
    let guestCounts = [];
    roomResGuest.resGuests.forEach((resGuest) => {
      let ageQualifyingCode = resGuest.ageQualifyingCode;
      let age = resGuest.age;

      let guestCount = {
        resGuestRPH: resGuestRPH,
        ageQualifyingCode: ageQualifyingCode,
        age: age,
        count: 1,
      };
      resGuestsOta.push(guestCount);
      guestCounts.push(guestCount);
      resGuestRPH++;
    });

    let roomStay = {
      roomTypes: [
        {
          quantity: 1,
        },
      ],
      guestCounts: {
        guestCountList: guestCounts,
      },
      reference: {
        type: "UUID",
        id: roomResGuest.referenceId,
      },
      roomStayIndex: roomIndex,
    };
    roomIndex++;
    roomStays.push(roomStay);
  });

  let serviceList = null;
  if (extraServices && Object.keys(extraServices).length > 0) {
    for (const roomIndex in extraServices) {
      for (const extraServiceKey in extraServices[roomIndex]) {
        const extraService = extraServices[roomIndex][extraServiceKey];
        const quantity = extraService.quantity;
        const ratePlanCode = extraService.ratePlanCode;
        const productCode = extraService.productCode;
        if (quantity == 0) continue;
        if (!serviceList) serviceList = [];

        let service = {
          ratePlanCode: ratePlanCode,
          type: "SUPPLEMENT",
          quantity: quantity,
          serviceRPH: roomIndex,
          id: productCode,
        };
        serviceList.push(service);
      }
    }
  }

  return getHotelResRQ(
    "Hold",
    checkIn,
    checkOut,
    roomStays,
    serviceList,
    resGuestsOta,
    null
  );
}

function getPreBookRQ(roomResGuestInfoFormModel) {
  let checkIn = roomResGuestInfoFormModel.checkIn;
  let checkOut = roomResGuestInfoFormModel.checkOut;
  let roomResGuests = roomResGuestInfoFormModel.roomResGuests;
  let extraServices = roomResGuestInfoFormModel.extraServices;
  let roomStays = [];
  let resGuestsOta = [];
  let roomIndex = 0;
  roomResGuests.forEach((roomResGuest) => {
    let guestCounts = [];
    let resGuests = roomResGuest.resGuests;
    resGuests.forEach((resGuest) => {
      let resGuestRPH = resGuest.resGuestRPH;
      let ageQualifyingCode = resGuest.ageQualifyingCode;
      let age = resGuest.age;
      resGuestsOta.push(resGuest);

      let guestCount = {
        resGuestRPH: resGuestRPH,
        ageQualifyingCode: ageQualifyingCode,
        age: age,
        count: 1,
      };
      guestCounts.push(guestCount);
    });

    let roomStay = {
      roomTypes: [
        {
          quantity: 1,
        },
      ],
      guestCounts: {
        guestCountList: guestCounts,
      },
      reference: {
        type: "UUID",
        id: roomResGuest.referenceId,
      },
      roomStayIndex: roomIndex,
    };
    if (roomResGuest.remarks) {
      roomStay.specialRequests = {
        specialRequests: [
          {
            text: roomResGuest.remarks,
          },
        ],
      };
    }
    roomIndex++;
    roomStays.push(roomStay);
  });

  let serviceList = null;
  if (extraServices && Object.keys(extraServices).length > 0) {
    for (const roomIndex in extraServices) {
      for (const extraServiceKey in extraServices[roomIndex]) {
        const extraService = extraServices[roomIndex][extraServiceKey];
        const quantity = extraService.quantity;
        const ratePlanCode = extraService.ratePlanCode;
        const productCode = extraService.productCode;
        if (quantity == 0) continue;
        if (!serviceList) serviceList = [];

        let service = {
          ratePlanCode: ratePlanCode,
          type: "SUPPLEMENT",
          quantity: quantity,
          serviceRPH: roomIndex,
          id: productCode,
        };
        serviceList.push(service);
      }
    }
  }
  return getHotelResRQ(
    "Initiate",
    checkIn,
    checkOut,
    roomStays,
    serviceList,
    resGuestsOta,
    null
  );
}

function getBookRQ(prebookRQ, prebookRS) {
  let uniqueIDList = prebookRS.hotelReservationList[0].uniqueIDList;
  prebookRQ.resStatus = "Book";
  prebookRQ.hotelReservationList.forEach((hotelReservation) => {
    hotelReservation.resStatus = "Book";
    hotelReservation.uniqueIDList = uniqueIDList;
  });
  return prebookRQ;
}

function getHotelResRQ(
  resStatus,
  checkInDate,
  checkOutDate,
  roomStayList,
  serviceList,
  resGuestList,
  uniqueIDList
) {
  if (uniqueIDList == null) {
    uniqueIDList = [];
  }

  resGuestList.forEach((resGuest) => {
    if (
      resGuest.surname == null &&
      !resGuest.mainIndicator &&
      !resGuest.primaryIndicator
    ) {
      resGuest.surname = "TBA-" + resGuest.resGuestRPH;
      resGuest.givenName = "TBA-" + resGuest.resGuestRPH;
    }
    if (resGuest.nameTitle == null) {
      resGuest.nameTitle = "Mr";
    }
  });

  return {
    resStatus: resStatus,
    hotelReservationList: [
      {
        resGlobalInfo: {
          timeSpan: {
            start: checkInDate,
            end: checkOutDate,
          },
        },
        uniqueIDList: uniqueIDList,
        roomStayList: roomStayList,
        serviceList: serviceList,
        resStatus: resStatus,
        resGuestList: resGuestList,
      },
    ],
  };
}

// eslint-disable-next-line no-unused-vars
class ResGuest {
  constructor(
    primaryIndicator,
    age,
    ageQualifyingCode,
    resGuestRPH,
    givenName,
    surname,
    nameTitle,
    emailAddress,
    countryAccessCode,
    phoneNumber,
    mainIndicator
  ) {
    this.primaryIndicator = primaryIndicator;
    this.age = age;
    this.ageQualifyingCode = ageQualifyingCode;
    this.nameTitle = nameTitle;
    this.givenName = givenName;
    this.surname = surname;
    this.resGuestRPH = resGuestRPH;
    this.countryAccessCode = countryAccessCode;
    this.phoneNumber = phoneNumber;
    this.emailAddress = emailAddress;
    this.mainIndicator = mainIndicator;
  }
}

//#endregion

//#region 获取酒店信息视图对象-View Object
// eslint-disable-next-line no-unused-vars

/*
 * 获取显示的酒店信息
 *
 * @return 返回值为Map对象，tticode -》 HotelViewItem的映射
 * */
function getHotelViewItemMap(hotelAvailRS, hotelDescriptiveRS) {
  let matchResult =
    hotelAvailRS == null &&
    hotelAvailRS.hotelStays == null &&
    hotelAvailRS.hotelStays.length == 0
      ? false
      : true;
  if (!matchResult) return {};

  let hotelViewItemMap = {};
  hotelAvailRS.hotelStays.forEach((hotelStay) => {
    let hotelViewItem = getHotelViewItem(hotelStay, hotelDescriptiveRS);
    hotelViewItemMap[hotelViewItem.ttiCode] = hotelViewItem;
  });

  //services
  let ratePlanSupplementMap = {};
  if (hotelAvailRS.services && hotelAvailRS.services.services) {
    hotelAvailRS.services.services.forEach((service) => {
      const serviceDescription = service.serviceDetails.serviceDescription;
      const supplementName = serviceDescription.name;
      const ratePlanCode = service.ratePlanCode;
      const id = service.id;
      const amountIncludingMarkup =
        service.prices[0].total.amountIncludingMarkup;
      const currencyCode = service.prices[0].total.currencyCode;
      const chargeType = service.prices[0].chargeType;

      let supplement = {
        productName: supplementName,
        chargeTypeName: chargeType,
        amount: amountIncludingMarkup,
        currency: currencyCode,
        productCode: id,
        ratePlanCode: ratePlanCode,
      };
      if (ratePlanSupplementMap[ratePlanCode]) {
        ratePlanSupplementMap[ratePlanCode].supplements.push(supplement);
      } else {
        ratePlanSupplementMap[ratePlanCode] = {
          supplements: [supplement],
        };
      }
    });
  }

  let maxPrice = null;
  hotelAvailRS.roomStays.roomStayList.forEach((roomStay) => {
    let ttiCode = roomStay.basicPropertyInfo.tticode;
    let roomTypeCode = roomStay.roomTypes[0].roomTypeCode;
    let roomTypes = hotelViewItemMap[ttiCode].roomTypes;

    //酒店重要信息只初始化一次
    if (Object.keys(roomTypes).length == 0) {
      let vendorMessages = roomStay.basicPropertyInfo.vendorMessages;
      if (vendorMessages) {
        let propTextContent = {};
        vendorMessages.forEach((vendorMessage) => {
          let infoType = vendorMessage.infoType;
          if (vendorMessage.subSections) {
            let paragraphs = vendorMessage.subSections[0].paragraphs;
            propTextContent[infoType] = paragraphs;
          }
          hotelViewItemMap[ttiCode].propTextContent = propTextContent;
        });
      }
    }

    //每个房型信息只初始化一次
    if (roomTypes[roomTypeCode] == null) {
      let roomTypeView = getRoomTypeViewItem(roomStay);
      roomTypes[roomTypeView.roomTypeCode] = roomTypeView;
    }

    let rooms = roomTypes[roomTypeCode].rooms;
    let roomViewItem = getRoomViewItem(roomStay);
    if (
      hotelViewItemMap[ttiCode].bestPrice == null ||
      hotelViewItemMap[ttiCode].bestPrice == 0 ||
      hotelViewItemMap[ttiCode].bestPrice > roomViewItem.totalPrice
    ) {
      hotelViewItemMap[ttiCode].bestPrice = roomViewItem.totalPrice;
      hotelViewItemMap[ttiCode].currencyCode = roomViewItem.currency;
      hotelViewItemMap[ttiCode].averagePrice =
        roomViewItem.totalPrice / roomViewItem.nightsStay;
    }
    if (maxPrice == null || maxPrice < roomViewItem.totalPrice) {
      maxPrice = Math.ceil(roomViewItem.totalPrice);
    }

    if (
      roomTypes[roomTypeCode].bestPrice == null ||
      roomTypes[roomTypeCode].bestPrice == 0 ||
      roomTypes[roomTypeCode].bestPrice > roomViewItem.totalPrice
    ) {
      roomTypes[roomTypeCode].bestPrice = roomViewItem.totalPrice;
      roomTypes[roomTypeCode].currencyCode = roomViewItem.currency;
    }

    const ratePlanCode = roomStay.ratePlans[0].ratePlanCode;
    if (ratePlanSupplementMap[ratePlanCode]) {
      roomViewItem.supplements =
        ratePlanSupplementMap[ratePlanCode].supplements;
    }
    rooms.push(roomViewItem);
  });

  Object.keys(hotelViewItemMap).forEach((key) => {
    hotelViewItemMap[key].maxPrice = maxPrice;
  });
  // console.info(hotelViewItemMap);
  return hotelViewItemMap;
}

function getSortedHotelViewItems(hotelViewItemMap, hotelAvailRS) {
  let sortedHotelViewItems = [];
  hotelAvailRS.roomStays.roomStayList.forEach((roomStay) => {
    let ttiCode = roomStay.basicPropertyInfo.tticode;

    const hotelViewItem = hotelViewItemMap[ttiCode];
    sortedHotelViewItems.push(hotelViewItem);
  });

  sortedHotelViewItems = commonUtils.unique(sortedHotelViewItems, "ttiCode");
  return sortedHotelViewItems;
}

function getHotelViewItem(hotelStay, hotelDescriptionRS) {
  const basicPropertyInfo = hotelStay.basicPropertyInfo;
  const hotelName = basicPropertyInfo.hotelName;
  const tticode = basicPropertyInfo.tticode;
  const hotelCityName = basicPropertyInfo.hotelCtyName;
  const starRating = basicPropertyInfo.rating;
  const mainImageURL = basicPropertyInfo.mainImageURL;
  const position = basicPropertyInfo.position;
  const latitude = position.latitude;
  const longitude = position.longitude;
  const address = basicPropertyInfo.address.addressString;
  const countryName = basicPropertyInfo.address.countryName;
  const awards = basicPropertyInfo.awards;
  const description = basicPropertyInfo.shortDescriptions;

  let userRating = 0;
  if (awards != null && awards.length > 0) {
    awards.forEach((award) => {
      if (award.provider == "TRIPADVISOR") {
        userRating =
          award.rating == null || award.rating == "" || award.rating == "null"
            ? 0
            : parseFloat(award.rating);
      }
    });
  }

  let facilityInfos = [];
  let images = [];
  if (
    hotelDescriptionRS != null &&
    hotelDescriptionRS.hotelDescriptiveContents != null &&
    hotelDescriptionRS.hotelDescriptiveContents.hotelDescriptiveContentList !=
      null &&
    hotelDescriptionRS.hotelDescriptiveContents.hotelDescriptiveContentList
      .length != 0
  ) {
    let content =
      hotelDescriptionRS.hotelDescriptiveContents
        .hotelDescriptiveContentList[0];
    // 服务设施
    if (content.hotelInfo.serviceList != null) {
      facilityInfos = content.hotelInfo.serviceList;
    }
    // 房间设施
    if (
      content.facilityInfo &&
      content.facilityInfo.guestRooms &&
      content.facilityInfo.guestRooms.guestRoomList &&
      content.facilityInfo.guestRooms.guestRoomList[0].amenities &&
      content.facilityInfo.guestRooms.guestRoomList[0].amenities.amenityList
    ) {
      let amenityList =
        content.facilityInfo.guestRooms.guestRoomList[0].amenities.amenityList;
      amenityList.forEach((amenity) => {
        facilityInfos.push({
          codeDetail: amenity.descriptiveText,
          type: "ROOM_VIEW",
        });
      });
    }

    // 图片，文字描述
    if (
      content.multimediaDescriptions != null &&
      content.multimediaDescriptions.multimediaDescriptionList != null &&
      content.multimediaDescriptions.multimediaDescriptionList.length > 0
    ) {
      let multimediaDescription =
        content.multimediaDescriptions.multimediaDescriptionList[0];

      if (multimediaDescription.imageItems) {
        multimediaDescription.imageItems.forEach((imageItem) => {
          let image = {
            url: imageItem.url,
            type: "hotel",
          };
          if (
            imageItem.imageFormatList != null &&
            imageItem.imageFormatList.length > 0 &&
            "bigger" == imageItem.imageFormatList[0].dimensionCategory
          ) {
            let roomTypeCode = imageItem.roomTypeCode;
            if (roomTypeCode != null) {
              image.type = "room";
              image.caption = roomTypeCode;
            }

            images.push(image);
          } else if (imageItem.imageFormatList == null) {
            images.push(image);
          }
        });
      }
    }
  }

  let hotelViewItem = new HotelViewItem(
    hotelName,
    tticode,
    starRating,
    userRating,
    hotelCityName,
    countryName,
    longitude,
    latitude,
    address,
    mainImageURL,
    null,
    null,
    null,
    description,
    facilityInfos,
    {},
    images,
    null,
    null
  );

  return hotelViewItem;
}

function getRoomTypeViewItem(roomStay) {
  const guestCountsOta = roomStay.guestCounts.guestCountList;
  const roomTypeOta = roomStay.roomTypes[0];

  // 房型信息相关
  let occupanciesOta = roomTypeOta.occupancies[0];
  let roomTypeName = roomTypeOta.roomType;
  let roomTypeCode = roomTypeOta.roomTypeCode;
  let minOccupancy = occupanciesOta ? occupanciesOta.minOccupancy : null;
  let maxOccupancy = occupanciesOta ? occupanciesOta.maxOccupancy : null;
  let ttiCode = roomStay.basicPropertyInfo.tticode;
  //todo 房间设施
  let amenities = [];
  //具体房间价格在hotelTools#getRoomViewItem方法中获取
  let rooms = [];

  //住客相关
  let adultNum = 0;
  let childNum = 0;
  guestCountsOta.forEach((guestCount) => {
    switch (guestCount.ageQualifyingCode) {
      case "10":
        adultNum += guestCount.count;
        break;
      case "8":
        childNum += guestCount.count;
        break;
      default:
    }
  });

  let roomTypeView = new RoomTypeViewItem(
    ttiCode,
    roomTypeName,
    roomTypeCode,
    adultNum,
    childNum,
    guestCountsOta,
    minOccupancy,
    maxOccupancy,
    amenities,
    rooms
  );
  return roomTypeView;
}

function getRoomViewItem(roomStay) {
  const ratePlanOta = roomStay.ratePlans[0];
  const roomTypeOta = roomStay.roomTypes[0];
  const roomRateOta = roomStay.roomRates.roomRates[0];
  const brand = roomStay.infoSource;
  const specialRequests = roomStay.specialRequests;
  const ratesOta = roomRateOta.rates.rateList;
  const cancelPenaltiesOta =
    roomStay.cancelPenalties != null
      ? roomStay.cancelPenalties.cancelPenalties
      : null;
  const totalOta = roomStay.total;
  const referenceId = roomStay.reference != null ? roomStay.reference.id : null;
  const timeSpan = roomStay.timeSpan;

  let totalPrice = totalOta.amountIncludingMarkup;
  let roomPrice = totalOta.amountBeforeTax;
  // let feesTaxesInc = totalPrice - roomPrice; // 包含在总价内的费用和税费
  let currency = totalOta.currencyCode;
  let availableQuantity = roomTypeOta.quantity;
  let bedTypeName = roomTypeOta.bedTypeName;
  let nightsStay = ratesOta.length;
  let checkIn = timeSpan.start;
  let checkOut = timeSpan.end;
  let mealIncluded = ratePlanOta.mealsIncluded.mealPlanIndicator;
  let mealPlan = ratePlanOta.mealsIncluded;
  let mealName =
    ratePlanOta.mealsIncluded.shortName != ""
      ? ratePlanOta.mealsIncluded.shortName
      : "Breakfast";

  // 每日价格相关
  let dailyRates = [];
  let feesTaxesInc = 0;
  ratesOta.sort(function (a, b) {
    return new Date(a.effectiveDate) > new Date(b.effectiveDate) ? 1 : -1;
  });
  // console.info(ratesOta);
  ratesOta.forEach((rateOta) => {
    let feesOta = rateOta.fees;
    let feesAmount = 0;
    if (feesOta) {
      feesOta.forEach((feeOta) => {
        let amount = feeOta.amount;
        feesAmount += amount;
      });
    }
    feesTaxesInc += feesAmount;
    // const effectiveDate = dateUtils.format(rateOta.effectiveDate);
    const effectiveDate = rateOta.effectiveDate;
    let amountBeforeTax =
      rateOta.total != null ? rateOta.total.amountBeforeTax : null;
    let amountIncludingMarkup =
      rateOta.total != null ? rateOta.total.amountIncludingMarkup : null;

    let dailyRate = new DailyRate(
      effectiveDate,
      amountBeforeTax,
      feesAmount,
      amountIncludingMarkup,
      currency
    );
    dailyRates.push(dailyRate);
  });

  //酒店费用，需要到酒店付款
  let feesTaxesExcs = [];
  if (roomRateOta.total) {
    let taxesOta = roomRateOta.total.taxes;
    if (taxesOta && taxesOta.taxes) {
      taxesOta.taxes.forEach((tax) => {
        if (tax.type == "EXCLUSIVE") {
          let feesTaxesExc = {
            amount: tax.amount,
            currency: currency,
            name: tax.taxDescriptions[0].name,
          };
          feesTaxesExcs.push(feesTaxesExc);
        }
      });
    }
  }
  //房间描述相关
  let roomRateDescriptionsOta = roomRateOta.roomRateDescriptionList;
  let rateComments = null;
  let rateDescription = null;
  if (roomRateDescriptionsOta) {
    roomRateDescriptionsOta.forEach((roomRateDescription) => {
      if ("RATE_COMMENTS" == roomRateDescription.name) {
        rateComments = roomRateDescription.textList[0].stringValue;
      } else if ("RATE_DESCRIPTIONS" == roomRateDescription.name) {
        rateDescription = "";
        if (roomRateDescription.textList != null) {
          roomRateDescription.textList.forEach((text) => {
            rateDescription += text.stringValue + "<br>";
          });
        }
      }
    });
  }

  // 取消策略相关
  let refundable = false;
  let cancelPolicies = [];
  if (cancelPenaltiesOta != null && cancelPenaltiesOta.length > 0) {
    cancelPenaltiesOta.forEach((cancelPenalty) => {
      if (cancelPenalty.deadline != null) {
        refundable = true;
        let absoluteDeadlineTimeStamp = cancelPenalty.deadline.absoluteDeadline;
        let absoluteDeadlineDate = new Date(absoluteDeadlineTimeStamp);
        let dateFormat = dateUtils.formatDate(absoluteDeadlineDate);
        let amountPercent = cancelPenalty.amountPercent;

        let cancelPolicy = {
          beginDate: dateFormat,
          nmbrOfUnit: amountPercent != null ? amountPercent.amount : null,
          unit: "Amount",
        };

        if (
          amountPercent != null &&
          (amountPercent.amount == null || amountPercent.amount == 0) &&
          amountPercent.percent != null
        ) {
          cancelPolicy.amount = (amountPercent.percent / 100) * totalPrice;
        } else if (
          amountPercent != null &&
          amountPercent.nmbrOfNights != null
        ) {
          // 若是按照天数收费
          cancelPolicy.unit = "Night";
          cancelPolicy.nmbrOfUnit = amountPercent.nmbrOfNights;
        }

        cancelPolicies.push(cancelPolicy);
      }
    });
  }

  // 特殊请求
  let remarks = null;
  if (
    specialRequests != null &&
    specialRequests.specialRequests != null &&
    specialRequests.specialRequests.length > 0
  ) {
    let specialRequest = specialRequests.specialRequests[0].text;
    remarks = specialRequest;
  }

  let roomView = new RoomViewItem(
    bedTypeName,
    checkIn,
    checkOut,
    roomPrice,
    totalPrice,
    feesTaxesInc,
    feesTaxesExcs,
    currency,
    nightsStay,
    mealIncluded,
    mealName,
    dailyRates,
    availableQuantity,
    refundable,
    cancelPolicies,
    rateComments,
    referenceId,
    brand,
    rateDescription,
    remarks,
    null,
    mealPlan
  );
  return roomView;
}

// eslint-disable-next-line no-unused-vars
class HotelViewItem {
  constructor(
    hotelName,
    ttiCode,
    starRating,
    userRating,
    cityName,
    countryName,
    longitude,
    latitude,
    address,
    mainImage,
    bestPrice,
    currencyCode,
    bestRoomTypeName,
    description,
    facilities,
    roomTypes,
    images,
    propTextContent,
    averagePrice
  ) {
    this.hotelName = hotelName;
    this.ttiCode = ttiCode;
    this.starRating = starRating;
    this.userRating = userRating;
    this.cityName = cityName;
    this.countryName = countryName;
    this.longitude = longitude;
    this.latitude = latitude;
    this.address = address;
    this.mainImage = mainImage;
    this.bestPrice = bestPrice;
    this.averagePrice = averagePrice;
    this.currencyCode = currencyCode;
    this.bestRoomTypeName = bestRoomTypeName;
    this.description = description;
    this.facilities = facilities;
    this.roomTypes = roomTypes;
    this.images = images;
    this.propTextContent = propTextContent;
  }
}

// eslint-disable-next-line no-unused-vars
class RoomTypeViewItem {
  constructor(
    ttiCode,
    roomTypeName,
    roomTypeCode,
    adultNum,
    childNum,
    guestCounts,
    minOccupancy,
    maxOccupancy,
    amenities,
    rooms
  ) {
    this.ttiCode = ttiCode;
    this.roomTypeName = roomTypeName;
    this.roomTypeCode = roomTypeCode;
    this.adultNum = adultNum;
    this.childNum = childNum;
    this.guestCounts = guestCounts;
    this.minOccupancy = minOccupancy;
    this.maxOccupancy = maxOccupancy;
    this.amenities = amenities;
    this.rooms = rooms;
  }
}

// eslint-disable-next-line no-unused-vars
/*
 *  ttiCode -(1-1)-> hotelViewItem -(1-n)-> roomTypeViewItem -(1-n)-> roomViewItem
 * */
class RoomViewItem {
  constructor(
    bedTypeName,
    checkIn,
    checkOut,
    roomPrice,
    totalPrice,
    feesTaxesInc,
    feesTaxesExcs,
    currency,
    nightsStay,
    mealsIncluded,
    mealName,
    dailyRates,
    availableQuantity,
    refundable,
    cancelPolicy,
    rateComments,
    referenceId,
    brand,
    rateDescription,
    remarks,
    supplements,
    mealPlan
  ) {
    this.bedTypeName = bedTypeName;
    this.checkIn = checkIn;
    this.checkOut = checkOut;
    this.roomPrice = roomPrice;
    this.totalPrice = totalPrice;
    this.feesTaxesInc = feesTaxesInc;
    this.feesTaxesExcs = feesTaxesExcs;
    this.currency = currency;
    this.nightsStay = nightsStay;
    this.mealsIncluded = mealsIncluded;
    this.mealName = mealName;
    this.dailyRates = dailyRates;
    this.availableQuantity = availableQuantity;
    this.refundable = refundable;
    this.cancelPolicy = cancelPolicy;
    this.rateComments = rateComments;
    this.referenceId = referenceId;
    this.brand = brand;
    this.rateDescription = rateDescription;
    this.remarks = remarks;
    this.supplements = supplements;
    this.mealPlan = mealPlan;
  }
}

class DailyRate {
  constructor(date, roomPrice, feesPrice, totalPrice, currency) {
    this.date = date;
    this.roomPrice = roomPrice;
    this.feesPrice = feesPrice;
    this.totalPrice = totalPrice;
    this.currency = currency;
  }
}

function getHotelImages(hotelDescriptionRS) {
  let images = [];
  if (
    hotelDescriptionRS != null &&
    hotelDescriptionRS.hotelDescriptiveContents != null &&
    hotelDescriptionRS.hotelDescriptiveContents.hotelDescriptiveContentList !=
      null &&
    hotelDescriptionRS.hotelDescriptiveContents.hotelDescriptiveContentList
      .length != 0
  ) {
    let content =
      hotelDescriptionRS.hotelDescriptiveContents
        .hotelDescriptiveContentList[0];

    // 图片，文字描述
    if (
      content.multimediaDescriptions != null &&
      content.multimediaDescriptions.multimediaDescriptionList != null &&
      content.multimediaDescriptions.multimediaDescriptionList.length > 0
    ) {
      let multimediaDescription =
        content.multimediaDescriptions.multimediaDescriptionList[0];

      multimediaDescription.imageItems.forEach((imageItem) => {
        let image = {
          url: imageItem.url,
          type: "hotel",
          category: "",
          caption: imageItem.caption,
        };

        if (
          imageItem.imageFormatList != null &&
          imageItem.imageFormatList.length > 0 &&
          "bigger" === imageItem.imageFormatList[0].dimensionCategory
        ) {
          let roomTypeCode = imageItem.roomTypeCode;
          if (roomTypeCode != null) {
            image.type = "room";
          }
          image.category = "bigger";
          images.push(image);
        } else if (imageItem.imageFormatList == null) {
          images.push(image);
        }
      });
    }
  }
  return images;
}

//#endregion

//region 获取表单对象

// 搜索表單相關
// eslint-disable-next-line no-unused-vars
class HotelSearchFormModel {
  /*
   * @param roomPassengers RoomPassengerFormModel.roomPassengers數組對象
   * */
  constructor(
    placeId,
    ttiCode,
    checkInDate,
    checkOutDate,
    roomPassengers,
    language,
    filterOption,
    sortingOption,
    bestOnlyIndicator,
    type
  ) {
    this.placeId = placeId;
    this.type = type;
    this.ttiCode = ttiCode;
    this.checkInDate = checkInDate;
    this.checkOutDate = checkOutDate;
    this.roomPassengers = roomPassengers;
    this.language = language;
    this.filterOption = filterOption;
    this.sortingOption = sortingOption;
    this.bestOnlyIndicator = bestOnlyIndicator;
  }
}

// eslint-disable-next-line no-unused-vars
class RoomPassengerFormModel {
  constructor(roomPassengers) {
    if (roomPassengers == null || roomPassengers.length === 0) {
      let roomPassenger = new RoomPassenger(1, 0, []);
      this.roomPassengers = [roomPassenger];
    } else {
      this.roomPassengers = roomPassengers;
    }
    // console.info(this.roomPassengers);
  }

  addNewRoomPassenger() {
    let roomPassenger = new RoomPassenger(1, 0, []);
    this.roomPassengers.push(roomPassenger);
  }

  removeRoomPassenger(index) {
    // (this.roomPassengers.length);
    if (this.roomPassengers.length == 1) return;
    this.roomPassengers.splice(index, 1);
  }
}

class RoomPassenger {
  /*
   * @param childAges number类型数组 [6,8,9]
   */
  constructor(adultNum, childNum, childAges) {
    this.adultNum = adultNum;
    this.childNum = childNum;
    this.childAges = childAges;
  }

  addChildNum() {
    this.childNum++;
    this.childAges.push({ age: null });
    // console.info(this.childAges);
  }

  substractChildNum() {
    if (this.childNum == 0) return;
    this.childAges.splice(this.childAges.length - 1, 1);
    this.childNum--;
  }
}

//住客信息頁表單相關

class RoomResGuest {
  /*
   * @param referenceId string 房间标识id
   * */
  constructor(
    referenceId,
    roomTypeName,
    bedTypeName,
    adultNum,
    childNum,
    remarks
  ) {
    this.referenceId = referenceId;
    this.resGuests = [];
    this.roomTypeName = roomTypeName;
    this.bedTypeName = bedTypeName;
    this.adultNum = adultNum;
    this.childNum = childNum;
    this.remarks = remarks;
  }

  /*
   *
   * @param primaryIndicator boolean 判断是否为联系人,若为true，则为联系人
   * */
  addNewResGuest(primaryIndicator, rph, guestType, age) {
    let guestInfo = new ResGuest(
      primaryIndicator,
      age,
      guestType,
      rph,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    );
    this.resGuests.push(guestInfo);
    return guestInfo;
  }
}

class RoomResGuestInfoFormModel {
  /*
   *
   * @param checkIn string 入住日期，格式为yyyy-MM-dd，例如，2023-06-12
   * @param checkOut string 离开日期，格式为yyyy-MM-dd，例如，2023-06-16
   * */
  constructor(checkIn, checkOut) {
    this.checkIn = checkIn;
    this.checkOut = checkOut;
    this.roomResGuests = [];
    this.extraServices = null;
  }

  /*
   *  新增一个房间-住客信息的映射
   * */
  addNewRoomResGuest(referenceId, roomTypeName, bedTypeName) {
    let roomResGuest = new RoomResGuest(referenceId, roomTypeName, bedTypeName);
    this.roomResGuests.push(roomResGuest);
    return roomResGuest;
  }
}

/*
 * @param holdRS
 * */
function getRoomResGuestFormModel(holdRS) {
  if (
    holdRS.hotelReservationList == null ||
    holdRS.hotelReservationList.length == 0
  )
    return;

  let start = null;
  let end = null;
  let roomStays = [];
  let hotelReservation = holdRS.hotelReservationList[0];
  let resGlobalInfo = hotelReservation.resGlobalInfo;
  let timeSpan = resGlobalInfo.timeSpan;
  start = timeSpan.start;
  end = timeSpan.end;

  if (
    hotelReservation.roomStayList == null ||
    hotelReservation.roomStayList.length == 0
  )
    return;

  roomStays = hotelReservation.roomStayList;
  let roomResGuestInfoFormModel = new RoomResGuestInfoFormModel(start, end);
  let primaryGuestExist = false;
  roomStays.forEach((roomStay) => {
    let id = roomStay.reference.id;
    let roomResGuest = roomResGuestInfoFormModel.addNewRoomResGuest(id);
    // 房型信息相关
    const roomTypeOta = roomStay.roomTypes[0];
    const roomTypeName = roomTypeOta.roomType;
    const bedTypeName = roomTypeOta.bedTypeName;

    roomResGuest.roomTypeName = roomTypeName;
    roomResGuest.bedTypeName = bedTypeName;
    let mainGuestInRoomExist = false;
    let notExistPrimary = true;
    if (
      roomStay &&
      roomStay.guestCounts &&
      roomStay.guestCounts.guestCountList &&
      roomStay.guestCounts.guestCountList.length > 0
    ) {
      roomStay.guestCounts.guestCountList.forEach((guestCount) => {
        let ageQualifyingCode = guestCount.ageQualifyingCode;
        let resGuestRPH = guestCount.resGuestRPH;
        let age = guestCount.age;
        const resGuest = roomResGuest.addNewResGuest(
          !primaryGuestExist,
          resGuestRPH,
          ageQualifyingCode,
          age
        );
        //for default
        resGuest.nameTitle = "MR";

        if (!primaryGuestExist && ageQualifyingCode == "10") {
          primaryGuestExist = true;
          notExistPrimary = false;
        }
        //每个房间只需要填写一个人的名字
        if (
          notExistPrimary &&
          ageQualifyingCode == "10" &&
          !mainGuestInRoomExist
        ) {
          mainGuestInRoomExist = true;
          resGuest.mainIndicator = true;
        }
      });
    }
  });

  // console.info(roomResGuestInfoFormModel);
  return roomResGuestInfoFormModel;
}

function getRoomResGuestDetails(prebookRS) {
  if (
    prebookRS.hotelReservationList == null ||
    prebookRS.hotelReservationList.length == 0
  )
    return;

  let start = null;
  let end = null;
  let roomStays = [];
  let hotelReservation = prebookRS.hotelReservationList[0];
  let resGlobalInfo = hotelReservation.resGlobalInfo;
  let timeSpan = resGlobalInfo.timeSpan;
  start = timeSpan.start;
  end = timeSpan.end;

  if (
    hotelReservation.roomStayList == null ||
    hotelReservation.roomStayList.length == 0
  )
    return;

  let resGuests = hotelReservation.resGuestList;
  let resGuestMap = {};
  resGuests.forEach((resGuest) => {
    resGuestMap[resGuest.resGuestRPH] = resGuest;
  });
  roomStays = hotelReservation.roomStayList;
  let roomResGuestInfoFormModel = new RoomResGuestInfoFormModel(start, end);
  let primaryGuestExist = false;
  roomStays.forEach((roomStay) => {
    let id = roomStay.reference.id;
    let roomResGuest = roomResGuestInfoFormModel.addNewRoomResGuest(id);

    if (
      roomStay &&
      roomStay.guestCounts &&
      roomStay.guestCounts.guestCountList &&
      roomStay.guestCounts.guestCountList.length > 0
    ) {
      roomStay.guestCounts.guestCountList.forEach((guestCount) => {
        let ageQualifyingCode = guestCount.ageQualifyingCode;
        let resGuestRPH = guestCount.resGuestRPH;
        let age = guestCount.age;
        let resGuestInfo = resGuestMap[resGuestRPH];
        let resGuest = roomResGuest.addNewResGuest(
          !primaryGuestExist,
          resGuestRPH,
          ageQualifyingCode,
          age
        );
        if (resGuestInfo.surname != null) {
          resGuest.surname = resGuestInfo.surname;
          resGuest.givenName = resGuestInfo.givenName;
        }
        if (resGuestInfo.nameTitle != null) {
          resGuest.nameTitle = resGuestInfo.nameTitle;
        }
        if (resGuestInfo.emailAddress != null) {
          resGuest.emailAddress = resGuestInfo.emailAddress;
        }
        if (resGuestInfo.countryAccessCode) {
          resGuest.countryAccessCode = resGuestInfo.countryAccessCode;
        }
        if (resGuestInfo.phoneNumber) {
          resGuest.phoneNumber = resGuestInfo.phoneNumber;
        }
        if (!primaryGuestExist && ageQualifyingCode == "10") {
          primaryGuestExist = true;
          //console.info(resGuest);
        }
      });
    }
  });

  // console.info(roomResGuestInfoFormModel);
  return roomResGuestInfoFormModel;
}

function getHotelPassengerInfos(bookingRetrieveRS) {
  if (
    bookingRetrieveRS.totalElements === 0 ||
    bookingRetrieveRS.elements == null ||
    bookingRetrieveRS.elements.length === 0
  )
    return null;

  let element = bookingRetrieveRS.elements[0];
  let roomResGuestInfoFormModels = [];
  element.hotelReservations.forEach((hotelReservation) => {
    let start = null;
    let end = null;
    let roomStays = [];
    let resGlobalInfo = hotelReservation.resGlobalInfo;
    let timeSpan = resGlobalInfo.timeSpan;
    start = timeSpan.start;
    end = timeSpan.end;

    if (
      hotelReservation.roomStayList == null ||
      hotelReservation.roomStayList.length == 0
    )
      return;

    let resGuests = hotelReservation.resGuestList;
    let resGuestMap = {};
    resGuests.forEach((resGuest) => {
      resGuestMap[resGuest.resGuestRPH] = resGuest;
    });
    roomStays = hotelReservation.roomStayList;
    let roomResGuestInfoFormModel = new RoomResGuestInfoFormModel(start, end);
    roomStays.forEach((roomStay) => {
      const roomType = roomStay.roomTypes[0].roomType;
      const bedTypeName = roomStay.roomTypes[0].bedTypeName;
      let roomResGuest = roomResGuestInfoFormModel.addNewRoomResGuest(
        null,
        roomType,
        bedTypeName
      );
      let adultNum = 0;
      let childNum = 0;
      if (
        roomStay &&
        roomStay.guestCounts &&
        roomStay.guestCounts.guestCountList &&
        roomStay.guestCounts.guestCountList.length > 0
      ) {
        roomStay.guestCounts.guestCountList.forEach((guestCount) => {
          let ageQualifyingCode = guestCount.ageQualifyingCode;
          let resGuestRPH = guestCount.resGuestRPH;
          let age = guestCount.age;
          let resGuestInfo = resGuestMap[resGuestRPH];
          if (ageQualifyingCode == "8") {
            childNum++;
          } else if (ageQualifyingCode == "10") {
            adultNum++;
          }
          if (
            resGuestInfo.surname != null &&
            resGuestInfo.surname != "TBA" &&
            resGuestInfo.surname != "TBA-" + resGuestRPH
          ) {
            let resGuest = roomResGuest.addNewResGuest(
              resGuestInfo.primaryIndicator,
              resGuestRPH,
              ageQualifyingCode,
              age
            );

            if (resGuestInfo.nameTitle != null) {
              resGuest.nameTitle = resGuestInfo.nameTitle;
            }
            if (resGuestInfo.surname != null) {
              resGuest.surname = resGuestInfo.surname;
              resGuest.givenName = resGuestInfo.givenName;
            }
            if (resGuestInfo.emailAddress != null) {
              resGuest.emailAddress = resGuestInfo.emailAddress;
            }
            if (resGuestInfo.countryAccessCode) {
              resGuest.countryAccessCode = resGuestInfo.countryAccessCode;
            }
            if (resGuestInfo.phoneNumber) {
              resGuest.phoneNumber = resGuestInfo.phoneNumber;
            }
          }
        });
      }

      roomResGuest.adultNum = adultNum;
      roomResGuest.childNum = childNum;
    });
    //console.info(roomResGuestInfoFormModel);
    roomResGuestInfoFormModels.push(roomResGuestInfoFormModel);
  });

  return roomResGuestInfoFormModels[0];
}

function getBookingHotelPassengerInfos(bookingRetrieveRS) {
  if (
    bookingRetrieveRS.totalElements === 0 ||
    bookingRetrieveRS.elements == null ||
    bookingRetrieveRS.elements.length === 0
  )
    return null;

  let element = bookingRetrieveRS.elements[0];
  let bookingHotelPassengerInfos = [];
  element.hotelReservations.forEach((hotelReservation) => {
    let start = null;
    let end = null;
    let roomStays = [];

    if (
      hotelReservation.roomStayList == null ||
      hotelReservation.roomStayList.length == 0
    )
      return;

    let resGuests = hotelReservation.resGuestList;
    let resGuestMap = {};
    resGuests.forEach((resGuest) => {
      resGuestMap[resGuest.resGuestRPH] = resGuest;
    });
    roomStays = hotelReservation.roomStayList;
    roomStays.forEach((roomStay) => {
      if (
        roomStay &&
        roomStay.guestCounts &&
        roomStay.guestCounts.guestCountList &&
        roomStay.guestCounts.guestCountList.length > 0
      ) {
        roomStay.guestCounts.guestCountList.forEach((guestCount) => {
          let ageQualifyingCode = guestCount.ageQualifyingCode;
          let resGuestRPH = guestCount.resGuestRPH;
          let age = guestCount.age;
          let resGuestInfo = resGuestMap[resGuestRPH];
          if (ageQualifyingCode == "8") {
            childNum++;
          } else if (ageQualifyingCode == "10") {
            adultNum++;
          }
          if (
            resGuestInfo.surname != null &&
            resGuestInfo.surname != "TBA" &&
            resGuestInfo.surname != "TBA-" + resGuestRPH
          ) {
            let resGuest = roomResGuest.addNewResGuest(
              resGuestInfo.primaryIndicator,
              resGuestRPH,
              ageQualifyingCode,
              age
            );

            if (resGuestInfo.nameTitle != null) {
              resGuest.nameTitle = resGuestInfo.nameTitle;
            }
            if (resGuestInfo.surname != null) {
              resGuest.surname = resGuestInfo.surname;
              resGuest.givenName = resGuestInfo.givenName;
            }
            if (resGuestInfo.emailAddress != null) {
              resGuest.emailAddress = resGuestInfo.emailAddress;
            }
            if (resGuestInfo.countryAccessCode) {
              resGuest.countryAccessCode = resGuestInfo.countryAccessCode;
            }
            if (resGuestInfo.phoneNumber) {
              resGuest.phoneNumber = resGuestInfo.phoneNumber;
            }

            bookingHotelPassengerInfos.push(resGuest);
          }
        });
      }
    });
  });
}

function getRomGstFrmMdByAvail(roomViewItem, roomQuantity, extraServices) {
  let start = roomViewItem.checkIn;
  let end = roomViewItem.checkOut;

  let roomResGuestInfoFormModel = new RoomResGuestInfoFormModel(start, end);
  let primaryGuestExist = false;
  for (let i = 0; i < roomQuantity; i++) {
    let id = roomViewItem.referenceId;
    let roomResGuest = roomResGuestInfoFormModel.addNewRoomResGuest(id);

    roomViewItem.guestCounts.forEach((guestCount) => {
      let ageQualifyingCode = guestCount.ageQualifyingCode;
      let resGuestRPH = guestCount.resGuestRPH;
      let age = guestCount.age;
      roomResGuest.addNewResGuest(
        !primaryGuestExist,
        resGuestRPH,
        ageQualifyingCode,
        age
      );
      if (!primaryGuestExist && ageQualifyingCode == "10") {
        primaryGuestExist = true;
      }
    });
  }

  // console.info(roomResGuestInfoFormModel);
  roomResGuestInfoFormModel.extraServices = extraServices;
  return roomResGuestInfoFormModel;
}

//endregion

//region 构建订单显示对象
function getReservationRoomInfo(resWithReservation) {
  if (
    resWithReservation.hotelReservationList == null ||
    resWithReservation.hotelReservationList.length === 0
  )
    return;

  let hotelReservation = resWithReservation.hotelReservationList[0];
  let resGlobalInfo = hotelReservation.resGlobalInfo;
  let timeSpan = resGlobalInfo.timeSpan;
  let checkIn = timeSpan.start;
  let checkOut = timeSpan.end;
  let hotelName = null;
  let roomQuantity = 0;
  let refundable = false;
  let roomViewItems = [];
  let address = "";
  let roomTypeNames = [];
  let daysDuration = dateUtils.getDaysByDateRange(checkIn, checkOut);

  let roomStays = hotelReservation.roomStayList;
  roomQuantity = roomStays.length;
  let hotelNameExist = false;
  let propTextContent = null;
  let resGuests = hotelReservation.resGuestList;
  let resGuestMap = {};
  resGuests.forEach((resGuest) => {
    resGuestMap[resGuest.resGuestRPH] = resGuest;
  });
  let passengers = [];

  roomStays.forEach((roomStay) => {
    if (!hotelNameExist) {
      hotelNameExist = true;
      let basicPropertyInfo = roomStay.basicPropertyInfo;
      hotelName = basicPropertyInfo.hotelName;
      if (basicPropertyInfo.address) {
        address = basicPropertyInfo.address.addressString;
      }
      //酒店重要信息只初始化一次
      let vendorMessages = roomStay.basicPropertyInfo.vendorMessages;
      if (vendorMessages) {
        propTextContent = {};
        vendorMessages.forEach((vendorMessage) => {
          let infoType = vendorMessage.infoType;
          if (vendorMessage.subSections) {
            let paragraphs = vendorMessage.subSections[0].paragraphs;
            propTextContent[infoType] = paragraphs;
          }
        });
      }
    }

    const roomTypeOta = roomStay.roomTypes[0];
    let roomTypeName = roomTypeOta.roomType;
    roomTypeNames.push(roomTypeName);
    const guestCounts =
      roomStay && roomStay.guestCounts
        ? roomStay.guestCounts.guestCountList
        : [];

    let roomViewItem = getRoomViewItem(roomStay);
    refundable = roomViewItem.refundable;
    let resGuestRPHs = [];
    if (guestCounts && guestCounts.length > 0) {
      for (const guestCount of guestCounts) {
        const resGuestRPH = guestCount.resGuestRPH;
        resGuestRPHs.push(resGuestRPH);
      }
    }
    roomViewItem.resGuestRPHs = resGuestRPHs;
    roomViewItems.push(roomViewItem);

    //住客信息
    if (
      roomStay &&
      roomStay.guestCounts &&
      roomStay.guestCounts.guestCountList &&
      roomStay.guestCounts.guestCountList.length > 0
    ) {
      roomStay.guestCounts.guestCountList.forEach((guestCount) => {
        let ageQualifyingCode = guestCount.ageQualifyingCode;
        let resGuestRPH = guestCount.resGuestRPH;
        let age = guestCount.age;
        let resGuestInfo = resGuestMap[resGuestRPH];
        // if (ageQualifyingCode == "8") {
        //   childNum++;
        // } else if (ageQualifyingCode == "10") {
        //   adultNum++;
        // }
        if (
          resGuestInfo.surname != null &&
          resGuestInfo.surname !== "TBA" &&
          resGuestInfo.surname !== "TBA-" + resGuestRPH
        ) {
          let resGuest = new ResGuest(
            resGuestInfo.primaryIndicator,
            age,
            ageQualifyingCode,
            resGuestRPH,
            null,
            null,
            null,
            null,
            null,
            null,
            null
          );

          if (resGuestInfo.nameTitle != null) {
            resGuest.nameTitle = resGuestInfo.nameTitle;
          }
          if (resGuestInfo.surname != null) {
            resGuest.surname = resGuestInfo.surname;
            resGuest.givenName = resGuestInfo.givenName;
          }
          if (resGuestInfo.emailAddress != null) {
            resGuest.emailAddress = resGuestInfo.emailAddress;
          }
          if (resGuestInfo.countryAccessCode) {
            resGuest.countryAccessCode = resGuestInfo.countryAccessCode;
          }
          if (resGuestInfo.phoneNumber) {
            resGuest.phoneNumber = resGuestInfo.phoneNumber;
          }

          passengers.push(resGuest);
        }
      });
    }
  });

  const serviceList = hotelReservation.serviceList;
  let extraServices = null;
  if (serviceList && serviceList.length > 0) {
    extraServices = [];
    serviceList.forEach((service) => {
      const serviceDescription = service.serviceDetails.serviceDescription;
      const supplementName = serviceDescription.name;
      const ratePlanCode = service.ratePlanCode;
      const quantity = service.quantity;
      const id = service.id;
      const amountIncludingMarkup = service.total.amountIncludingMarkup;
      const currencyCode = service.total.currencyCode;
      const chargeType = service.prices[0].chargeType;

      let supplement = {
        productName: supplementName,
        chargeTypeName: chargeType,
        amount: amountIncludingMarkup,
        quantity: quantity,
        currency: currencyCode,
        productCode: id,
        ratePlanCode: ratePlanCode,
      };
      if (extraServices[parseInt(service.serviceRPH)]) {
        extraServices[parseInt(service.serviceRPH)].supplements.push(
          supplement
        );
      } else {
        extraServices[parseInt(service.serviceRPH)] = {
          supplements: [],
        };
        extraServices[parseInt(service.serviceRPH)].supplements.push(
          supplement
        );
      }
    });
    //console.info(extraServices);
  }

  let hotelRoomBasicInfoView = new HotelRoomBasicInfoView(
    hotelName,
    address,
    roomQuantity,
    roomTypeNames.toString(),
    refundable,
    checkIn,
    checkOut,
    daysDuration,
    roomViewItems,
    propTextContent,
    extraServices,
    passengers
  );
  return hotelRoomBasicInfoView;
}

class HotelRoomBasicInfoView {
  constructor(
    hotelName,
    address,
    roomQuantity,
    roomTypeNames,
    refundable,
    checkIn,
    checkOut,
    daysDuration,
    roomViewItems,
    propTextContent,
    extraServices,
    passengers,
    itemId
  ) {
    this.hotelName = hotelName;
    this.address = address;
    this.roomQuantity = roomQuantity;
    this.roomTypeNames = roomTypeNames;
    this.refundable = refundable;
    this.checkIn = checkIn;
    this.checkOut = checkOut;
    this.daysDuration = daysDuration;
    this.roomViewItems = roomViewItems;
    this.propTextContent = propTextContent;
    this.extraServices = extraServices;
    this.passengers = passengers;
    this.itemId = itemId;
  }
}

//endregion

export default {
  getHotelAvailRQ,
  getDescriptiveRQ,
  getHoldRQ,
  getPreBookRQ,
  getBookRQ,
  getHotelImages,
  getRomGstFrmMdByAvail,
  getRoomResGuestFormModel,
  getHotelViewItemMap,
  getRoomTypeViewItem,
  getRoomViewItem,
  getSortedHotelViewItems,
  getReservationRoomInfo,
  getRoomResGuestDetails,
  getHotelPassengerInfos,
  FilterOption,
  SortingOption,
  HotelViewItem,
  RoomPassenger,
  ResGuest,
  RoomResGuest,
  HotelRoomBasicInfoView,
  RoomPassengerFormModel,
  HotelSearchFormModel,
  RoomResGuestInfoFormModel,
};
