const sortOptions = [
  {
    name: "Price(Lowest)",
    label: "price-lowest",
    type: "PRICE",
    order: "ASC",
  },
  {
    name: "Price(Highest)",
    label: "price-highest",
    type: "PRICE",
    order: "DSC",
  },
  {
    name: "User Rating(Lowest)",
    label: "user-rating-lowest",
    type: "USER_RATING",
    order: "ASC",
  },
  {
    name: "User Rating(Highest)",
    label: "user-rating-highest",
    type: "USER_RATING",
    order: "DSC",
  },
  {
    name: "Star Rating(Lowest)",
    label: "star-rating-lowest",
    type: "RATING",
    order: "ASC",
  },
  {
    name: "Star Rating(Highest)",
    label: "star-rating-highest",
    type: "RATING",
    order: "DSC",
  },
  {
    name: "Hotel Name(A-Z)",
    label: "hotel-name-lowest",
    type: "NAME",
    order: "ASC",
  },
  {
    name: "Hotel Name(Z-A)",
    label: "hotel-name-highest",
    type: "NAME",
    order: "DSC",
  },
];

export default {
  sortOptions,
};
