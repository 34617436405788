<script setup>
import { ref, watch } from "vue";

import "vue3-tel-input/dist/vue3-tel-input.css";
import useVuelidate from "@vuelidate/core";
import contentApi from "@/apis/contentApi";

const props = defineProps({
  profileBasicInformationFormModal: {},
});

const emits = defineEmits([]);
const profileBasicInformationFormModalRef = ref(null);
const countriesRef = ref(null);

watch(
  () => props.profileBasicInformationFormModal,
  (newValue) => {
    profileBasicInformationFormModalRef.value = newValue;
  },
  { immediate: true, deep: true }
);

let rules = {
  // surname: { surnameRule },
  // givenName: { givenNameRule },
};

const v = useVuelidate(rules, profileBasicInformationFormModalRef);

function getCountries(keyword) {
  document.getElementById("country-dropdown-menu").classList.add("show");
  contentApi.countries(keyword).then((res) => {
    countriesRef.value = res.data;
  });
}

function selectCountry(country, menuId) {
  // partyBasicInfoFormModelRef.value.birthPlaceCountryId = country.code;
  // partyBasicInfoFormModelRef.value.birthPlaceCountry = country.name;
  document.getElementById(menuId).classList.remove("show");
}

function modifyTravelDocuments(profileBasicInformationFormModal) {
  emits("modifyTravelDocuments", profileBasicInformationFormModal);
}
</script>

<template>
  <form
    action="!#"
    id="profile_form_area"
    @submit.prevent="modifyTravelDocuments()"
  >
    <h3>Account support</h3>
    <div class="font-14">
      Messages about your account, password recovery, and policy updates.
    </div>
    <div class="row mg-t-20">
      <div class="col-lg-12">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            :id="'example-radios-default' + 1"
            :name="'example-radios-default' + 1"
          />
          <label class="form-check-label" :for="'example-radios-default' + 1">
            <div>Email</div>
            <div class="text-muted font-14">
              You can't unsubscribe from emails critical to your bookings,
              account activity, and rewards programme.
            </div>
          </label>
        </div>
      </div>
    </div>

    <div class="col-lg-12" align="center">
      <button class="btn btn_theme">Update</button>
    </div>
  </form>
</template>

<style scoped></style>
