<script setup>
import FlightAncillaryServiceMenuPanel from "@/views/v2/sales/flight/items/panel/ancillaryservice/FlightAncillaryServiceMenuPanel.vue";
import { useRoute } from "vue-router";
import { useFlightAncillaryServiceStore } from "@/stores/v2/flight/ancillaryservice/flightAncillaryServiceStore";
import { useTemplateStore } from "@/stores/template";
import { ref, watch } from "vue";
import flightAncillaryServiceTools from "@/tools/v2/flight/ancillaryservice/flightAncillaryServiceTools";
import FlightAncillaryServiceItemOperationPanel from "@/views/v2/sales/flight/items/panel/ancillaryservice/FlightAncillaryServiceItemOperationPanel.vue";
import { Empty, Modal } from "ant-design-vue";
import FlightChangeAncillaryServiceSelectedPanel from "@/views/v2/trip/servicing/items/panel/FlightChangeAncillaryServiceSelectedPanel.vue";
import { FlightModifyFormModel } from "@/formmodel/sales/flight/modify/flightModifyFormModel";
import { TripAirItemPaymentDetailVO } from "@/viewobject/trip/flight/payment/tripAirItemPaymentDetailVO";
import FlightServicesPriceSummaryPanel from "@/views/v2/sales/flight/items/panel/FlightServicesPriceSummaryPanel.vue";
import { useAirContentStore } from "@/stores/v2/content/airContentStore";
import { useI18n } from "vue-i18n";

const route = useRoute();
const props = defineProps({
  tripAirItem: {},
  passengers: {},
  flightRePriceFormModel: {},
  recordLocator: {},
  idContext: {},
  tripId: {},
  tripItemId: {},
  tripAirItemId: {
    default: null,
  },
  isFromTripList: {},
});
const { t, locale } = useI18n(); // 解构调用函数

const AEmpty = Empty;
const AModal = Modal;
const emits = defineEmits(["closeAncillaryService"]);
const templateStore = useTemplateStore();
const airContentStore = useAirContentStore();
const ancillaryServiceStore = useFlightAncillaryServiceStore();

const tripAirItemRef = ref(null);
const flightRePriceFormModelRef = ref(null);
const ancillaryOptionsRef = ref(null);
const ancillaryServiceMenuRef = ref(null);

const tripSegmentsRef = ref([]);
const currentSegmentRef = ref({});
const currentPassengerRef = ref({});

const paxSegmentAncillaryOptionsRef = ref(null);

const currentServiceToSelectedRef = ref({});
const serviceOperationFormShowRef = ref(false);
const airlineCapabilityRef = ref([]);

const flightModifyFormModelRef = ref(
  new FlightModifyFormModel(
    null,
    props.tripId,
    props.tripAirItemId,
    null,
    props.recordLocator,
    props.idContext,
    null,
    new TripAirItemPaymentDetailVO(
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null
    )
  )
);

watch(
  () => props.passengers,
  (newValue) => {
    if (newValue && newValue.length > 0) {
      currentPassengerRef.value = newValue[0];
    }
  },
  { deep: true, immediate: true }
);
watch(
  () => props.tripAirItem,
  (newValue) => {
    tripAirItemRef.value = newValue;
    tripSegmentsRef.value = [];
    if (newValue && newValue.flightItems && newValue.flightItems.length > 0) {
      newValue.flightItems.forEach((item, index) => {
        tripSegmentsRef.value.push(...item.flightSegments);
        if (index === 0)
          currentSegmentRef.value =
            item.flightSegments && item.flightSegments.length > 0
              ? item.flightSegments[0]
              : {};
      });
    }

    if (tripAirItemRef.value) {
      //1.获取airline capability
      // if (tripAirItemRef.value.supplierCode) {
      //   airContentApi
      //     .airlineCapability(tripAirItemRef.value.supplierCode)
      //     .then((res) => {
      //       if (res && res.data) {
      //         airlineCapabilityRef.value = res.data;
      //       }
      //     });
      // }

      //2.将所有选择的附加服务放到flightModifyFormModel中传给需要操作修改附加服务费组件中
      const tripAirItemFare = tripAirItemRef.value.tripAirItemFare;
      if (tripAirItemFare && tripAirItemFare.ancillaryFares) {
        if (tripAirItemFare.ancillaryFares.length > 0) {
          flightModifyFormModelRef.value.servicesSelected =
            tripAirItemFare.ancillaryFares.filter(
              (item) => item.code !== "189C" && item.type !== "SEAT"
            );
        }
      }
    }
  },
  { deep: true, immediate: true }
);
watch(
  () => props.flightRePriceFormModel,
  (newValue) => {
    flightRePriceFormModelRef.value = newValue;
  },
  { deep: true, immediate: true }
);

watch(
  () => ancillaryServiceStore.ancillaryServiceMenu,
  (newValue) => {
    ancillaryServiceMenuRef.value = newValue;
  },
  { immediate: true, deep: true }
);

watch(
  () => ancillaryServiceStore.ancillaryOptions,
  (newValue) => {
    if (newValue && newValue.length > 0) {
      ancillaryOptionsRef.value = newValue.filter(
        (item) => item.type !== "SEAT" && item.code !== "189C"
      );
    }

    if (ancillaryOptionsRef.value && ancillaryOptionsRef.value.length > 0) {
      paxSegmentAncillaryOptionsRef.value =
        flightAncillaryServiceTools.findSpecificAncillaryOptions(
          ancillaryOptionsRef.value,
          null,
          null,
          currentSegmentRef.value.rph,
          currentPassengerRef.value.travelerRefNumber
        );
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => ancillaryServiceStore.currentTaxonomyCode,
  (newValue) => {
    //点击菜单项，更新options显示
    paxSegmentAncillaryOptionsRef.value =
      flightAncillaryServiceTools.findSpecificAncillaryOptions(
        ancillaryOptionsRef.value,
        "SERVICE",
        newValue,
        currentSegmentRef.value.rph,
        currentPassengerRef.value.travelerRefNumber
      );
  }
);

function closeAncillaryService() {
  emits("closeAncillaryService");
}

//切换当前航段和乘客
function changeSegmentAndPassenger(
  segmentRPH,
  travelerRPH,
  currentTaxonomyCode
) {
  paxSegmentAncillaryOptionsRef.value =
    flightAncillaryServiceTools.findSpecificAncillaryOptions(
      ancillaryOptionsRef.value,
      "SERVICE",
      currentTaxonomyCode,
      segmentRPH,
      travelerRPH
    );
}

function chooseServiceToSelected(service, servicesSelectedOld) {
  //如果当前要选择的服务类型已经选择过，提示先删除再选择
  let contain = false;
  if (servicesSelectedOld && servicesSelectedOld.length > 0) {
    contain = servicesSelectedOld.some(
      (item) =>
        item.code === service.code &&
        item.flightSegmentRPH === service.flightSegmentRPH
    );
  }
  if (!contain) {
    currentServiceToSelectedRef.value = service;
    serviceOperationFormShowRef.value = true;
  } else {
    AModal.warning({
      title: t("this-is-an-error-message"),
      content: t("segment-already-contains-the-same-type-additional-services"),
      centered: true,
      width: 600,
      wrapClassName: "confirmModal",
      onOk() {
        return new Promise((resolve, reject) => {}).catch(() =>
          console.log("Oops errors!")
        );
      },
    });
  }
}

function closeOperationForm() {
  serviceOperationFormShowRef.value = false;
  currentServiceToSelectedRef.value = {};
}
</script>

<template>
  <h3>{{ $t("manage-ancillary-service") }}</h3>
  <!--  <div class="text-muted font-18">-->
  <!--    {{ $t("flexibility-your-own-flying-experience") }}-->
  <!--  </div>-->

  <div class="row change-ancillary_service">
    <!-- 乘客和航段信息 -->
    <div class="col-12 font-16 p-2">
      <div class="row">
        <div class="col-2">
          <FlightAncillaryServiceMenuPanel :nodes="ancillaryServiceMenuRef" />
        </div>
        <div class="col-6">
          <div class="row">
            <!-- 当前航段和乘客概览 -->
            <div
              v-if="currentSegmentRef && currentPassengerRef"
              class="col-12 mg-b-10"
            >
              <h6 v-if="currentSegmentRef.departureDateTime">
                <span v-if="currentSegmentRef.departureDateTime">
                  {{ currentSegmentRef.departureDateTime.split("T")[0] }}
                </span>

                <span class="mg-l-10"
                  >{{ currentSegmentRef.departureAirportCode }}
                  <i class="bi bi-arrow-right main_color"></i>
                  {{ currentSegmentRef.arrivalAirportCode }}</span
                >

                <span class="mg-l-10">
                  {{ currentSegmentRef.marketingAirlineCode
                  }}{{ currentSegmentRef.flightNumber }}
                </span>

                <span class="mg-l-10">
                  {{ $t("passenger") }}
                  {{ currentPassengerRef.travelerRefNumber }} ({{
                    currentPassengerRef.passengerTypeCode
                  }})
                </span>
              </h6>
            </div>
            <!-- 当前航段和乘客概览END -->

            <!-- 航段和乘客选择 -->
            <div
              v-if="tripSegmentsRef && tripSegmentsRef.length > 0"
              class="col-4"
            >
              <label class="form-label" for="example-text-input"
                >{{ $t("current-segment") }}
                <small class="text-lowercase"><code>*</code></small></label
              >
              <select
                v-model="currentSegmentRef"
                aria-label="Floating label select example"
                class="form-select"
                @change="
                  changeSegmentAndPassenger(
                    currentSegmentRef.rph,
                    currentPassengerRef.travelerRefNumber,
                    ancillaryServiceStore.currentTaxonomyCode
                  )
                "
              >
                <option
                  v-for="(segment, segIndex) in tripSegmentsRef"
                  :key="segIndex"
                  :selected="segIndex === 0"
                  :value="segment"
                >
                  {{ $t("segment") }} {{ segIndex + 1 }} :
                  {{ segment.departureAirportCode }}-{{
                    segment.arrivalAirportCode
                  }}
                </option>
              </select>
            </div>
            <div class="col-4" v-if="passengers && passengers.length > 0">
              <label class="form-label" for="example-text-input"
                >{{ $t("current-passenger") }}
                <small class="text-lowercase"><code>*</code></small></label
              >
              <select
                v-model="currentPassengerRef"
                aria-label="Floating label select example"
                class="form-select"
                @change="
                  changeSegmentAndPassenger(
                    currentSegmentRef.rph,
                    currentPassengerRef.travelerRefNumber,
                    ancillaryServiceStore.currentTaxonomyCode
                  )
                "
              >
                <option
                  v-for="(passenger, psIndex) in passengers"
                  :key="psIndex"
                  :value="passenger"
                >
                  {{ $t("passenger") }} {{ psIndex + 1 }} :
                  {{ passenger.travelerRefNumber }} -
                  {{ passenger.passengerTypeCode }}
                </option>
              </select>
            </div>
            <!-- 航段和乘客选择END -->

            <!-- 附加服务选择区域 -->
            <div class="col-12 mg-t-20">
              <div
                v-if="
                  paxSegmentAncillaryOptionsRef &&
                  paxSegmentAncillaryOptionsRef.length > 0
                "
                class="row"
              >
                <div
                  v-for="(
                    service, serviceIndex
                  ) in paxSegmentAncillaryOptionsRef"
                  :key="serviceIndex"
                  class="col-4 d-md-flex"
                >
                  <BaseBlock content-full style="width: 100%">
                    <template #content>
                      <img
                        v-if="service.imageUrl"
                        :src="service.imageUrl"
                        alt=""
                        class="img-fluid"
                      />
                      <div
                        v-else
                        style="height: 158px; width: 100%; background: #f0f0f0"
                      />
                      <div class="block-content">
                        <div class="font_weight mg-b-5">
                          {{ service.name }}
                        </div>
                        <div class="flex justify-content-between">
                          <div class="text-primary">
                            {{ service.currencyCode }} {{ service.amount }}
                          </div>
                          <div
                            :data-bs-target="
                              '#collapseExample' + 'C' + serviceIndex
                            "
                            aria-controls="collapseExample"
                            aria-expanded="false"
                            class="cursor-point font-14 text-primary"
                            data-bs-toggle="collapse"
                            v-if="
                              service.descriptions &&
                              service.descriptions.length > 0
                            "
                          >
                            {{ $t("see-descriptions") }}
                            <i class="fas fa-chevron-down"></i>
                          </div>
                        </div>

                        <div
                          v-for="(description, index) in service.descriptions"
                          :id="'collapseExample' + 'C' + serviceIndex"
                          :key="index"
                          class="fs-sm text-muted collapse"
                        >
                          <span v-if="description.name">
                            {{ description.name }} -
                            {{ description.text }}</span
                          >
                          <span v-else> {{ description.text }}</span>
                        </div>
                        <div class="text-center">
                          <button
                            class="btn btn-outline-primary mb-2 mg-t-20"
                            @click="
                              chooseServiceToSelected(
                                service,
                                flightModifyFormModelRef.servicesSelected
                              )
                            "
                          >
                            {{ $t("choose") }}
                          </button>
                        </div>
                      </div>
                    </template>
                  </BaseBlock>
                </div>
              </div>
              <div v-else>
                <AEmpty :description="$t('no-services')"></AEmpty>
              </div>
            </div>
            <!-- 附加服务选择区域 END-->
          </div>
        </div>
        <div class="col-4">
          <div class="service-selected-price-summary">
            <!--新选择的附加服务-->
            <h6 class="text-bg-secondary p-2">{{ $t("selection") }}</h6>
            <FlightChangeAncillaryServiceSelectedPanel
              :flight-re-price-form-model="flightRePriceFormModelRef"
              :segments="tripSegmentsRef"
              :passengers="passengers"
              :services-selected="flightRePriceFormModelRef.servicesSelected"
              :trip-air-item="tripAirItem"
              :airline-capability="airlineCapabilityRef"
              @closeServicesModalPanel="closeAncillaryService"
            />
            <!--新选择的附加服务END-->
          </div>
          <hr />
          <!--已选择的附加服务-->
          <h6 class="text-bg-secondary p-2">
            {{ $t("my-additional-services") }}
          </h6>
          <FlightServicesPriceSummaryPanel
            v-if="
              flightModifyFormModelRef.servicesSelected &&
              flightModifyFormModelRef.servicesSelected.length > 0
            "
            :form-model="flightModifyFormModelRef"
            :trip-air-item="tripAirItemRef"
            :is-reservation-page="true"
            :passenger-items="tripAirItemRef.passengers"
            :airline-capability="airlineCapabilityRef"
            :show-title="false"
            :filter-seat="true"
          />
          <div v-else>
            <AEmpty :description="$t('no-services')"></AEmpty>
          </div>

          <!--已选择的附加服务END-->
        </div>
      </div>
    </div>
  </div>

  <!--  service operation content-->
  <a-modal
    v-model:open="serviceOperationFormShowRef"
    :cancel-button-props="{ style: { display: 'none' } }"
    :closable="false"
    :maskClosable="false"
    :ok-button-props="{ style: { display: 'none' } }"
    :z-index="12222"
    centered
    width="35%"
  >
    <FlightAncillaryServiceItemOperationPanel
      :current-passenger="currentPassengerRef"
      :current-segment="currentSegmentRef"
      :flight-price-form-model="flightRePriceFormModelRef"
      :service="currentServiceToSelectedRef"
      :airline-item-map="airContentStore.airlineItemMap"
      @closeOperationForm="closeOperationForm"
    />
  </a-modal>
  <!--  service operation content END-->
</template>

<style lang="scss" scoped></style>
