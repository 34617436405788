import { FlightPriceClassVO } from "@/viewobject/sales/flight/shopping/flightPriceClassVO";
import { FlightSegmentVO } from "@/viewobject/sales/flight/shopping/flightSegmentVO";
import { TripAirItemVO } from "@/viewobject/trip/flight/tripAirItemVO";
import { FlightItemVO } from "@/viewobject/sales/flight/shopping/flightItemVO";
import { TripAirItemPassengerTypeFareVO } from "@/viewobject/trip/flight/passenger/tripAirItemPassengerTypeFareVO";
import { TripAirItemFareVO } from "@/viewobject/trip/flight/tripAirItemFareVO";
import { TripAirItemAncillaryFareVO } from "@/viewobject/trip/flight/ancillary/tripAirItemAncillaryFareVO";
import flightAncillaryServiceTools from "@/tools/v2/flight/ancillaryservice/flightAncillaryServiceTools";
import TripAirItemPassengerVO from "@/viewobject/trip/flight/passenger/tripAirItemPassengerVO";
import { TripPassengerEmailVO } from "@/viewobject/trip/common/tripPassengerEmailVO";
import { TripPassengerPhoneVO } from "@/viewobject/trip/common/tripPassengerPhoneVO";
import { TripPassengerDocumentVO } from "@/viewobject/trip/common/tripPassengerDocumentVO";
import { TripAirItemPaymentDetailVO } from "@/viewobject/trip/flight/payment/tripAirItemPaymentDetailVO";
import { TripAirItemTicketingVO } from "@/viewobject/trip/flight/ticketing/tripAirItemTicketingVO";

//region Trip Air Item
function buildTripAirItem(pricedItinerary) {
  if (!pricedItinerary) return;

  const approvalRequired = pricedItinerary.approvalRequired;
  const outOfPolicy = pricedItinerary.outOfPolicy;
  const upgradeAllowed = pricedItinerary.upgradeAllowed;
  const reasonRequired = pricedItinerary.reasonRequired;
  const reasons = pricedItinerary.reasons;
  const statusId =
    pricedItinerary.statusId === "BOOKING_ITEM_CANCELLED"
      ? "CANCELLED"
      : pricedItinerary.statusId;

  const bookingReferenceIdList = pricedItinerary.bookingReferenceIdList;
  const airItinerary = pricedItinerary.airItinerary;
  const priceInfo = pricedItinerary.priceInfo;
  const travelerInfo = pricedItinerary.travelerInfo;
  const fulfillment = pricedItinerary.fulfillment;
  const ticketing = pricedItinerary.ticketing;
  const tpaExtensions = pricedItinerary.tpaExtensions;
  const airItineraryKey = airItinerary.airItineraryKey;
  const airItineraryRPH = airItinerary.airItineraryRPH;
  const originDestinationOptions = airItinerary.originDestinationOptions;
  const offer = pricedItinerary.offer;
  const airItineraryPricingInfo = pricedItinerary.airItineraryPricingInfo;
  let quoteID = null;
  let supplierCode = null;
  if (airItineraryPricingInfo) {
    quoteID = airItineraryPricingInfo.quoteID;
    supplierCode =
      airItineraryPricingInfo.priceRequestInformation &&
      airItineraryPricingInfo.priceRequestInformation.negotiatedFareCodeList
        ? airItineraryPricingInfo.priceRequestInformation
            .negotiatedFareCodeList[0].supplierCode
        : airItineraryPricingInfo.pricingSource;
  }

  const bookingSourceType = pricedItinerary.bookingSourceType;

  let tripAirItemId = null;
  let tripItemId = null;
  let recordLocator = null;
  let idContext = null;
  if (bookingReferenceIdList && bookingReferenceIdList.length > 0) {
    for (const bookingReferenceId of bookingReferenceIdList) {
      const idType = bookingReferenceId.type;
      const id = bookingReferenceId.id;
      if (idType === "RECORD_LOCATOR") {
        recordLocator = id;
        idContext = bookingReferenceId.id_context;
      } else if (idType === "BOOKING_ITEM_ID") {
        tripItemId = id;
      } else if (idType === "BOOKING_AIR_ITEM_ID") {
        tripAirItemId = id;
      }
    }
  }

  let ssrRemarks = null;
  if (pricedItinerary.tpaExtensions && pricedItinerary.tpaExtensions.valueMap) {
    ssrRemarks = pricedItinerary.tpaExtensions.valueMap["specialRemarks"];
  }

  const tripAirItemVO = new TripAirItemVO(
    statusId,
    tripItemId,
    tripAirItemId,
    recordLocator,
    idContext,
    airItineraryRPH,
    airItineraryKey,
    quoteID,
    bookingSourceType,
    supplierCode,
    outOfPolicy,
    approvalRequired,
    upgradeAllowed,
    reasonRequired,
    reasons,
    null,
    null,
    null,
    null,
    null,
    null,
    ssrRemarks
  );

  //Passengers
  if (
    travelerInfo &&
    travelerInfo.airTravelerList &&
    travelerInfo.airTravelerList.length > 0
  ) {
    const airTravelerList = travelerInfo.airTravelerList;
    for (const airTraveler of airTravelerList) {
      const tripAirItemPassengerVO = buildPassenger(airTraveler);
      tripAirItemVO.addPassenger(tripAirItemPassengerVO);
    }
  }

  if (
    fulfillment &&
    fulfillment.paymentDetailList &&
    fulfillment.paymentDetailList.length > 0
  ) {
    const paymentDetailList = fulfillment.paymentDetailList;
    for (const paymentDetail of paymentDetailList) {
      const paymentType = paymentDetail.paymentType;
      const paymentAmount = paymentDetail.paymentAmount;
      const currencyCode = paymentAmount.currencyCode;
      const amount = paymentAmount.amount;
      const paymentCard = paymentDetail.paymentCard;
      const cardCode = paymentCard ? paymentCard.cardCode : null;
      const remark = paymentCard ? paymentCard.remark : null;
      const cardNumber = paymentCard ? paymentCard.cardNumber : null;

      const tripAirItemPaymentDetailVO = new TripAirItemPaymentDetailVO(
        null,
        paymentType,
        cardCode,
        cardNumber,
        null,
        remark,
        currencyCode,
        amount
      );
      tripAirItemVO.addPaymentDetail(tripAirItemPaymentDetailVO);
    }
  }

  if (ticketing && ticketing.length > 0) {
    for (const ticketingEle of ticketing) {
      const flightSegmentRefNumbers = ticketingEle.flightSegmentRefNumber;
      const travelerRefNumber = ticketingEle.travelerRefNumber;
      const ticketTimeLimit = ticketingEle.ticketTimeLimit;
      const serviceReference = ticketingEle.serviceReference;
      const ticketDocumentNbr = ticketingEle.ticketDocumentNbr;
      const ticketType = ticketingEle.ticketType;
      const requestedTicketingDate = ticketingEle.requestedTicketingDate;
      const ticketingVendor = ticketingEle.ticketingVendor
        ? ticketingEle.ticketingVendor.code
        : null;
      const status = ticketTimeLimit != null ? "UNTICKETED" : "TICKETED";
      const tripAirItemTicketingVO = new TripAirItemTicketingVO(
        status,
        flightSegmentRefNumbers,
        serviceReference,
        ticketDocumentNbr,
        ticketType,
        travelerRefNumber,
        requestedTicketingDate,
        ticketingVendor,
        ticketTimeLimit
      );

      tripAirItemVO.addTicketing(tripAirItemTicketingVO);
    }
  }

  if (
    tpaExtensions &&
    tpaExtensions.valueMap &&
    tpaExtensions.valueMap.ticketRemarks
  ) {
    tripAirItemVO.ticketingRemarks = tpaExtensions.valueMap.ticketRemarks;
  }

  //Air Item Fare
  const tripAirItemFareVO = buildTripAirItemFareVO(
    airItineraryPricingInfo,
    priceInfo,
    offer
  );
  tripAirItemVO.addTripAirItemFare(tripAirItemFareVO);

  if (!originDestinationOptions) return tripAirItemVO;

  //Flight Items
  let tripSequenceNumber = 0;
  for (const originDestinationOption of originDestinationOptions) {
    const flightItemVO = buildFlightItemVO(
      originDestinationOption,
      airItineraryKey,
      tripSequenceNumber,
      offer
    );
    tripAirItemVO.addFlightItem(flightItemVO);

    tripSequenceNumber++;
  }

  tripAirItemVO.flightItems.sort((a, b) => {
    const result =
      new Date(a.departureDateTime).getTime() -
      new Date(b.departureDateTime).getTime();
    return result;
  });

  return tripAirItemVO;
}

function buildFlightSegmentVO(flightSegment) {
  const departureDateTime = flightSegment.departureDateTime;
  const arrivalDateTime = flightSegment.arrivalDateTime;
  const segmentKey = flightSegment.segmentKey;
  const flightNumber = flightSegment.flightNumber;
  const fareBasisCode = flightSegment.fareBasisCode;
  const fareTypeName = flightSegment.fareTypeName;
  const resBookDesigCode = flightSegment.resBookDesigCode;
  const numberInParty = flightSegment.numberInParty;
  const stopoverInd = flightSegment.stopoverInd;
  const departureAirportCode = flightSegment.departureAirportCode;
  const arrivalAirportCode = flightSegment.arrivalAirportCode;
  const operatingAirlineCode = flightSegment.operatingAirlineCode;
  const operatingAirlineName = flightSegment.operatingAirlineName;
  const equipmentCode = flightSegment.equipmentCode;
  const marketingAirlineCode = flightSegment.marketingAirlineCode;
  const cabinClass = flightSegment.cabinClass;
  const baggageAllowance = flightSegment.baggageAllowance;
  const rph = flightSegment.rph;

  return new FlightSegmentVO(
    departureDateTime,
    arrivalDateTime,
    segmentKey,
    flightNumber,
    fareBasisCode,
    fareTypeName,
    resBookDesigCode,
    numberInParty,
    stopoverInd,
    departureAirportCode,
    arrivalAirportCode,
    operatingAirlineCode,
    operatingAirlineName,
    equipmentCode,
    marketingAirlineCode,
    cabinClass,
    baggageAllowance,
    rph
  );
}

function buildFlightItemVO(
  originDestinationOption,
  airItineraryKey,
  tripSequenceNumber,
  offer
) {
  const originLocationCode = originDestinationOption.originLocation;
  const destinationLocationCode = originDestinationOption.destinationLocation;
  const connections = originDestinationOption.connections;
  const numStops = originDestinationOption.numStops;
  const durationInMins = originDestinationOption.durationInMins;
  const originDestinationKey = originDestinationOption.originDestinationKey;
  const flightSegments = originDestinationOption.flightSegments;
  const originDestRPH = originDestinationOption.rph;
  let priceClassId = null;
  if (offer && offer.summary && offer.summary.length > 0) {
    for (const summaryEle of offer.summary) {
      if (summaryEle.origDestRPH === originDestRPH)
        priceClassId = summaryEle.bundleID;
    }
  }

  let originLocationName = null;
  let departureAirportCodeFlight = null;
  let destinationLocationName = null;
  let arrivalAirportCodeFlight = null;
  let departureDateTimeFlight = null;
  let arrivalDateTimeFlight = null;
  let currencyCode = null;
  let bestTotalPrice = null;

  const flightSegmentVOs = [];
  for (const flightSegment of flightSegments) {
    const flightSegmentVO = buildFlightSegmentVO(flightSegment);
    flightSegmentVOs.push(flightSegmentVO);

    if (!departureDateTimeFlight) {
      departureDateTimeFlight = flightSegmentVO.departureDateTime;
      departureAirportCodeFlight = flightSegmentVO.departureAirportCode;
    }
    arrivalDateTimeFlight = flightSegmentVO.arrivalDateTime;
    arrivalAirportCodeFlight = flightSegmentVO.arrivalAirportCode;
  }

  return new FlightItemVO(
    airItineraryKey,
    tripSequenceNumber,
    originLocationCode,
    originLocationName,
    departureAirportCodeFlight,
    destinationLocationCode,
    destinationLocationName,
    arrivalAirportCodeFlight,
    departureDateTimeFlight,
    arrivalDateTimeFlight,
    originDestRPH,
    originDestinationKey,
    durationInMins,
    connections,
    numStops,
    currencyCode,
    bestTotalPrice,
    null,
    null,
    priceClassId,
    flightSegmentVOs
  );
}

function buildPassenger(airTraveler) {
  const travelerRefNumberRPH = airTraveler.travelerRefNumber.rph;
  const passengerTypeCode = airTraveler.passengerTypeCode;
  const birthDate = airTraveler.birthDate;
  const personName = airTraveler.personName;
  let givenName = null;
  let surname = null;
  let nameTitle = null;
  if (personName) {
    givenName = personName.givenName;
    surname = personName.surname;
    nameTitle = personName.nameTitle;
  }
  const tripAirItemPassengerVO = new TripAirItemPassengerVO(
    travelerRefNumberRPH,
    passengerTypeCode,
    givenName,
    surname,
    nameTitle,
    birthDate,
    null,
    null,
    null
  );

  const emailList = airTraveler.emailList;
  const telephoneList = airTraveler.telephoneList;
  const documents = airTraveler.documents;
  if (emailList && emailList.length > 0) {
    for (const email of emailList) {
      const emailAddress = email.emailAddress;
      const tripPassengerEmailVO = new TripPassengerEmailVO(null, emailAddress);
      tripAirItemPassengerVO.addContactEmail(tripPassengerEmailVO);
    }
  }
  if (telephoneList && telephoneList.length > 0) {
    for (const telephone of telephoneList) {
      const phoneTechType = telephone.phoneTechType;
      const countryAccessCode = telephone.countryAccessCode;
      const phoneNumber = telephone.phoneNumber;

      const tripPassengerPhoneVO = new TripPassengerPhoneVO(
        phoneTechType,
        countryAccessCode,
        phoneNumber
      );
      tripAirItemPassengerVO.addContractPhone(tripPassengerPhoneVO);
    }
  }
  if (documents && documents.length > 0) {
    for (const document of documents) {
      const docID = document.docID;
      const docType = document.docType;
      const expireDate = document.expireDate;
      const docIssueCountry = document.docIssueCountry;
      const docHolderNationality = document.docHolderNationality;
      const tripPassengerDocumentVO = new TripPassengerDocumentVO(
        docType,
        docID,
        expireDate,
        docIssueCountry,
        docHolderNationality
      );
      tripAirItemPassengerVO.addDocument(tripPassengerDocumentVO);
    }
  }

  return tripAirItemPassengerVO;
}

//endregion Trip Air Item

//region Trip Air Item Fare
function buildTripAirItemFareVO(airItineraryPricingInfo, priceInfo, offer) {
  // const quoteID = airItineraryPricingInfo.quoteID;
  let priceExpirationDatetime = null;

  const itinTotalFare =
    airItineraryPricingInfo &&
    airItineraryPricingInfo.itinTotalFares &&
    airItineraryPricingInfo.itinTotalFares.length > 0
      ? airItineraryPricingInfo.itinTotalFares[0]
      : null;
  const totalFare = itinTotalFare ? itinTotalFare.totalFare : null;
  const discountList = itinTotalFare ? itinTotalFare.discountList : null;

  let totalDiscount = null;
  let promotionRemarks = null;
  if (discountList && discountList.length > 0) {
    const discount = discountList[0];
    if (discount) totalDiscount = discount.amount;
  }
  const localCreateDateTime = itinTotalFare
    ? itinTotalFare.localCreateDateTime
    : null;
  const totalFareCurrencyCode = totalFare ? totalFare.currencyCode : null;
  const totalFareAmount = totalFare ? totalFare.amount : null;
  const ptcFareBreakdowns = airItineraryPricingInfo
    ? airItineraryPricingInfo.PTC_FareBreakdowns
    : null;
  const tpaExtensions = airItineraryPricingInfo
    ? airItineraryPricingInfo.tpaExtensions
    : null;

  if (tpaExtensions && tpaExtensions.length > 0 && tpaExtensions[0].valueMap) {
    priceExpirationDatetime = tpaExtensions[0].valueMap.priceExpirationDatetime;
  }
  if (tpaExtensions && tpaExtensions.length > 0 && tpaExtensions[0].valueMap) {
    promotionRemarks = tpaExtensions[0].valueMap.Exception;
  }
  let repriceRequired = null;
  if (priceInfo && priceInfo.repriceRequired != null) {
    repriceRequired = priceInfo.repriceRequired;
  }
  if (
    priceInfo &&
    priceInfo.tpaExtensions &&
    priceInfo.tpaExtensions.length > 0 &&
    priceInfo.tpaExtensions[0].valueMap
  ) {
    priceExpirationDatetime =
      priceInfo.tpaExtensions[0].valueMap.priceExpirationDatetime;
  }

  const tripAirItemFareVO = new TripAirItemFareVO(
    repriceRequired,
    priceExpirationDatetime,
    totalFareAmount,
    totalDiscount,
    totalFareCurrencyCode,
    null,
    null,
    promotionRemarks
  );

  let totalAmountCount = 0;
  let currencyCode = null;
  if (ptcFareBreakdowns && ptcFareBreakdowns.length > 0) {
    for (const ptcFareBreakdown of ptcFareBreakdowns) {
      const airItemPassengerTypeFareVO =
        buildPassengerTypeFareVO(ptcFareBreakdown);

      totalAmountCount +=
        airItemPassengerTypeFareVO && airItemPassengerTypeFareVO.totalAmount
          ? airItemPassengerTypeFareVO.totalAmount
          : 0;
      currencyCode =
        airItemPassengerTypeFareVO && airItemPassengerTypeFareVO.currencyCode
          ? airItemPassengerTypeFareVO.currencyCode
          : null;

      tripAirItemFareVO.addPassengerFare(airItemPassengerTypeFareVO);
    }
  }

  if (offer && offer.purchased && offer.purchased.length > 0) {
    let purchased = offer.purchased;
    for (const offerPurchased of purchased) {
      const purchaseItemList = offerPurchased.purchaseItemList;
      for (const purchaseItem of purchaseItemList) {
        const airItemAncillaryFareVO = buildAncillaryFareVO(purchaseItem);
        totalAmountCount += airItemAncillaryFareVO.amount;
        if (!currencyCode) currencyCode = airItemAncillaryFareVO.currencyCode;
        tripAirItemFareVO.addAncillaryFare(airItemAncillaryFareVO);
      }
    }
  }
  if (offer && offer.priced && offer.priced.length > 0) {
    const airItemAncillaryFareVOs =
      flightAncillaryServiceTools.buildAncillaryOptions(offer.priced, "PRICED");
    tripAirItemFareVO.addAncillaryFares(airItemAncillaryFareVOs);
    for (const airItemAncillaryFareVO of airItemAncillaryFareVOs) {
      if (!currencyCode) currencyCode = airItemAncillaryFareVO.currencyCode;
      totalAmountCount += airItemAncillaryFareVO.amount;
    }
  }

  if (!tripAirItemFareVO.totalAmount) {
    tripAirItemFareVO.currencyCode = currencyCode;
    tripAirItemFareVO.totalAmount = totalAmountCount;
  }

  return tripAirItemFareVO;
}

function buildPassengerTypeFareVO(ptcFareBreakdown) {
  //fareUsage == 'TICKET FEE'
  const priceQuoteNumber = ptcFareBreakdown.rph;
  const localCreateDateTime = ptcFareBreakdown.localCreateDateTime;
  const flightRefNumberRPHList = ptcFareBreakdown.flightRefNumberRPHList;
  const travelerRefNumberList = ptcFareBreakdown.travelerRefNumberList;
  const passengerTypeCode = ptcFareBreakdown.passengerTypeQuantity.code;
  const quantity = ptcFareBreakdown.passengerTypeQuantity.quantity;
  const passengerFares = ptcFareBreakdown.passengerFareList;
  if (!passengerFares) return null;

  const travelerRefNumbers = [];
  for (const travelerRefNumber of travelerRefNumberList) {
    travelerRefNumbers.push(travelerRefNumber.rph);
  }

  let passengerFareBaseFare = null;
  let passengerFareTotalFare = null;

  let fareBaggageAllowanceList = null;
  let taxes = null;
  let currencyCode = null;
  let baseAmount = null;
  let taxesAmount = null;
  let feesAmount = null;
  let totalAmount = null;
  let discount = null;

  for (const passengerFare of passengerFares) {
    if (passengerFare.usage === "PassengerFare") {
      let baseFare = passengerFare.baseFare;
      let totalFare = passengerFare.totalFare;
      passengerFareBaseFare = baseFare.amount;
      passengerFareTotalFare = totalFare.amount;
    } else if (passengerFare.usage === "TicketFee") {
      fareBaggageAllowanceList = passengerFare.fareBaggageAllowanceList;
      const equivFares = passengerFare.equivFares;
      let baseFare = passengerFare.baseFare;
      if (equivFares && equivFares.length > 0) {
        baseFare = equivFares[0];
      }
      baseAmount = baseFare.amount;
      currencyCode = baseFare.currencyCode;
      taxesAmount = passengerFare.taxes.amount;
      const totalFare = passengerFare.totalFare;
      totalAmount = totalFare.amount;
      taxes = passengerFare.taxes.taxes;
      if (passengerFare.fees && passengerFare.fees.amount)
        feesAmount = passengerFare.fees.amount;
      const discountList = passengerFare.discountList;

      if (discountList && discountList.length > 0) {
        const discountItem = discountList[0];
        if (discountItem) discount = discountItem.amount;
      }
    }
  }

  const tripAirItemPassengerTypeFareVO = new TripAirItemPassengerTypeFareVO(
    priceQuoteNumber,
    flightRefNumberRPHList,
    travelerRefNumbers,
    passengerTypeCode,
    quantity,
    currencyCode,
    baseAmount,
    taxesAmount,
    feesAmount,
    totalAmount,
    null,
    null,
    localCreateDateTime,
    discount
  );

  if (fareBaggageAllowanceList && fareBaggageAllowanceList.length > 0) {
    for (const fareBaggageAllowance of fareBaggageAllowanceList) {
      const flightSegmentRPH = fareBaggageAllowance.flightSegmentRPH;
      const unitOfMeasureQuantity = fareBaggageAllowance.unitOfMeasureQuantity
        ? fareBaggageAllowance.unitOfMeasureQuantity
        : 0;
      const unitOfMeasure =
        fareBaggageAllowance.unitOfMeasure &&
        fareBaggageAllowance.unitOfMeasure.toLowerCase() === "kg"
          ? "Kg"
          : "Piece(s)";

      tripAirItemPassengerTypeFareVO.addNewBaggageAllowance(
        unitOfMeasureQuantity,
        unitOfMeasure,
        flightSegmentRPH
      );
    }
  }

  if (taxes && taxes.length > 0) {
    for (const tax of taxes) {
      const taxCode = tax.taxCode;
      const taxCurrencyCode =
        tax.currencyCode == null ? currencyCode : tax.currencyCode;
      const amount = tax.amount;
      const taxName = tax.taxName == null ? taxCode : tax.taxName;
      tripAirItemPassengerTypeFareVO.addNewTaxFeeSummary(
        taxCode,
        "TAX",
        true,
        taxName,
        taxCurrencyCode,
        amount
      );
    }
  }

  return tripAirItemPassengerTypeFareVO;
}

function buildAncillaryFareVO(purchaseItem) {
  let name = purchaseItem.name;
  const id = purchaseItem.id;
  const travelerRPH = purchaseItem.travelerRPH;
  const flightSegmentRPH = purchaseItem.flightSegmentRPH;
  const pricing = purchaseItem.pricing;
  const shortDescription = purchaseItem.shortDescription;
  const amount = pricing.amount;
  const pricingCurrency = pricing.pricingCurrency;
  const appliesTo = purchaseItem.appliesTo;
  let rowNumber = null;
  let seatNumber = null;
  if (appliesTo) {
    rowNumber = appliesTo.rowNumber;
    seatNumber = appliesTo.seatNumber;
  }

  let type = null;
  let code = purchaseItem.serviceCode;
  if ("189C" === code) {
    type = "SEAT";
    // name += " - " + shortDescription;
  } else {
    type = "SERVICE";
  }

  if ("1644" === code && shortDescription) {
    // name += " - " + shortDescription;
  }

  const tripAirItemAncillaryFareVO = new TripAirItemAncillaryFareVO(
    "PURCHASED",
    type,
    code,
    name,
    pricingCurrency,
    amount,
    id,
    flightSegmentRPH,
    travelerRPH,
    null,
    seatNumber,
    rowNumber,
    null
  );
  if (
    purchaseItem &&
    purchaseItem.productGroups &&
    purchaseItem.productGroups.length > 0
  ) {
    let productGroups = purchaseItem.productGroups;
    for (const productGroup of productGroups) {
      let groupCode = productGroup.code;
      let groupName = productGroup.description;
      let subGroupCode = null;
      let subGroupName = null;
      if (productGroup.subGroups && productGroup.subGroups.length > 0) {
        let subGroup = productGroup.subGroups[0];
        subGroupCode = subGroup.code;
        subGroupName = subGroup.description;
      }

      tripAirItemAncillaryFareVO.addNewGroup(
        groupCode,
        groupName,
        subGroupCode,
        subGroupName
      );
    }
  }

  return tripAirItemAncillaryFareVO;
}

//endregion Trip Air Item Fare

//region Price Classes

function buildPriceClasses(offer) {
  if (!offer || !offer.summary || offer.summary.length === 0) return null;

  const summary = offer.summary;

  const priceClasses = [];
  for (const summaryItem of summary) {
    const name = summaryItem.name;
    const bundleID = summaryItem.bundleID;
    const origDestRPH = summaryItem.origDestRPH;
    const subSections =
      summaryItem.shortDescription && summaryItem.shortDescription.subSections
        ? summaryItem.shortDescription.subSections
        : null;

    const flightPriceClassVO = new FlightPriceClassVO(
      bundleID,
      name,
      null,
      origDestRPH
    );
    if (subSections) {
      for (const subSection of subSections) {
        const subCode = subSection.subCode;
        const text = subSection.paragraphs[0].text;
        flightPriceClassVO.addNewPriceDescription(subCode, text);
      }
    }

    priceClasses.push(flightPriceClassVO);
  }

  return priceClasses;
}

function findSpecificPriceClass(priceClasses, priceClassId) {
  if (!priceClasses || priceClasses.length === 0 || !priceClassId) return null;

  for (const priceClass of priceClasses) {
    if (priceClass.priceClassId === priceClassId) return priceClass;
  }

  return null;
}

//endregion Price Class

export default {
  buildPriceClasses,
  buildTripAirItem,
};
