import { FlightItemVO } from "@/viewobject/sales/flight/shopping/flightItemVO";

export class TripAirItemVO {
  /**
   *
   * @param tripItemStatus string，该机票订单状态
   * @param tripItemId
   * @param tripAirItemId
   * @param recordLocatorId
   * @param idContext
   * @param airItineraryRPH string，行程唯一标识
   * @param airItineraryKey string，行程信息键，例如，SIN-HKG-20240513-SQ-0874#HKG-SIN-20240521-SQ-0895#SIN-HKG-20240602-SQ-0874+HKG-TPE-20240602-CX-0832
   * @param quoteID string，机票关联ID，例如，SP1F-3330781945825108452-1
   * @param bookingSourceType string，机票来源系统，例如，GDS,NDC
   * @param supplierCode string数组，机票供应商代码，例如，SQ,IB
   * @param flightItems Array，FlightItemVO对象数组
   * @param passengers Array，TripAirItemPassengerVO对象数组
   * @param paymentDetails Array，TripAirItemPaymentDetailVO对象数组
   * @param ticketings Array，TripAirItemTicketingVO对象数组
   * @param tripAirItemFare Object，TripAirItemFareVO对象
   * @param outOfPolicy boolean, CBT是否在权限之外
   * @param approvalRequired boolean，是否需要申请
   * @param upgradeAllowed boolean
   * @param reasonRequired boolean
   * @param reasons
   * @param ticketingRemarks
   * @param ssrRemarks
   */
  constructor(
    tripItemStatus,
    tripItemId,
    tripAirItemId,
    recordLocatorId,
    idContext,
    airItineraryRPH,
    airItineraryKey,
    quoteID,
    bookingSourceType,
    supplierCode,
    outOfPolicy,
    approvalRequired,
    upgradeAllowed,
    reasonRequired,
    reasons,
    flightItems,
    passengers,
    paymentDetails,
    ticketings,
    ticketingRemarks,
    tripAirItemFare,
    ssrRemarks
  ) {
    this.tripItemStatus = tripItemStatus;
    this.tripItemId = tripItemId;
    this.tripAirItemId = tripAirItemId;
    this.recordLocatorId = recordLocatorId;
    this.idContext = idContext;
    this.airItineraryKey = airItineraryKey;
    this.bookingSourceType = bookingSourceType;
    this.supplierCode = supplierCode;
    this.airItineraryRPH = airItineraryRPH;
    this.quoteID = quoteID;
    this.flightItems = flightItems;
    this.passengers = passengers;
    this.paymentDetails = paymentDetails;
    this.ticketings = ticketings;
    this.ticketingRemarks = ticketingRemarks;
    this.tripAirItemFare = tripAirItemFare;
    this.outOfPolicy = outOfPolicy;
    this.approvalRequired = approvalRequired;
    this.upgradeAllowed = upgradeAllowed;
    this.reasonRequired = reasonRequired;
    this.reasons = reasons;
    this.ssrRemarks = ssrRemarks;
  }

  addNewFlightItem(
    airItineraryKey,
    tripSequenceNumber,
    originLocationCode,
    originLocationName,
    departureAirportCode,
    destinationLocationCode,
    destinationLocationName,
    arrivalAirportCode,
    departureDateTime,
    arrivalDateTime,
    originDestRPH,
    originDestinationKey,
    durationInMins,
    connections,
    numStops,
    currencyCode,
    bestTotalPrice,
    bookingSourceType,
    supplierCode,
    flightSegments
  ) {
    const flightItemVO = new FlightItemVO(
      airItineraryKey,
      tripSequenceNumber,
      originLocationCode,
      originLocationName,
      departureAirportCode,
      destinationLocationCode,
      destinationLocationName,
      arrivalAirportCode,
      departureDateTime,
      arrivalDateTime,
      originDestRPH,
      originDestinationKey,
      durationInMins,
      connections,
      numStops,
      currencyCode,
      bestTotalPrice,
      [bookingSourceType],
      [supplierCode],
      flightSegments
    );

    if (this.flightItems == null) this.flightItems = [];
    this.flightItems.push(flightItemVO);
    return flightItemVO;
  }

  addFlightItem(flightItemVO) {
    if (this.flightItems == null) this.flightItems = [];
    this.flightItems.push(flightItemVO);
    return flightItemVO;
  }

  addPassenger(tripAirItemPassengerVO) {
    if (this.passengers == null) this.passengers = [];
    this.passengers.push(tripAirItemPassengerVO);
    return tripAirItemPassengerVO;
  }

  addTripAirItemFare(tripAirItemFare) {
    this.tripAirItemFare = tripAirItemFare;
  }

  addPaymentDetail(paymentDetail) {
    if (this.paymentDetails == null) this.paymentDetails = [];
    this.paymentDetails.push(paymentDetail);
  }

  addTicketing(ticketingVO) {
    if (this.ticketings == null) this.ticketings = [];
    this.ticketings.push(ticketingVO);
    return ticketingVO;
  }
}
