<script setup>
import { reactive, ref, watch, watchEffect } from "vue";
import Banner from "@/components/custom/Banner.vue";
import { useDynamicPackageStore } from "@/stores/dynamicPackage";
import { useRoute, useRouter } from "vue-router";
import { useTemplateStore } from "@/stores/template";
import BasePackageHotelDetailHeading from "@/views/sales/package/items/group/BasePackageHotelDetailHeading.vue";
import BasePackageHotelDetailPhotosGroup from "@/views/sales/package/items/group/BasePackageHotelDetailPhotosGroup.vue";
import PacakgeHotelDescriptionPannelGroup from "@/views/sales/package/items/group/PacakgeHotelDescriptionPannelGroup.vue";
import { useCmsContentStore } from "@/stores/cms";

const dynamicPackageStore = useDynamicPackageStore();
const templateStore = useTemplateStore();

const route = useRoute();
const router = useRouter();
const navigation = reactive({
  title: "Hotel Search Result",
  routes: [
    {
      name: "Home",
      path: "/",
    },
    {
      name: "Hotel Search Result",
      path: "/sales/hotel/hotel-list",
    },
    {
      name: "Hotel Details",
    },
  ],
});

//监听引用类型的所有属性
let hotelViewItem = ref(null);
let hotelViewItemImages = ref(null);
watchEffect(() => {
  if (dynamicPackageStore.hotelViewItemMap) {
    let hotelKeys = Object.keys(dynamicPackageStore.hotelViewItemMap);
    //拿到酒店item
    hotelViewItem.value = dynamicPackageStore.hotelViewItemMap[hotelKeys[0]];

    hotelViewItemImages.value = hotelViewItem.value
      ? hotelViewItem.value.images
      : [];
  }
});

function goBack() {
  router.back();
}

let equipmentType = ref(templateStore.responsive.equipmentType);
watch(
  () => templateStore.responsive.equipmentType,
  (newValue) => {
    equipmentType.value = newValue;
  }
);
const bannerContentStore = useCmsContentStore();
let bannerContent = reactive(null);
bannerContentStore.getProductBannerBackground();
const baseUrl = "/api/v3/content/collections/images";
let imageUrlRef = ref("");
let backgroundImageUrlRef = ref("");
watchEffect(() => {
  bannerContentStore.productPageBackgroundImage,
    (bannerContent = bannerContentStore.productPageBackgroundImage);
  imageUrlRef.value =
    bannerContentStore.productPageBackgroundImage &&
    bannerContentStore.productPageBackgroundImage.packageBackgroundImage
      ? bannerContentStore.productPageBackgroundImage.packageBackgroundImage.url
      : "";
  backgroundImageUrlRef.value = baseUrl + imageUrlRef.value;
});
</script>

<template>
  <!-- Common Banner Area -->
  <Banner
    :navigate="navigation"
    :background-image-url="backgroundImageUrlRef"
  />
  <section
    id="tour_details_main"
    class="section_padding"
    style="margin-left: 15%"
  >
    <div class="container hotel_container">
      <div class="row" v-if="equipmentType != 'iphone'">
        <div class="col-lg-3 col-md-3 col-sm-3 col-3">
          <div class="header_back">
            <a @click="goBack">
              <i class="bi bi-arrow-left-square back_icon" size="40"></i>
              <span style="font-size: 27px">{{ $t("back") }}</span>
            </a>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          class="col-lg-10"
          :class="{ 'col-lg-12': equipmentType == 'ipad' }"
        >
          <div class="tour_details_leftside_wrapper">
            <div class="tour_details_heading_wrapper">
              <BasePackageHotelDetailHeading :hotel-view-item="hotelViewItem" />
            </div>
            <div class="tour_details_img_wrapper">
              <BasePackageHotelDetailPhotosGroup
                :hotel-view-item-images="hotelViewItemImages"
              />
            </div>

            <PacakgeHotelDescriptionPannelGroup
              :hotel-view-item="hotelViewItem"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped></style>
