import { defineStore } from "pinia";
import profileTools from "@/tools/profileTools";
import { useRouter } from "vue-router";
import partyApi from "@/apis/v2/party/partyApi";
import partyAirlinePrefApi from "@/apis/v2/party/partyAirlinePrefApi";
import partyContactApi from "@/apis/v2/party/partyContactApi";
import partyDocumentApi from "@/apis/v2/party/partyDocumentApi";
import partyLoyaltyApi from "@/apis/v2/party/partyLoyaltyApi";
import PartyRelationshipApi from "@/apis/v2/party/partyRelationshipApi";

let router = useRouter();

export const useProfileStore = defineStore("profile", {
  state: () => {
    return {
      profileViewItem: null,
      profileViewItems: null,
    };
  },
  actions: {
    loadUserProfile(partyId) {
      let _this = this;
      const promises = [
        partyApi.findPartyInfoFull(partyId),
        partyAirlinePrefApi.findSeatprefs(partyId),
        partyAirlinePrefApi.findMealprefs(partyId),
      ];

      Promise.all(promises)
        .then((results) => {
          if (results && results.length > 0) {
            const partyData =
              results[0] && results[0].data ? results[0].data : null;
            const seatPrefData =
              results[1] && results[1].data ? results[1].data : null;
            const mealPrefData =
              results[2] && results[2].data ? results[2].data : null;
            if (partyData) {
              _this.profileViewItem = profileTools.getProfileViewItem(
                partyData,
                seatPrefData,
                mealPrefData
              );
            } else {
              _this.profileViewItem = null;
            }
          }
        })
        .catch((err) => {
          console.error(err);
          router.push({
            name: "error-500",
            query: { message: "user profile unavailable" },
          });
        });
    },
    loadAdditionalTravellersProfile(partyIds) {
      let _this = this;
      _this.profileViewItems = [];
      if (partyIds && partyIds.length) {
        partyIds.forEach((partyId) => {
          const promises = [
            partyApi.findPartyInfoFull(partyId),
            partyAirlinePrefApi.findSeatprefs(partyId),
            partyAirlinePrefApi.findMealprefs(partyId),
          ];
          Promise.all(promises)
            .then((results) => {
              if (results && results.length > 0) {
                const partyData =
                  results[0] && results[0].data ? results[0].data : null;
                const seatPrefData =
                  results[1] && results[1].data ? results[1].data : null;
                const mealPrefData =
                  results[2] && results[2].data ? results[2].data : null;
                if (partyData) {
                  _this.profileViewItems.push(
                    profileTools.getProfileViewItem(
                      partyData,
                      seatPrefData,
                      mealPrefData
                    )
                  );
                }
              }
            })
            .catch((err) => {
              console.error(err);
              router.push({
                name: "error-500",
                query: { message: "user profile unavailable" },
              });
            });
        });
      }
    },
    modifyBasicInformation(profileFormModel) {
      let _this = this;
      let basicModifyRQ =
        profileTools.getBasicInformationModifyRQ(profileFormModel);
      return partyApi
        .modifyParty(basicModifyRQ, profileFormModel.partyId)
        .then((res) => {
          return res.data;
        });
    },
    modifyContact(profileFormModel) {
      let _this = this;
      const promises = [];
      if (profileFormModel.mobileNumber) {
        const contactRQ = {
          partyId: profileFormModel.partyId,
          contactMechPurposeTypeId: "PHONE_MOBILE",
          contactMech: {
            contactMechTypeId: "TELECOM_NUMBER",
            telecomNumber: {
              countryCode: profileFormModel.mobileCountryAccessCode,
              contactNumber: profileFormModel.mobileNumber,
            },
          },
        };
        if (profileFormModel.mobileContactMechId) {
          //修改
          contactRQ.partyContactMechId = profileFormModel.mobileContactMechId;
          promises.push(
            partyContactApi.modifyContact(
              contactRQ,
              profileFormModel.partyId,
              profileFormModel.mobileContactMechId
            )
          );
        } else {
          promises.push(
            partyContactApi.addContact(contactRQ, profileFormModel.partyId)
          );
        }
      }
      if (profileFormModel.emergencyNumber) {
        const contactRQ = {
          partyId: profileFormModel.partyId,
          contactMechPurposeTypeId: "PHONE_HOME",
          contactMech: {
            contactMechTypeId: "TELECOM_NUMBER",
            telecomNumber: {
              countryCode: profileFormModel.emergencyCountryAccessCode,
              contactNumber: profileFormModel.emergencyNumber,
              askForName: profileFormModel.emergencyContactName,
            },
          },
        };
        if (profileFormModel.emergencyContactMechId) {
          //修改
          contactRQ.partyContactMechId =
            profileFormModel.emergencyContactMechId;
          promises.push(
            partyContactApi.modifyContact(
              contactRQ,
              profileFormModel.partyId,
              profileFormModel.emergencyContactMechId
            )
          );
        } else {
          promises.push(
            partyContactApi.addContact(contactRQ, profileFormModel.partyId)
          );
        }
      }
      if (profileFormModel.emailAddress) {
        const contactRQ = {
          partyId: profileFormModel.partyId,
          contactMechPurposeTypeId: "PRIMARY_EMAIL",
          contactMech: {
            contactMechTypeId: "EMAIL_ADDRESS",
            infoString: profileFormModel.emailAddress,
          },
        };

        if (profileFormModel.emailContactMechId) {
          //修改
          contactRQ.partyContactMechId = profileFormModel.emailContactMechId;
          promises.push(
            partyContactApi.modifyContact(
              contactRQ,
              profileFormModel.partyId,
              profileFormModel.emailContactMechId
            )
          );
        } else {
          promises.push(
            partyContactApi.addContact(contactRQ, profileFormModel.partyId)
          );
        }
      }
      if (profileFormModel.country) {
        const contactRQ = {
          partyId: profileFormModel.partyId,
          contactMechPurposeTypeId: "PRIMARY_LOCATION",
          contactMech: {
            contactMechTypeId: "POSTAL_ADDRESS",
            postalAddress: {
              // postalAddressId: 6,
              attnName: profileFormModel.country,
              address1: profileFormModel.province,
              address2: profileFormModel.region,
              city: profileFormModel.city,
              postalCode: profileFormModel.postCode,
            },
          },
        };
        if (profileFormModel.addressContactMechId) {
          //修改
          contactRQ.partyContactMechId = profileFormModel.addressContactMechId;
          promises.push(
            partyContactApi.modifyContact(
              contactRQ,
              profileFormModel.partyId,
              profileFormModel.addressContactMechId
            )
          );
        } else {
          promises.push(
            partyContactApi.addContact(contactRQ, profileFormModel.partyId)
          );
        }
      }
      return Promise.all(promises);
    },
    modifyAirportSecurity(profileFormModel) {
      let _this = this;
      const promises = [];
      if (profileFormModel.knownTravellerNumber) {
        const documentRQ = {
          partyId: profileFormModel.partyId,
          docID: profileFormModel.knownTravellerNumber,
          docTypeId: "KNOWN_TRAVELER_NUMBER",
          // expireDate: "2025-06-27",
          // docIssueCountry: "fdsaafasdfad",
          // docHolderNationality: "hong kong",
        };
        if (profileFormModel.knownTravellerNumberId) {
          documentRQ.partyDocumentId = profileFormModel.knownTravellerNumberId;
          promises.push(
            partyDocumentApi.modifyDocument(
              documentRQ,
              profileFormModel.partyId,
              profileFormModel.knownTravellerNumberId
            )
          );
        } else {
          promises.push(
            partyDocumentApi.addDocument(documentRQ, profileFormModel.partyId)
          );
        }
      }
      if (profileFormModel.redressNumber) {
        const documentRQ = {
          partyId: profileFormModel.partyId,
          docID: profileFormModel.redressNumber,
          docTypeId: "REDRESS_NUMBER",
          // expireDate: "2025-06-27",
          // docIssueCountry: "fdsaafasdfad",
          // docHolderNationality: "hong kong",
        };
        if (profileFormModel.redressNumberId) {
          documentRQ.partyDocumentId = profileFormModel.redressNumberId;
          promises.push(
            partyDocumentApi.modifyDocument(
              documentRQ,
              profileFormModel.partyId,
              profileFormModel.redressNumberId
            )
          );
        } else {
          promises.push(
            partyDocumentApi.addDocument(documentRQ, profileFormModel.partyId)
          );
        }
      }
      return Promise.all(promises);
    },
    modifyTravelDocuments(profileFormModel) {
      let _this = this;
      const promises = [];
      if (profileFormModel.documents && profileFormModel.documents.length > 0) {
        profileFormModel.documents.forEach((document) => {
          const documentRQ = {
            partyId: profileFormModel.partyId,
            docID: document.docID,
            docTypeId: "PASSPORT",
            expireDate: document.expireDate,
            docIssueCountry: document.docIssueCountry,
            docHolderNationality: document.docIssueCountry,
          };
          if (document.partyDocumentId) {
            documentRQ.partyDocumentId = document.partyDocumentId;
            promises.push(
              partyDocumentApi.modifyDocument(
                documentRQ,
                profileFormModel.partyId,
                document.partyDocumentId
              )
            );
          } else {
            promises.push(
              partyDocumentApi.addDocument(documentRQ, profileFormModel.partyId)
            );
          }
        });
      }
      return Promise.all(promises);
    },
    removeTravelDocument(partyId, partyDocumentId) {
      return partyDocumentApi
        .deleteDocument(partyId, partyDocumentId)
        .then((res) => {
          return res.data;
        });
    },
    modifyFlightPreference(profileFormModel) {
      let _this = this;
      const promises = [];
      if (profileFormModel.seatPref) {
        const preferRQ = {
          partyId: profileFormModel.partyId,
          seatPreference: profileFormModel.seatPref,
        };
        if (profileFormModel.seatPrefId) {
          preferRQ.seatPrefId = profileFormModel.seatPrefId;
          promises.push(
            partyAirlinePrefApi.modifySeatpref(
              preferRQ,
              profileFormModel.partyId,
              profileFormModel.seatPrefId
            )
          );
        } else {
          promises.push(
            partyAirlinePrefApi.addSeatpref(preferRQ, profileFormModel.partyId)
          );
        }
      }
      if (profileFormModel.mealPref) {
        const preferRQ = {
          partyId: profileFormModel.partyId,
          mealType: profileFormModel.mealPref,
        };
        if (profileFormModel.mealPrefId) {
          preferRQ.mealPrefId = profileFormModel.mealPrefId;
          promises.push(
            partyAirlinePrefApi.modifyMealpref(
              preferRQ,
              profileFormModel.partyId,
              profileFormModel.mealPrefId
            )
          );
        } else {
          promises.push(
            partyAirlinePrefApi.addMealpref(preferRQ, profileFormModel.partyId)
          );
        }
      }
      return Promise.all(promises);
    },
    modifyMemberships(profileFormModel) {
      let _this = this;
      const promises = [];
      if (
        profileFormModel.custLoyalties &&
        profileFormModel.custLoyalties.length > 0
      ) {
        profileFormModel.custLoyalties.forEach((custLoyalty) => {
          const loyaltyRQ = {
            partyId: profileFormModel.partyId,
            programID: custLoyalty.programID,
            membershipID: custLoyalty.membershipID,
          };
          if (custLoyalty.custLoyaltyId) {
            loyaltyRQ.custLoyaltyId = custLoyalty.custLoyaltyId;
            promises.push(
              partyLoyaltyApi.modifyTravelLoyalty(
                loyaltyRQ,
                profileFormModel.partyId,
                custLoyalty.custLoyaltyId
              )
            );
          } else {
            promises.push(
              partyLoyaltyApi.addTravelLoyalty(
                loyaltyRQ,
                profileFormModel.partyId
              )
            );
          }
        });
      }
      return Promise.all(promises);
    },
    removeMemberShip(partyId, partyCustLoyaltyId) {
      return partyLoyaltyApi
        .deleteTravelLoyalty(partyId, partyCustLoyaltyId)
        .then((res) => {
          return res.data;
        });
    },
    addAdditionalTraveller(arrangerPartyId, profileFormModel) {
      const additionalTravellerRQ = profileTools.getAddAdditionalTravellerRQ(
        arrangerPartyId,
        profileFormModel
      );
      return partyApi.addParty(additionalTravellerRQ).then((res) => {
        return res.data;
      });
    },
    removeAdditionalTraveller(relationShipId) {
      return PartyRelationshipApi.deleteRelationship(relationShipId).then(
        (res) => {
          return res.data;
        }
      );
    },
    modifyAdditionalPersonInfo(profileFormModel) {
      let _this = this;
      let basicModifyRQ =
        profileTools.getBasicInformationModifyRQ(profileFormModel);
      const promises = [
        partyApi.modifyParty(basicModifyRQ, profileFormModel.partyId),
      ];
      if (profileFormModel.mobileNumber) {
        const contactRQ = {
          partyId: profileFormModel.partyId,
          contactMechPurposeTypeId: "PHONE_MOBILE",
          contactMech: {
            contactMechTypeId: "TELECOM_NUMBER",
            telecomNumber: {
              countryCode: profileFormModel.mobileCountryAccessCode,
              contactNumber: profileFormModel.mobileNumber,
            },
          },
        };
        if (profileFormModel.mobileContactMechId) {
          //修改
          contactRQ.partyContactMechId = profileFormModel.mobileContactMechId;
          promises.push(
            partyContactApi.modifyContact(
              contactRQ,
              profileFormModel.partyId,
              profileFormModel.mobileContactMechId
            )
          );
        } else {
          promises.push(
            partyContactApi.addContact(contactRQ, profileFormModel.partyId)
          );
        }
      }
      if (profileFormModel.emergencyNumber) {
        const contactRQ = {
          partyId: profileFormModel.partyId,
          contactMechPurposeTypeId: "PHONE_HOME",
          contactMech: {
            contactMechTypeId: "TELECOM_NUMBER",
            telecomNumber: {
              countryCode: profileFormModel.emergencyCountryAccessCode,
              contactNumber: profileFormModel.emergencyNumber,
              askForName: profileFormModel.emergencyContactName,
            },
          },
        };
        if (profileFormModel.emergencyContactMechId) {
          //修改
          contactRQ.partyContactMechId =
            profileFormModel.emergencyContactMechId;
          promises.push(
            partyContactApi.modifyContact(
              contactRQ,
              profileFormModel.partyId,
              profileFormModel.emergencyContactMechId
            )
          );
        } else {
          promises.push(
            partyContactApi.addContact(contactRQ, profileFormModel.partyId)
          );
        }
      }
      if (profileFormModel.emailAddress) {
        const contactRQ = {
          partyId: profileFormModel.partyId,
          contactMechPurposeTypeId: "PRIMARY_EMAIL",
          contactMech: {
            contactMechTypeId: "EMAIL_ADDRESS",
            infoString: profileFormModel.emailAddress,
          },
        };

        if (profileFormModel.emailContactMechId) {
          //修改
          contactRQ.partyContactMechId = profileFormModel.emailContactMechId;
          promises.push(
            partyContactApi.modifyContact(
              contactRQ,
              profileFormModel.partyId,
              profileFormModel.emailContactMechId
            )
          );
        } else {
          promises.push(
            partyContactApi.addContact(contactRQ, profileFormModel.partyId)
          );
        }
      }
      if (profileFormModel.country) {
        const contactRQ = {
          partyId: profileFormModel.partyId,
          contactMechPurposeTypeId: "PRIMARY_LOCATION",
          contactMech: {
            contactMechTypeId: "POSTAL_ADDRESS",
            postalAddress: {
              // postalAddressId: 6,
              attnName: profileFormModel.country,
              address1: profileFormModel.province,
              address2: profileFormModel.region,
              city: profileFormModel.city,
              postalCode: profileFormModel.postCode,
            },
          },
        };
        if (profileFormModel.addressContactMechId) {
          //修改
          contactRQ.partyContactMechId = profileFormModel.addressContactMechId;
          promises.push(
            partyContactApi.modifyContact(
              contactRQ,
              profileFormModel.partyId,
              profileFormModel.addressContactMechId
            )
          );
        } else {
          promises.push(
            partyContactApi.addContact(contactRQ, profileFormModel.partyId)
          );
        }
      }
      return Promise.all(promises);
    },
  },
});
