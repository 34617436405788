<script setup>
import { Empty, Modal, Spin } from "ant-design-vue";
import { ref } from "vue";
import flightApi from "@/apis/flightApi";

const props = defineProps({
  itineraryFareOptionKeyMap: {},
  fareItem: {},
  flightInfo: {},
  fareIndex: {},
  priceClasses: {},
});

const AModal = Modal;
const AEmpty = Empty;

//单独请求fare的时候使用
const fareDescriptionsRef = ref([]); //單獨請求
const hasFareDescriptionRef = ref(false);
const fareRuleRPHRef = ref(null);

const fareDescriptionModalOpenRef = ref(false);
const isLoadingRef = ref(false);
const fareActiveDesIndexRef = ref(null);

function getNormalFareRules(
  itineraryFareOptionKeyMap,
  fareItem,
  flightInfo,
  fareIndex
) {
  const supplierCode = fareItem.supplierCode;
  const originDestinationKey = fareItem.originDestinationKey;
  const fareBasisCodes = fareItem.fareBasisCodes;

  fareDescriptionModalOpenRef.value = true;
  isLoadingRef.value = true;
  if (fareIndex !== fareActiveDesIndexRef.value) {
    fareDescriptionsRef.value = [];
    hasFareDescriptionRef.value = false;
    if (itineraryFareOptionKeyMap) {
      for (const itineraryFareOptionKeyMapKey in itineraryFareOptionKeyMap) {
        if (itineraryFareOptionKeyMapKey.indexOf(supplierCode) !== -1) {
          fareRuleRPHRef.value =
            itineraryFareOptionKeyMap[itineraryFareOptionKeyMapKey];
          break;
        }
      }
    }
    const flightSegmentsCopy = JSON.parse(
      JSON.stringify(flightInfo.flightSegments)
    );

    if (
      flightSegmentsCopy &&
      flightSegmentsCopy.length > 0 &&
      fareBasisCodes &&
      fareBasisCodes.length > 0
    ) {
      const segment = flightSegmentsCopy[0];
      const fareBasisCode =
        fareBasisCodes[0].indexOf("/") >= 0
          ? fareBasisCodes[0].split("/")[0]
          : fareBasisCodes[0];
      const departureAirportCode = segment.departureAirportCode;
      const arrivalAirportCode = getArrivalLocationShared(
        flightSegmentsCopy,
        fareBasisCodes
      );
      const marketingAirlineCode = segment.marketingAirlineCode;
      const departureDate = segment.departureDateTime.split("T")[0];

      const fareRuleRQ = {
        abbreviatedRuleTextInd: false,
        ruleReqInfo: {
          rph: fareRuleRPHRef.value,
          departureAirportCode: departureAirportCode,
          arrivalAirportCode: arrivalAirportCode,
          marketingAirlineCode: marketingAirlineCode,
          departureDate: departureDate,
          fareReferences: [
            {
              resBookDesigCode: fareBasisCode,
            },
          ],
        },
      };

      flightApi.fareRules(fareRuleRQ).then((res) => {
        isLoadingRef.value = false;
        if (res.data && res.data.fareRuleResponseInfos) {
          const fareRuleResponseInfos = res.data.fareRuleResponseInfos;
          let subSections = null;
          if (fareRuleResponseInfos && fareRuleResponseInfos.length > 0) {
            subSections = fareRuleResponseInfos[0].fareRules.subSections;
            if (subSections) {
              for (const subSection of subSections) {
                const subTitle = subSection.subTitle;
                const text = subSection.paragraphs[0].text.replaceAll(
                  "\n",
                  "<br/>"
                );
                fareDescriptionsRef.value.push({ subTitle, text });
              }
            }
          }
          hasFareDescriptionRef.value = true;
          fareRuleRPHRef.value = null;
        } else {
          fareDescriptionsRef.value = null;
          hasFareDescriptionRef.value = false;
        }
      });
    }
    fareActiveDesIndexRef.value = fareIndex;
  }
}

function getArrivalLocationShared(flightSegments, fareBasisCodes) {
  if (fareBasisCodes.length === 1)
    return flightSegments[flightSegments.length - 1].arrivalAirportCode;

  return flightSegments[0].arrivalAirportCode;
}

const descriptionActiveIndexRef = ref(0);

function closeFareDescriptionModal() {
  fareDescriptionModalOpenRef.value = false;
  descriptionActiveIndexRef.value = 0;
}

function changeActiveIndex(index) {
  descriptionActiveIndexRef.value = index;
}
</script>

<template>
  <span
    class="mg-l-8"
    @click="
      getNormalFareRules(
        itineraryFareOptionKeyMap,
        fareItem,
        flightInfo,
        fareIndex
      )
    "
  >
    {{ $t("normal-rules") }}
  </span>

  <!-- fare 描述 -->
  <a-modal
    v-model:open="fareDescriptionModalOpenRef"
    :cancel-button-props="{ style: { display: 'none' } }"
    :maskClosable="false"
    :ok-button-props="{ style: { display: 'none' } }"
    :z-index="11111"
    centered
    width="45%"
    wrapClassName="fare-modal"
    @cancel="closeFareDescriptionModal"
  >
    <div class="mg-t-20" style="margin-left: -20px">
      <div v-if="!hasFareDescriptionRef" class="text-center">
        {{ $t("loading") }}
        <Spin></Spin>
      </div>
      <div v-if="fareDescriptionsRef && fareDescriptionsRef.length > 0">
        <div class="flex">
          <ul
            class="nav nav-tabs nav-tabs-block justify-content-end justify-content-md-start flex-md-column col-md-4"
            role="tablist"
          >
            <li
              v-for="(descriptionItem, descriptionIndex) in fareDescriptionsRef"
              :key="descriptionIndex"
              class="nav-item d-md-flex flex-md-column"
            >
              <button
                :aria-controls="'btabs-vertical2-' + descriptionIndex"
                :class="{
                  active: descriptionIndex === descriptionActiveIndexRef,
                }"
                :data-bs-target="'#btabs-vertical2-' + descriptionIndex"
                aria-selected="true"
                class="nav-link text-md-start"
                data-bs-toggle="tab"
                role="tab"
                @click="changeActiveIndex(descriptionIndex)"
              >
                {{ descriptionItem.subTitle }}
              </button>
            </li>
          </ul>
          <div class="tab-content col-md-8">
            <div
              v-for="(descriptionItem, descriptionIndex) in fareDescriptionsRef"
              :key="descriptionIndex"
              :aria-labelledby="'btabs-vertical2-' + descriptionIndex + '-tab'"
              :class="{
                active: descriptionIndex === descriptionActiveIndexRef,
              }"
              class="block-content tab-pane"
              role="tabpanel"
              tabindex="0"
            >
              <span
                class="review_count mg-t-5"
                v-html="descriptionItem.text"
              ></span>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="!isLoadingRef">
        <AEmpty :description="$t('no-data')"></AEmpty>
      </div>
    </div>
  </a-modal>
  <!-- fare描述 END -->
</template>

<style lang="scss">
.fare-modal {
  .ant-modal-content {
    max-height: 700px;
    overflow-y: scroll;
  }
}
</style>
