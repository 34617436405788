import { defineStore } from "pinia";
import flightPriceTools from "@/tools/v2/flight/price/flightPriceTools";
import flightPrimeBookingApi from "@/apis/v2/flight/flightPrimeBookingApi";
import flightBookTools from "@/tools/v2/flight/book/flightBookTools";
import flightCommonTools from "@/tools/v2/flight/common/flightCommonTools";
import tripAirItemTools from "@/tools/v2/trip/tripAirItemTools";

export const useFlightPriceStore = defineStore("flightPrice", {
  state: () => ({
    tripAirItem: null,
    passengerFormModelItems: null,
    priceClasses: null,
  }),
  actions: {
    airPrice(flightPriceFormModel) {
      const airPriceRQ = flightPriceTools.buildAirPriceRQ(flightPriceFormModel);
      const thisRef = this;
      return flightPrimeBookingApi
        .verifyPrice(airPriceRQ)
        .then((res) => {
          const airPriceRS = res.data;
          if (
            airPriceRS &&
            airPriceRS.pricedItineraryList &&
            airPriceRS.pricedItineraryList.length > 0
          ) {
            thisRef.tripAirItem = tripAirItemTools.buildTripAirItem(
              airPriceRS.pricedItineraryList[0]
            );
            thisRef.passengerFormModelItems =
              flightBookTools.buildFlightPassengerFormItems(
                airPriceRS.pricedItineraryList[0]
              );
            thisRef.priceClasses = flightCommonTools.buildPriceClasses(
              airPriceRS.offer
            );
          } else {
            thisRef.tripAirItem = null;
            thisRef.passengerFormModelItems = null;
            thisRef.priceClasses = null;
          }
          return airPriceRS;
        })
        .catch((error) => {
          console.log(error);
          thisRef.tripAirItem = null;
          thisRef.passengerFormModelItems = null;
          thisRef.priceClasses = null;
          return error;
        });
    },
    airPriceOffer(flightPriceFormModel) {
      const airPriceRQ = flightPriceTools.buildAirPriceRQ(flightPriceFormModel);
      const thisRef = this;
      return flightPrimeBookingApi
        .verifyPrice(airPriceRQ)
        .then((res) => {
          const airPriceRS = res.data;
          if (
            airPriceRS &&
            airPriceRS.pricedItineraryList &&
            airPriceRS.pricedItineraryList.length > 0
          ) {
            thisRef.tripAirItem = tripAirItemTools.buildTripAirItem(
              airPriceRS.pricedItineraryList[0]
            );
          }
          return airPriceRS;
        })
        .catch((error) => {
          thisRef.tripAirItem = null;
          return error;
        });
    },
  },
});
